import "./App.css";
import RoutePage from "./config/routes/RoutePage";

function App() {
  return (
    <>
      <RoutePage />
    </>
  );
}

export default App;
