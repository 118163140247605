export const customMessages = {
  commonError: "Something went wrong, please try again later.",
  severitySuccess: "success",
  severityError: "error",
  severityInfo: "info",
  formFillError: "Please fill out  all the required fields and try again.",
  userRoleEmptyError:
    "You dont have a role to access this portal, Please contact admin.",
  // Job Realted Messages
  formFillErrorJobTitle: "Please enter Job Title",

  draftSuccess: "Job saved as draft successfully",
  jobEditSuccess: "Job Edited successfully",
  jobAddSuccess: "Job added successfully",
  jobCloseSuccess: "Job closed  successfully",
  jobDeleteSuccess: "Job deleted succesfully",
  jobPostSuccess: "Job posted  successfully",
  jobSharedSuccess: "Job Shared  successfully",
  jobUnPrioritizedSuccess: "Job Un Prioritized  successfully",
  jobPrioritizedSuccess: "Job prioritized  successfully",
  jobShareSuccess: "Job Shared Successfully",
  jobRepostSuccess:
    "The job has been reposted. It is now available under 'Submitted Jobs'",
  // User Related Messages
  loginError: "The user name or password are incorrect, try again.",
  createPasswordInfo: "Please create a new password",
  registrationSuccess:
    "The registration is successful. The temporary password is sent to your email ID after admin approval.",
  registrationError: "User already exist.",
  changePasswordSuccess:
    "Your password updated sucessfully, Please login with new password.",
  forgotPasswordSuccess: "OTP has been sent to your email ID.",
  forgotError: "User not exist.",
  otpSuccess: "OTP verified sucessfully, Please change your password.",
  otpError: "Invalid OTP, Please enter valid OTP.",
  jobApplySuccess:
    "Thank you for the application, We will get back to you soon.",

  // Role  and privileges Related Messages
  roleDeleteSuccess: "Role deleted succesfully",
  roleAddedSuccess: "Role added successfully.",
  roleExistMessage: "Role already exist, Please create another one.",
  roleEditedSuccess: "Role Edited successfully.",
  privilegeUpdatedSuccess: "Privileges updated succesfully",

  //Field Setting related
  entryDeleted: "Entry deleted succesfully",
  entryAdded: "Entry created successfully",
  entryExistMessage: "Input Field already exist, Please create another one.",

  // Request related
  requestAcceptSuccess: "Request accepted succesfully",
  requestRejectSuccess: "Request Rejected succesfully",
  // User Profile related
  profilePhotoAddSuccess: "Profile picture updated succesfully",
  profileDataUpdateSuccess: "Profile data updated succesfully",

  // Applicant related
  rejectApplicantSuccess: "Rejected applicant successfully",
  shortlistApplicantSuccess: "Shortlisted applicant successfully",
  applicantStatusUpdated: "Status Updated successfully",
  interviewScheduleSuccess: "Interview scheduled successfully",
  interviewReScheduleSuccess: "Interview Re-scheduled successfully",
};
// Candidate Related
export const experinceDataInYear = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "10+",
];
export const experinceDataInMonth = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
];
export const noticePeriodData = [
  "Immediate",
  "Less than 30 days",
  "31-45 days",
  "46-60 days",
  "61-90 days",
];

// Job Related
export const locationData = ["Kochi", "Trivandrum", "Bangalore", "Mumbai"];
export const timeShiftData = ["Day", "Night", "Any"];
export const jobTypeData = ["Full time", "Part time", "Any"];
export const PositionData = [
  "Software Development",
  "Quality Assurance and Testing",
  "Business Analyst",
  "Project Manangement",
  "IT Support",
];
export const Skills = [
  "Angular JS",
  "Android",
  "Automation Anywhere",
  "Automation Testing",
  "AWS",
  "Bash Scripting",
  "C Sharp",
  "Codeignator",
  "Devops",
  "Drupal",
  "Flutter",
  "IOS",
  "Jiffy",
  "Java",
  "Javascript",
  "Kotlin",
  "Laravel",
  "Linux",
  "Magento",
  "Manual Testing",
  "My SQL",
  "Node Js",
  "Objective C",
  ".Net",
  "PHP",
  "Power BI",
  "Power Platform",
  "Python",
  "React JS",
  "React Native",
  "RPA",
  "Selenium/Java",
  "Springboot",
  "Swift",
  "Wordpress",
];
export const ExperienceData = [
  "1 and below",
  "2 and below",
  "5 and below",
  "10 and below",
];

export const Qualification = [
  "BCA",
  "BSc",
  "MCA",
  "MSc",
  "B-Tech",
  "M-Tech",
  "Others",
];

export const WorkMode = ["OnSite", "Remote", "Hybrid"];

export const Configurations = [
  "Location",
  "Key Skills",
  "Position",
  "Qualification",
];

export const InterviewStatus = [
  "L1 Completed Candidates",
  "L2 Completed Candidates",
  "Selected Candidates",
  "Onboarded Candidates",
];

export const L1InterviewStatus = [
  "L2 Completed Candidates",
  "Selected Candidates",
  "Onboarded Candidates",
];

export const L2InterviewStatus = [
  "Selected Candidates",
  "Onboarded Candidates",
];
export const ExperienceDataInResumes = [
  "Below 1 year",
  "1-2 years",
  "2-5 years",
  "5 years & above",
  "10 years & above",
];
export const InterviewType = ["L1 Interview", "L2 Interview", "HR Interview"];
export const DateFormat = "MM/DD/YYYY";
export const InterviewTypeL1 = ["L2 Interview", "HR Interview"];
export const InterviewTypeHR = ["HR Interview"];
