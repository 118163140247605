import "../../../assets/styles/css/IndexPage.css";

import React from "react";
import TopBar from "../../../layouts/Header/TopBar";
import Navbar from "../../../layouts/Header/Navbar";
import Footer from "../../../layouts/Footer";
import LifeAtSaasvaap from "./components/LifeAtSaasvaap";
import Benefits from "./components/Benefits";
import CurrentOpenings from "./components/CurrentOpenings";
import UploadResume from "./components/UploadResume";
import CustomButton from "../../../components/customComponents/common/CustomButton";
import image1 from "../../../assets/images/indexPage/image1.png";
import image2 from "../../../assets/images/indexPage/image2.jpg";
import image3 from "../../../assets/images/indexPage/image3.jpg";

const IndexPage = () => {
  return (
    <>
      <TopBar />
      <Navbar />
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              className="d-block w-100 slider-image "
              src={image1}
              alt="First slide"
            />
          </div>
          <div className="carousel-item">
            <img
              className="d-block w-100 slider-image "
              src={image2}
              alt="Second slide"
            />
          </div>

          <div className="carousel-item">
            <img
              className="d-block w-100 slider-image "
              src={image3}
              alt="Third slide"
            />
          </div>
        </div>
        <div className="carousel-caption d-none d-md-block">
          <span className="index-page-dashboard-content">
            <h2>Inspiring generations to come</h2>
            <br />
            <CustomButton
              color="primary"
              variant="contained"
              name="Current Openings"
              margin="normal"
              href="#current-openings"
            />
          </span>
        </div>
        <a
          className="carousel-control-prev"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
        </a>
        <a
          className="carousel-control-next"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
        </a>
      </div>

      <LifeAtSaasvaap />
      <Benefits />
      <div id="current-openings">
        <CurrentOpenings />
      </div>
      <UploadResume />
      <Footer />
    </>
  );
};
export default IndexPage;
