const validation = (values) => {
  let errors = { error: false };

  if (!values.email) {
    errors.email = " Please Enter Email";
    errors.error = true;
  } else if (!/\S+@saasvaap.com/.test(values.email)) {
    errors.email = " Enter a valid Email";
    errors.error = true;
  }

  return errors;
};

export default validation;
