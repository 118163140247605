import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  getAPIHandler,
  postAPIHandler,
} from "../../../../apiHandler/RestAPIHandler";
import API_URL_CONFIG from "../../../../config/API_URL_CONFIG.json";
import { Checkbox, Grid } from "@mui/material";
import CustomButton from "../../../../components/customComponents/common/CustomButton";
import { customMessages } from "../../../../CustomDatas";
import CustomSnackbar from "../../../../components/customComponents/common/CustomSnackbar";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Privilege = ({ roleDetails, handleCloseButton, getRoleList }) => {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [notificationData, setNotificationData] = useState({
    message: "",
    severity: "",
  });

  const [value, setValue] = useState(0);
  const [privileges, setPrivileges] = useState([]);
  const getAllPrivilages = () => {
    getAPIHandler(
      API_URL_CONFIG.GET_ALL_PREVILEGES_BY_ITEM + "?_id=" + roleDetails?._id
    )
      .then((result) => {
        if (result.response.status === 200) {
          setPrivileges(result?.response?.data?.data?.resultArray[0]);
        } else {
          setPrivileges([]);
        }
        if (result.response.status === 401) {
          setOpenSnackbar(true);
          setNotificationData({
            message: "Session Expired, Please login",
            severity: "error",
          });
          setTimeout(() => {
            window.location.replace("/admin-console-login");
            localStorage.clear();
          }, 1000);
        }
      })
      .catch((err) => {
        setPrivileges([]);
      });
  };

  useEffect(() => {
    setPrivileges([]);
    setValue(0);

    if (roleDetails?._id) getAllPrivilages(); // eslint-disable-next-line
  }, [roleDetails?._id]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const updatePrivilegesApi = () => {
    const postData = {
      _id: roleDetails?._id,
      privilege: privileges.filter((item) => item.defaultValue === true),
    };
    postAPIHandler(API_URL_CONFIG.UPDATE_ROLE, postData)
      .then((result) => {
        if (result.response.status === 401) {
          setOpenSnackbar(true);
          setNotificationData({
            message: "Session Expired, Please login",
            severity: "error",
          });
          setTimeout(() => {
            window.location.replace("/admin-console-login");
            localStorage.clear();
          }, 1000);
        }
        if (result.response.status === 200) {
          return (
            setOpenSnackbar(true),
            setNotificationData({
              message: customMessages.privilegeUpdatedSuccess,
              severity: customMessages.severitySuccess,
            }),
            setTimeout(() => {
              handleCloseButton();
              getRoleList(1);
            }, 1000)
          );
        } else if (result.response.status === 412) {
          return (
            setOpenSnackbar(true),
            setNotificationData({
              message: customMessages.commonError,
              severity: customMessages.severityError,
            })
          );
        }
      })
      .catch(() => {
        setOpenSnackbar(true);
        setNotificationData({
          message: customMessages.commonError,
          severity: customMessages.severityError,
        });
      });
  };
  const handleClose = () => {
    setOpenSnackbar(false);
  };
  const privilegeChange = (event, item) => {
    let newItem = {
      value: item.value,
      key: item.key,
      type: item.type,
      defaultValue: event.target.checked,
    };

    let index = privileges.findIndex(({ key }) => key === newItem.key);

    if (index === -1) {
      privileges.push(newItem);
    } else {
      privileges[index] = newItem;
    }
  };
  return (
    <div style={{ minWidth: 800 }}>
      {/* Custom notification snackbar */}
      <CustomSnackbar
        openSnackbar={openSnackbar}
        message={notificationData?.message}
        handleClose={handleClose}
        severity={notificationData?.severity}
      />
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        indicatorColor="primary"
        textColor="inherit"
        variant="fullWidth"
      >
        <Tab label="TABLE" {...a11yProps(0)} />
        <Tab label="BUTTON" {...a11yProps(1)} />
        <Tab label="MENU" {...a11yProps(2)} />
        <Tab label="NOTIFICATION" {...a11yProps(3)} />
      </Tabs>

      <TabPanel value={value} index={0}>
        {privileges
          .filter((item) => item.type === "TABLE")
          .map((item) => {
            return (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Checkbox
                      {...label}
                      onChange={(event) => privilegeChange(event, item)}
                      defaultChecked={item?.defaultValue}
                    />
                    <span>{item.value}</span>
                  </Grid>
                </Grid>
              </>
            );
          })}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {privileges
          .filter((item) => item.type === "BUTTON")
          .map((item) => {
            return (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Checkbox
                      {...label}
                      onChange={(event) => privilegeChange(event, item)}
                      defaultChecked={item?.defaultValue}
                    />
                    <span>{item.value}</span>
                  </Grid>
                </Grid>
              </>
            );
          })}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {privileges
          .filter((item) => item.type === "MENU")
          .map((item) => {
            return (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Checkbox
                      {...label}
                      onChange={(event) => privilegeChange(event, item)}
                      defaultChecked={item?.defaultValue}
                    />
                    <span>{item.value}</span>
                  </Grid>
                </Grid>
              </>
            );
          })}
      </TabPanel>
      <TabPanel value={value} index={3}>
        {privileges
          .filter((item) => item.type === "NOTIFICATION")
          .map((item) => {
            return (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Checkbox
                      {...label}
                      onChange={(event) => privilegeChange(event, item)}
                      defaultChecked={item?.defaultValue}
                    />
                    <span>{item.value}</span>
                  </Grid>
                </Grid>
              </>
            );
          })}
      </TabPanel>
      <hr />
      <br />
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
        spacing={2}
      >
        <Grid xs={6} sm={2}>
          <CustomButton
            onClick={handleCloseButton}
            color="secondary"
            name="Cancel"
          />
        </Grid>
        <Grid xs={6} sm={2}>
          <CustomButton name="Update" onClick={updatePrivilegesApi} />
        </Grid>
      </Grid>
    </div>
  );
};
export default Privilege;
