import { Pagination } from "@mui/material";
import React from "react";
import CustomButton from "../../../components/customComponents/common/CustomButton";
import {
  ListSkeleton,
  PaginationSkeleton,
} from "../../../components/Skeleton/admin";
import { RowActionMenu } from "./RowActionMenu";

//  Roles table start

export const RolesTable = ({
  listLoader,
  roleList,
  OpenDialog,
  ListEmptyState,
  roleListCount,
  pageNo,
  paginationChange,
}) => {
  return (
    <div className="table-responsive-xl">
      <div className="wrap-table100">
        <div className="shadow table100" style={{ minHeight: 300 }}>
          <table>
            <thead>
              <tr className="table100-head">
                <th scope="col" className="column">
                  SL No.
                </th>
                <th scope="col" className="column-td">
                  Role
                </th>
                <th scope="col" className="column-td">
                  Description
                </th>
                <th scope="col" className="column-td"></th>
                <th scope="col" className="column-td"></th>
              </tr>
            </thead>
            {listLoader ? (
              <ListSkeleton />
            ) : roleList ? (
              roleList.length ? (
                roleList.map((item, i) => {
                  return (
                    <tbody key={i}>
                      <tr>
                        <td className="column">
                          {pageNo === 1 ? i + 1 : (pageNo - 1) * 5 + (i + 1)}
                        </td>
                        <td className="column-td">{item.roleName}</td>
                        <td className="column-td">{item.roleDescription}</td>
                        <td className="column-td">
                          <CustomButton
                            size="small"
                            name=" Privileges"
                            onClick={() => OpenDialog("Privilage", item)}
                          />
                        </td>
                        <td className="column-td">
                          <RowActionMenu item={item} OpenDialog={OpenDialog} />
                        </td>
                      </tr>
                    </tbody>
                  );
                })
              ) : (
                <ListEmptyState emptyState="No Roles Added" />
              )
            ) : (
              <ListEmptyState emptyState="No Roles Added" />
            )}
          </table>
        </div>
        {listLoader ? (
          <PaginationSkeleton />
        ) : roleList ? (
          roleListCount > 5 ? (
            <div className="job-list-pagination">
              <Pagination
                count={roleListCount < 5 ? 1 : Math.ceil(roleListCount / 5)}
                onChange={paginationChange}
                page={pageNo}
                color="primary"
                size="small"
              />
            </div>
          ) : (
            <div style={{ marginBottom: 20 }} />
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
