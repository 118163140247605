const validation = (configurationOption, inputValue) => {
  let errors = { error: false };
  if (!configurationOption) {
    errors.configurationOption = "Please select option";
    errors.error = true;
  }
  if (!inputValue) {
    errors.inputValue = "Please enter value";
    errors.error = true;
  }

  return errors;
};

export default validation;
