import axios from "axios";
// Post API Handler
import API_URL_CONFIG from "../config/API_URL_CONFIG.json";

export const postAPIHandler = (URL, postData) => {
  let token = localStorage.getItem("jwt_access_token");
  return new Promise((resolve, reject) => {
    URL = API_URL_CONFIG.REACT_APP_API_BASE_URL + URL;
    let headers = {
      Authorization: token,
    };
    return axios
      .post(URL, postData, { headers })
      .then((response) => {
        if (response?.status === 200) {
          return resolve({ response: response, severity: "success" });
        } else {
          return resolve({ response: response, severity: "error" });
        }
      })
      .catch((error) => {
        return resolve(error);
      });
  });
};

// noAuth post Api Handler
export const postNoAuthAPIHandler = (URL, postData) => {
  return new Promise((resolve, reject) => {
    URL = API_URL_CONFIG.REACT_APP_API_BASE_URL + URL;
    return axios
      .post(URL, postData)
      .then((response) => {
        if (response?.status === 200) {
          return resolve({ response: response, severity: "success" });
        } else {
          return resolve({ response: response, severity: "error" });
        }
      })
      .catch((error) => {
        return resolve(error);
      });
  });
};
// GET API Handler
export const getAPIHandler = (URL) => {
  let token = localStorage.getItem("jwt_access_token");
  return new Promise((resolve, reject) => {
    URL = API_URL_CONFIG.REACT_APP_API_BASE_URL + URL;
    let headers = {
      Authorization: token,
    };

    return axios
      .get(URL, { headers })
      .then((response) => {
        if (response?.status === 200) {
          return resolve({ response: response, severity: "success" });
        } else {
          return resolve({ response: response, severity: "error" });
        }
      })
      .catch((error) => {
        return resolve(error);
      });
  });
};
// Login API Handler
export const loginAPIHandler = (loginCredential, URL) => {
  URL = API_URL_CONFIG.REACT_APP_API_BASE_URL + URL;
  return new Promise((resolve, reject) => {
    return axios
      .post(URL, loginCredential)
      .then((response) => {
        if (response?.status === 200) {
          localStorage.setItem(
            "jwt_access_token",
            "Bearer " + response?.data?.data?.token
          );
          localStorage.setItem("user_id", response?.data?.data?.id);
          localStorage.setItem(
            "user_privileges",
            response?.data?.data?.privileges
          );
          return resolve({ response: response, severity: "success" });
        } else {
          return resolve({ response: response, severity: "error" });
        }
      })
      .catch((error) => {
        return resolve(error);
      });
  });
};

//LinkedIn Share Post API
export const postAPILinkedIn = (postData, authToken) => {
  return new Promise((resolve, reject) => {
    let URL = "https://api.linkedin.com/v2/ugcPosts";
    let headers = {
      Authorization: "Bearer " + authToken,
    };

    return axios
      .post(URL, postData, { headers })
      .then((response) => {
        if (response?.status === 201) {
          return resolve({ response: response, severity: "success" });
        } else {
          return resolve({ response: response, severity: "error" });
        }
      })
      .catch((error) => {
        return resolve(error);
      });
  });
};

//facebook Share Post API
export const postAPIFaceBook = (postData, authToken) => {
  let token = authToken;
  return new Promise((resolve, reject) => {
    let URL =
      "https://graph.facebook.com/278580642318984/feed?message=" +
      `${postData}` +
      "&access_token=" +
      `${authToken}`;
    let headers = {
      Authorization: token,
    };
    return axios
      .post(URL, postData, { headers })
      .then((response) => {
        if (response?.status === 200) {
          return resolve({ response: response, severity: "success" });
        } else {
          return resolve({ response: response, severity: "error" });
        }
      })
      .catch((error) => {
        return resolve(error);
      });
  });
};
