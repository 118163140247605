import { Pagination } from "@mui/material";
import React from "react";
import CustomButton from "../../../components/customComponents/common/CustomButton";
import {
  ListSkeleton,
  PaginationSkeleton,
} from "../../../components/Skeleton/admin";

// Pending Request table start

export const PendingRequestTable = ({
  listLoader,
  requestList,
  OpenDialog,
  requestListCount,
  ListEmptyState,
  paginationChange,
  pageNo,
}) => {
  return (
    <div className="table-responsive-xl">
      <div className="wrap-table100">
        <div className="shadow table100" style={{ minHeight: 300 }}>
          <table>
            <thead>
              <tr className="table100-head">
                <th scope="col" className="column">
                  SL No.
                </th>
                <th scope="col" className="column-td">
                  Name
                </th>
                <th scope="col" className="column-td">
                  Email
                </th>
                <th scope="col" className="column-td">
                  Designation
                </th>
                <th scope="col" className="column-td">
                  Status
                </th>
                <th scope="col" className="column-td"></th>
                <th scope="col" className="column-td"></th>
                <th scope="col" className="column-td"></th>
                <th scope="col" className="column"></th>
                <th scope="col" className="column"></th>
              </tr>
            </thead>
            {listLoader ? (
              <ListSkeleton />
            ) : requestList ? (
              requestList.length ? (
                requestList.map((item, i) => {
                  return (
                    <tbody key={i}>
                      <tr>
                        <td className="column">
                          {pageNo === 1 ? i + 1 : (pageNo - 1) * 5 + (i + 1)}
                        </td>
                        <td className="column-td">{item.userName}</td>
                        <td className="column-td">{item.email}</td>
                        <td className="column-td">{item.designation}</td>
                        <td className="column-td">{item.userStatus}</td>
                        <td className="column">
                          <CustomButton
                            size="small"
                            name="Accept"
                            onClick={() => OpenDialog("AcceptRequest", item)}
                          />
                        </td>
                        <td className="column">
                          <CustomButton
                            onClick={() => OpenDialog("RejectRequest", item)}
                            size="small"
                            color="error"
                            name="Reject"
                          />
                        </td>
                        <td className="column-td"></td>
                        <td className="column-td"></td>
                        <td className="column-td"></td>
                      </tr>
                    </tbody>
                  );
                })
              ) : (
                <ListEmptyState emptyState="No Pending Users" />
              )
            ) : (
              <ListEmptyState emptyState="No Pending  Users" />
            )}
          </table>
        </div>
        {listLoader ? (
          <PaginationSkeleton />
        ) : requestList ? (
          requestListCount > 5 ? (
            <div className="job-list-pagination">
              <Pagination
                count={
                  requestListCount < 5 ? 1 : Math.ceil(requestListCount / 5)
                }
                onChange={paginationChange}
                page={pageNo}
                color="primary"
                size="small"
              />
            </div>
          ) : (
            <div style={{ marginBottom: 20 }} />
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export const ActiveUsersTable = ({
  listLoader,
  requestList,
  OpenDialog,
  ListEmptyState,
  requestListCount,
  paginationChange,
  pageNo,
}) => {
  return (
    <div className="table-responsive-xl">
      <div className="wrap-table100">
        <div className="shadow table100" style={{ minHeight: 300 }}>
          <table>
            <thead>
              <tr className="table100-head">
                <th scope="col" className="column">
                  SL No.
                </th>
                <th scope="col" className="column-td">
                  Name
                </th>
                <th scope="col" className="column-td">
                  Email
                </th>
                <th scope="col" className="column-td">
                  Designation
                </th>
                <th scope="col" className="column-td">
                  Status
                </th>

                <th scope="col" className="column-td"></th>
                <th scope="col" className="column-td"></th>
              </tr>
            </thead>
            {listLoader ? (
              <ListSkeleton />
            ) : requestList ? (
              requestList.length ? (
                requestList.map((item, i) => {
                  return (
                    <tbody key={i}>
                      <tr>
                        <td className="column">
                          {pageNo === 1 ? i + 1 : (pageNo - 1) * 5 + (i + 1)}
                        </td>
                        <td className="column-td">{item.userName}</td>
                        <td className="column-td">{item.email}</td>
                        <td className="column-td">{item.designation}</td>
                        <td className="column-td">{item.userStatus}</td>
                        <td className="column">
                          {item.isAdmin != true ? (
                            <CustomButton
                              size="small"
                              name=" Edit Role"
                              onClick={() => OpenDialog("EditRole", item)}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                        <td className="column">
                          {item.isAdmin != true ? (
                            <CustomButton
                              onClick={() => OpenDialog("RejectRequest", item)}
                              size="small"
                              color="error"
                              name="Reject"
                            />
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    </tbody>
                  );
                })
              ) : (
                <ListEmptyState emptyState="No Users Found" />
              )
            ) : (
              <ListEmptyState emptyState="No Users Found" />
            )}
          </table>
        </div>
        {listLoader ? (
          <PaginationSkeleton />
        ) : requestList ? (
          requestListCount > 5 ? (
            <div className="job-list-pagination">
              <Pagination
                count={
                  requestListCount < 5 ? 1 : Math.ceil(requestListCount / 5)
                }
                onChange={paginationChange}
                page={pageNo}
                color="primary"
                size="small"
              />
            </div>
          ) : (
            <div style={{ marginBottom: 20 }} />
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export const RejectedUsersTable = ({
  listLoader,
  requestList,
  OpenDialog,
  ListEmptyState,
  requestListCount,
  paginationChange,
  pageNo,
}) => {
  return (
    <div className="table-responsive-xl">
      <div className="wrap-table100">
        <div className="shadow table100" style={{ minHeight: 300 }}>
          <table>
            <thead>
              <tr className="table100-head">
                <th scope="col" className="column">
                  SL No.
                </th>
                <th scope="col" className="column-td">
                  Name
                </th>
                <th scope="col" className="column-td">
                  Email
                </th>
                <th scope="col" className="column-td">
                  Designation
                </th>
                <th scope="col" className="column-td">
                  Status
                </th>

                <th scope="col" className="column-td"></th>
              </tr>
            </thead>
            {listLoader ? (
              <ListSkeleton />
            ) : requestList ? (
              requestList.length ? (
                requestList.map((item, i) => {
                  return (
                    <tbody key={i}>
                      <tr>
                        <td className="column">
                          {pageNo === 1 ? i + 1 : (pageNo - 1) * 5 + (i + 1)}
                        </td>
                        <td className="column-td">{item.userName}</td>
                        <td className="column-td">{item.email}</td>
                        <td className="column-td">{item.designation}</td>
                        <td className="column-td">{item.userStatus}</td>
                        <td className="column">
                          <CustomButton
                            size="small"
                            name="Approve"
                            onClick={() => OpenDialog("AcceptRequest", item)}
                          />
                        </td>
                      </tr>
                    </tbody>
                  );
                })
              ) : (
                <ListEmptyState emptyState="No Users Found" />
              )
            ) : (
              <ListEmptyState emptyState="No Users Found" />
            )}
          </table>
        </div>
        {listLoader ? (
          <PaginationSkeleton />
        ) : requestList ? (
          requestListCount > 5 ? (
            <div className="job-list-pagination">
              <Pagination
                count={
                  requestListCount < 5 ? 1 : Math.ceil(requestListCount / 5)
                }
                onChange={paginationChange}
                page={pageNo}
                color="primary"
                size="small"
              />
            </div>
          ) : (
            <div style={{ marginBottom: 20 }} />
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
