import React, { useState } from "react";
import { Grid } from "@mui/material";
import { postAPIHandler } from "../../../../../apiHandler/RestAPIHandler";
import CustomButton from "../../../../../components/customComponents/common/CustomButton";
import API_URL_CONFIG from "../../../../../config/API_URL_CONFIG.json";
import CustomSnackbar from "../../../../../components/customComponents/common/CustomSnackbar";
import { customMessages } from "../../../../../CustomDatas";
const RejectUser = ({
  handleCloseButton,
  applicantDetails,
  getApplicantList,
}) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [notificationData, setNotificationData] = useState({
    message: "",
    severity: "",
  });
  const rejectApplicant = () => {
    const postData = {
      applicantStatus: "rejected",
      interviewStatus: "",
      _id: applicantDetails?._id,
    };
    const URL = API_URL_CONFIG.APPLICANT_STATUS_UPDATE;

    postAPIHandler(URL, postData)
      .then((result) => {
        if (result.response.status === 200) {
          return (
            setOpenSnackbar(true),
            setNotificationData({
              message: customMessages.rejectApplicantSuccess,
              severity: customMessages.severitySuccess,
            }),
            setTimeout(() => {
              handleCloseButton();
              getApplicantList();
            }, 1000)
          );
        } else if (result.response.status === 401) {
          setOpenSnackbar(true);
          setNotificationData({
            message: "Session Expired, Please login",
            severity: "error",
          });
          setTimeout(() => {
            window.location.replace("/admin-console-login");
            localStorage.clear();
          }, 1000);
        } else if (result.response.status === 412) {
          return (
            setOpenSnackbar(true),
            setNotificationData({
              message: customMessages.commonError,
              severity: customMessages.severityError,
            })
          );
        }
      })

      .catch(() => {
        setOpenSnackbar(true);
        setNotificationData({
          message: customMessages.commonError,
          severity: customMessages.severityError,
        });
      });
  };
  const handleClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      {/* Custom notification snackbar */}
      <CustomSnackbar
        openSnackbar={openSnackbar}
        message={notificationData?.message}
        handleClose={handleClose}
        severity={notificationData?.severity}
      />
      <Grid
        style={{ minWidth: 400 }}
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid xs={12}>
          <span>
            Are you sure want to Reject -
            <span style={{ color: "red" }}>{applicantDetails?.fullName}</span>
          </span>
        </Grid>
      </Grid>
      <hr />
      <br />
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
        spacing={2}
      >
        <Grid xs={6} sm={3}>
          <CustomButton
            color="secondary"
            onClick={handleCloseButton}
            name="No"
          />
        </Grid>
        <Grid xs={6} sm={3}>
          <CustomButton name="Yes" color="primary" onClick={rejectApplicant} />
        </Grid>
      </Grid>
    </>
  );
};
export default RejectUser;
