import React from "react";

import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import { Pagination, Tooltip } from "@mui/material";
import {
  ListSkeleton,
  PaginationSkeleton,
} from "../../../components/Skeleton/admin";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import { RowActionMenu } from "./RowActionMenu";
import ShareIcon from "@mui/icons-material/Share";

// Drafted jobs table start

export const DraftedJobs = ({
  listLoader,
  jobList,
  OpenDialog,
  ListEmptyState,
  userPrivileges,
}) => {
  return (
    <div className="table-responsive-xl">
      <div className="wrap-table100">
        <div className="shadow table100" style={{ minHeight: 300 }}>
          <table>
            <thead>
              <tr className="table100-head">
                <th scope="col" className="column">
                  SL No.
                </th>
                <th scope="col" className="column-td">
                  Job Title
                </th>
                <th scope="col" className="column-td">
                  Location
                </th>
                <th scope="col" className="column-td">
                  Position
                </th>
                <th scope="col" className="column-td">
                  Experience
                </th>
                <th scope="col" className="column-td"></th>
              </tr>
            </thead>

            {listLoader ? (
              <ListSkeleton />
            ) : jobList ? (
              jobList.length ? (
                jobList.map((item, i) => {
                  return (
                    <tbody key={i}>
                      <tr>
                        <td className="column">{i + 1}</td>
                        <td className="column-td">{item.jobTitle}</td>
                        <td className="column-td">
                          {item.location.length > 1
                            ? item.location.join(", ")
                            : item.location}
                        </td>
                        <td className="column-td">{item.position}</td>
                        <td className="column-td">
                          {item.minimumExperience}-{item.maximumExperience}
                        </td>
                        <td className="column-td">
                          <RowActionMenu
                            item={item}
                            userPrivileges={userPrivileges}
                            OpenDialog={OpenDialog}
                          />
                        </td>
                      </tr>
                    </tbody>
                  );
                })
              ) : (
                <ListEmptyState emptyState="No drafted jobs" />
              )
            ) : (
              <ListEmptyState emptyState="No drafted jobs" />
            )}
          </table>
        </div>
      </div>
    </div>
  );
};
// Drafted jobs table end
// Submitted jobs table end
export const SubmittedJobs = ({
  listLoader,
  jobList,
  OpenDialog,
  ListEmptyState,
  CustomButton,
  pageNo,
  paginationChange,
  jobListCount,
  userPrivileges,
}) => {
  return (
    <div className=" table-responsive-xl">
      <div className=" wrap-table100">
        <div className=" shadow table100" style={{ minHeight: 300 }}>
          <table>
            <thead>
              <tr className="table100-head">
                <th scope="col" className="column">
                  SL No.
                </th>
                <th scope="col" className="column-td">
                  Job Title
                </th>
                <th scope="col" className="column-td">
                  Location
                </th>
                <th scope="col" className="column-td">
                  Position
                </th>
                <th scope="col" className="column-td">
                  Experience
                </th>
                <th scope="col" className="column-td"></th>
                <th scope="col" className="column-td"></th>
              </tr>
            </thead>
            {listLoader ? (
              <ListSkeleton />
            ) : jobList ? (
              jobList.length ? (
                jobList.map((item, i) => {
                  return (
                    <tbody key={i}>
                      <tr>
                        <td className="column">
                          {pageNo === 1 ? i + 1 : (pageNo - 1) * 5 + (i + 1)}
                        </td>
                        <td className="column-td">
                          <span
                            style={{ color: "rgba(54, 171, 155)" }}
                            onClick={() => OpenDialog("JobDetails", item)}
                          >
                            {item.jobTitle}
                          </span>
                        </td>
                        <td className="column-td">
                          {item.location.length > 1
                            ? item.location.join(", ")
                            : item.location}
                        </td>
                        <td className="column-td">{item.position}</td>
                        <td className="column-td">
                          {item.minimumExperience}-{item.maximumExperience}
                        </td>

                        <td className="column-td">
                          <CustomButton
                            size="small"
                            onClick={() => OpenDialog("PostJob", item)}
                            name="Post"
                          />
                        </td>
                        <td className="column-td">
                          <RowActionMenu
                            item={item}
                            userPrivileges={userPrivileges}
                            OpenDialog={OpenDialog}
                          />
                        </td>
                      </tr>
                    </tbody>
                  );
                })
              ) : (
                <ListEmptyState emptyState="No Submitted jobs" />
              )
            ) : (
              <ListEmptyState emptyState="No Submitted jobs" />
            )}
          </table>
        </div>

        {listLoader ? (
          <PaginationSkeleton />
        ) : jobList ? (
          jobListCount > 5 ? (
            <div className="job-list-pagination">
              <Pagination
                count={jobListCount < 5 ? 1 : Math.ceil(jobListCount / 5)}
                onChange={paginationChange}
                page={pageNo}
                color="primary"
                size="small"
              />
            </div>
          ) : (
            <div style={{ marginBottom: 20 }} />
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

/* Submitted job list end  */

// active jobs start

export const ActiveJobs = ({
  listLoader,
  jobList,
  OpenDialog,
  ListEmptyState,
  CustomButton,
  pageNo,
  paginationChange,
  toggle,
  setToggle,
  jobListCount,
  userPrivileges,
}) => {
  return (
    <div className=" table-responsive-xl">
      <div className=" wrap-table100">
        <div className=" shadow table100" style={{ minHeight: 300 }}>
          <table>
            <thead>
              <tr className="table100-head">
                <th scope="col" className="column">
                  SL No.
                </th>
                <th scope="col" className="column-td">
                  Job ID
                </th>
                <th
                  style={{ textAlign: "left", paddingLeft: 20 }}
                  scope="col"
                  className="column-td"
                >
                  Job Title
                </th>
                <th scope="col" className="column-td">
                  Location
                </th>
                <th scope="col" className="column-td">
                  Position
                </th>
                <th scope="col" className="column-td">
                  Experience
                </th>

                {userPrivileges.includes(
                  "JOBS_ACTIVE_TABLE_VIEW_CANDIDATE_ACTION_BUTTON"
                ) ? (
                  <th scope="col" className="column-td"></th>
                ) : (
                  <></>
                )}
                {userPrivileges.includes("JOBS_ACTIVE_TABLE_CLOSE_BUTTON") ? (
                  <th scope="col" className="column-td"></th>
                ) : (
                  <></>
                )}
                <th scope="col" className="column-td"></th>
                {userPrivileges.includes("ACTIVE_JOBS_PRIORITIZE_BUTTON") ? (
                  <th scope="col" className="column-td">
                    {toggle ? (
                      <Tooltip title="Click for all  list of jobs">
                        <StarIcon
                          onClick={() => setToggle(!toggle)}
                          style={{ color: "#ffeb3b" }}
                          fontSize="medium"
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Click for prioritized job list">
                        <StarBorderIcon
                          onClick={() => setToggle(!toggle)}
                          style={{ color: "#ffeb3b" }}
                          fontSize="medium"
                        />
                      </Tooltip>
                    )}
                  </th>
                ) : (
                  <th scope="col" className="column-td"></th>
                )}
              </tr>
            </thead>
            {listLoader ? (
              <ListSkeleton />
            ) : jobList ? (
              jobList.length ? (
                jobList.map((item, i) => {
                  return (
                    <tbody key={i}>
                      <tr>
                        <td className="column">
                          {pageNo === 1 ? i + 1 : (pageNo - 1) * 5 + (i + 1)}
                        </td>
                        <td className="column-td">{item.jobId}</td>
                        <td
                          style={{
                            textAlign: "left",
                            color: "rgba(54, 171, 155)",
                          }}
                          className="column-td"
                        >
                          {userPrivileges.includes(
                            "ACTIVE_JOBS_PRIORITIZE_BUTTON"
                          ) ? (
                            <span style={{ paddingRight: 5 }}>
                              {item.isPrioritized ? (
                                <Tooltip title="Click to un prioritize job">
                                  <StarIcon
                                    onClick={() =>
                                      OpenDialog("PriorityJob", item, true)
                                    }
                                    style={{ color: "#ffeb3b" }}
                                    fontSize="small"
                                  />
                                </Tooltip>
                              ) : (
                                <Tooltip title="Click to prioritize job">
                                  <StarBorderIcon
                                    onClick={() =>
                                      OpenDialog("PriorityJob", item, false)
                                    }
                                    style={{ color: "#ffeb3b" }}
                                    fontSize="small"
                                  />
                                </Tooltip>
                              )}
                            </span>
                          ) : (
                            <span style={{ paddingLeft: 25 }}></span>
                          )}
                          <span onClick={() => OpenDialog("JobDetails", item)}>
                            {item.jobTitle}
                          </span>
                        </td>
                        <td className="column-td">
                          {item.location.length > 1
                            ? item.location.join(", ")
                            : item.location}
                        </td>
                        <td className="column-td">{item.position}</td>
                        <td className="column-td">
                          {item.minimumExperience}-{item.maximumExperience}
                        </td>
                        {userPrivileges.includes(
                          "JOBS_ACTIVE_TABLE_VIEW_CANDIDATE_ACTION_BUTTON"
                        ) ? (
                          <td className="column-td">
                            <Tooltip title="Applied candidates">
                              <FolderSharedIcon
                                color="primary"
                                onClick={() =>
                                  OpenDialog("AppliedCandidates", item)
                                }
                              />
                            </Tooltip>
                          </td>
                        ) : (
                          ""
                        )}
                        {userPrivileges.includes(
                          "JOBS_ACTIVE_TABLE_CLOSE_BUTTON"
                        ) ? (
                          <td className="column">
                            <CustomButton
                              size="small"
                              onClick={() => OpenDialog("CloseJob", item)}
                              name="Close"
                              color="error"
                            />
                          </td>
                        ) : (
                          ""
                        )}

                        <td className="column-td">
                          {userPrivileges.includes(
                            "JOBS_ACTIVE_TABLE_EDIT_BUTTON",
                            "JOBS_ACTIVE_TABLE_DELETE_BUTTON"
                          ) ? (
                            <RowActionMenu
                              isActiveTable={true}
                              userPrivileges={userPrivileges}
                              item={item}
                              OpenDialog={OpenDialog}
                            />
                          ) : (
                            ""
                          )}
                        </td>

                        <td>
                          <ShareIcon
                            size="small"
                            onClick={() => OpenDialog("ShareJobPost", item)}
                            name="Share"
                            color="primary"
                          />
                        </td>
                      </tr>
                    </tbody>
                  );
                })
              ) : (
                <ListEmptyState emptyState="No active jobs" />
              )
            ) : (
              <ListEmptyState emptyState="No active jobs" />
            )}
          </table>
        </div>

        {listLoader ? (
          <PaginationSkeleton />
        ) : jobList ? (
          jobListCount > 5 ? (
            <div className="job-list-pagination">
              <Pagination
                count={jobListCount < 5 ? 1 : Math.ceil(jobListCount / 5)}
                onChange={paginationChange}
                page={pageNo}
                color="primary"
                size="small"
              />
            </div>
          ) : (
            <div style={{ marginBottom: 20 }} />
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
// active jobs end

// closed Jobs start
export const ClosedJobs = ({
  listLoader,
  jobList,
  OpenDialog,
  ListEmptyState,
  pageNo,
  jobListCount,
  paginationChange,
  CustomButton,
  userPrivileges,
}) => {
  return (
    <div className=" table-responsive-xl">
      <div className=" wrap-table100">
        <div className=" shadow table100" style={{ minHeight: 300 }}>
          <table>
            <thead>
              <tr className="table100-head">
                <th scope="col" className="column">
                  SL No.
                </th>
                <th scope="col" className="column-td">
                  Job ID
                </th>
                <th scope="col" className="column-td">
                  Job Title
                </th>
                <th scope="col" className="column-td">
                  Location
                </th>
                <th scope="col" className="column-td">
                  Position
                </th>
                <th scope="col" className="column-td">
                  Experience
                </th>

                {userPrivileges.includes("CLOSED_JOBS_REPOST_BUTTON") ? (
                  <th scope="col" className="column-td"></th>
                ) : (
                  <></>
                )}
              </tr>
            </thead>
            {listLoader ? (
              <ListSkeleton />
            ) : jobList ? (
              jobList.length ? (
                jobList.map((item, i) => {
                  return (
                    <tbody key={i}>
                      <tr>
                        <td className="column">
                          {pageNo === 1 ? i + 1 : (pageNo - 1) * 5 + (i + 1)}
                        </td>
                        <td className="column-td">{item.jobId}</td>
                        <td className="column-td">{item.jobTitle}</td>
                        <td className="column-td">
                          {item.location.length > 1
                            ? item.location.join(", ")
                            : item.location}
                        </td>
                        <td className="column-td">{item.position}</td>
                        <td className="column-td">
                          {item.minimumExperience}-{item.maximumExperience}
                        </td>
                        {userPrivileges.includes(
                          "CLOSED_JOBS_REPOST_BUTTON"
                        ) ? (
                          <td className="column-td">
                            <CustomButton
                              size="small"
                              onClick={() =>
                                OpenDialog("EditJob", item, " ", "RepostJob")
                              }
                              name="Repost"
                            />
                          </td>
                        ) : (
                          ""
                        )}
                      </tr>
                    </tbody>
                  );
                })
              ) : (
                <ListEmptyState emptyState="No Closed jobs" />
              )
            ) : (
              <ListEmptyState emptyState="No Closed jobs" />
            )}
          </table>
        </div>

        {listLoader ? (
          <PaginationSkeleton />
        ) : jobList ? (
          jobListCount > 5 ? (
            <div className="job-list-pagination">
              <Pagination
                count={jobListCount < 5 ? 1 : Math.ceil(jobListCount / 5)}
                onChange={paginationChange}
                page={pageNo}
                color="primary"
                size="small"
              />
            </div>
          ) : (
            <div style={{ marginBottom: 20 }} />
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
