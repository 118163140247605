import { Routes, Route, Navigate } from "react-router-dom";
import ApplyJobPage from "../../view/public/ApplyJobPage/Index";
import IndexPage from "../../view/public/IndexPage";
import JobDescriptionPage from "../../view/public/JobDescriptionPage/Index";
import JobList from "../../view/public/JobList";
import LoginPage from "../../view/admin/LoginPage";
import JobsPage from "../../view/admin/JobsPage";
import RegisterPage from "../../view/admin/RegisterPage";
import PageNotFound from "../../view/common/PageNotFound";
import ForgotPasswordPage from "../../view/common/ForgotPasswordPage";
import ChangePasswordPage from "../../view/common/ChangePasswordPage";
import RolesPage from "../../view/admin/RolesPage/index";
import RequestsPage from "../../view/admin/RequestsPage";
import ProfilePage from "../../view/admin/ProfilePage/Index";
import AdminDashboard from "../../view/admin/Dashboard/Admin";
import SuperAdminDashboard from "../../view/admin/Dashboard/SuperAdmin";
import ResumesDirectory from "../../view/admin/ResumesDirectory/index";
import InterviewDirectory from "../../view/admin/InterviewDirectory/index";
const RoutePage = () => {
  let token = localStorage.getItem("jwt_access_token");
  return (
    <>
      <Routes>
        <Route path="/admin-console-login" element={<LoginPage />} />
        <Route path="/" element={<IndexPage />} />
        <Route path="/job-list" element={<JobList />} />
        <Route path="/job-description/:_id" element={<JobDescriptionPage />} />
        <Route path="/job-apply/:jobId/:jobTitle" element={<ApplyJobPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/change-password" element={<ChangePasswordPage />} />
        <Route path="/admin-dashboard" element={<AdminDashboard />} />
        <Route
          path="/super-admin-dashboard"
          element={<SuperAdminDashboard />}
        />
        <Route path="/jobs" element={<JobsPage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/roles" element={<RolesPage />} />
        <Route path="/resumes-directory" element={<ResumesDirectory />} />
        <Route path="/interview-directory" element={<InterviewDirectory />} />
        <Route path="/requests" element={<RequestsPage />} />
        {token ? (
          <>
            <Route path="*" element={<PageNotFound />} />
          </>
        ) : (
          <>
            <Route path="*" element={<Navigate to="/" replace />} />
            {[
              "/jobs",
              "/roles",
              "/requests",
              "/profile",
              "/admin-dashboard",
              "/super-admin-dashboard",
              "/resumes-directory",
              "/interview-directory",
            ].map((path) => (
              <Route
                path={path}
                element={<Navigate to="/admin-console-login" replace />}
              />
            ))}
          </>
        )}
      </Routes>
    </>
  );
};

export default RoutePage;
