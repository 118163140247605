import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import logo from "../../../assets/images/indexPage/logo.png";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import "../../../assets/styles/css/Navbar.css";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Popover from "@mui/material/Popover";
import { postAPIHandler } from "../../../apiHandler/RestAPIHandler";
import NotificationView from "./NotificationView";
import API_URL_CONFIG from "../../../config/API_URL_CONFIG.json";
import Badge from "@mui/material/Badge";

const AdminHeader = () => {
  let navigate = useNavigate();

  const [profileOptions, setProfileOptions] = useState(null);
  const [userPrivileges, setUserPrivileges] = useState([]);
  const [dialogType, setDialogType] = useState();
  const [notificatonCount, setNotificationCount] = useState("");

  useEffect(() => {
    const privileges = localStorage.getItem("user_privileges");
    setUserPrivileges(privileges);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getNotificationCount();
    }, 1000); // 1 second reload time
    return () => clearInterval(intervalId); // clear the interval time
  }, []);

  const open = Boolean(profileOptions);
  const handleClick = (event) => {
    setProfileOptions(event.currentTarget);
  };
  const handleClose = () => {
    setProfileOptions(null);
  };
  const logOut = () => {
    localStorage.clear();
    navigate("/admin-console-login");
  };
  const OpenDialog = (e, type) => {
    setDialogType(e);
  };
  const buildAPIRequestBody = (isforList, pageNumber) => {
    let requestBodyObject = {
      filterCategory: "notifications",
      filterTemplate: "NOTIFICATION_DATA_FILTER_TEMPLATE_V1.json",
    };
    let filterArray = [];
    filterArray.push({
      fieldKey: "isOpened",
      filterValue: false,
      fieldOperator: "EQUAL",
      isLikeFilter: false,
    });
    requestBodyObject["filter"] = filterArray;

    return requestBodyObject;
  };
  //api for get notification count for jobs
  const getNotificationCount = () => {
    let requestData = buildAPIRequestBody(false, null);
    return new Promise((resolve, reject) => {
      postAPIHandler(API_URL_CONFIG.GET_NOTIFICATION_COUNT, requestData)
        .then((result) => {
          if (result.message === "Network Error") {
            setNotificationCount(0);
            resolve();
          } else {
            setNotificationCount(result?.response.data.data[0].count);
            resolve();
          }
        })
        .catch((err) => {
          setNotificationCount(0);
          resolve();
        });
    });
  };
  const handleCloseButton = () => {
    setDialogType("");
  };
  const openPopup = Boolean(dialogType);
  const id = openPopup ? "simple-popover" : undefined;

  return (
    <>
      <div
        className="navbar navbar-expand navbar-light bg-white topbar mb-6 static-top shadow"
        style={{ height: 80 }}
      >
        <div className="container">
          <Link
            to="/admin-dashboard"
            className="sidebar-brand d-flex align-items-center "
            style={{ marginLeft: 20 }}
          >
            <img src={logo} alt="saasvaap" height="36" />
          </Link>

          <div>
            <Link
              className="sidebar-brand d-flex align-items-center "
              style={{
                marginLeft: 300,
                marginTop: 10,
                color: "rgba(54, 171, 155)",
                textDecoration: "none",
                fontWeight: 900,
              }}
              to="/jobs"
            >
              <p> {userPrivileges.includes("JOBS-DIRECTORY") ? "Jobs" : ""}</p>
            </Link>
          </div>

          <div>
            <Link
              className="sidebar-brand d-flex align-items-center "
              style={{
                marginLeft: 50,
                marginTop: 10,
                color: "rgba(54, 171, 155)",
                textDecoration: "none",
                fontWeight: 900,
              }}
              to="/resumes-directory"
            >
              <p>
                {userPrivileges.includes("RESUMES-DIRECTORY") ? "Resumes" : ""}
              </p>
            </Link>
          </div>

          <div>
            <Link
              className="sidebar-brand d-flex align-items-center "
              style={{
                marginLeft: 50,
                marginTop: 10,
                color: "rgba(54, 171, 155)",
                textDecoration: "none",
                fontWeight: 900,
              }}
              to="/interview-directory"
            >
              {/* <p>
                {userPrivileges.includes("INTERVIEW-DIRECTORY") ? "Resumes" : ""}
              </p> */}
              {/* <p>Interviews</p> */}
            </Link>
          </div>

          <ul className="navbar-nav ml-auto">
            <div style={{ marginRight: 20 }}>
              <Badge
                badgeContent={notificatonCount ? notificatonCount : 0}
                color="error"
              >
                <NotificationsIcon
                  style={{
                    color: "#1589F0",
                    cursor: "pointer",
                    fontSize: 35,
                  }}
                  onClick={() => OpenDialog("LatestNotificationList")}
                  name="notification"
                />
              </Badge>
            </div>
            <AccountCircleOutlinedIcon
              id="basic-button"
              fontSize="large"
              color="primary"
              onClick={handleClick}
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            />
            <Menu
              id="basic-menu"
              anchorEl={profileOptions}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <Link className="dropdown-link" to="/profile">
                <MenuItem>My Profile</MenuItem>
              </Link>

              <MenuItem onClick={logOut}>Logout</MenuItem>
            </Menu>
          </ul>
        </div>
        {/*Notification Dialog */}
        <Popover
          style={{ marginTop: 50 }}
          id={id}
          open={openPopup}
          dialogType={dialogType === "LatestNotificationList"}
          onClose={handleCloseButton}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
          }}
        >
          <NotificationView getNotificationCount={getNotificationCount} />
        </Popover>
      </div>
    </>
  );
};
export default AdminHeader;
