import React, { useState } from "react";
import { Grid } from "@mui/material";
import { postAPIHandler } from "../../../../apiHandler/RestAPIHandler";
import CustomButton from "../../../../components/customComponents/common/CustomButton";
import API_URL_CONFIG from "../../../../config/API_URL_CONFIG.json";
import CustomSnackbar from "../../../../components/customComponents/common/CustomSnackbar";
import { customMessages } from "../../../../CustomDatas";
const PostJob = ({ handleCloseButton, getJobList, jobDetails }) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [notificationData, setNotificationData] = useState({
    message: "",
    severity: "",
  });
  const postJob = () => {
    const postData = {
      jobTitle: jobDetails?.jobTitle,
      jobDescription: jobDetails?.jobDescription,
      position: jobDetails?.position,
      keySkills: jobDetails?.keySkills,
      minimumExperience: jobDetails?.minimumExperience,
      maximumExperience: jobDetails?.maximumExperience,
      minimumAnnualSalary: jobDetails?.minimumAnnualSalary,
      maximumAnnualSalary: jobDetails?.maximumAnnualSalary,
      timeShift: jobDetails?.timeShift,
      fullTime: jobDetails?.fullTime,
      partTime: jobDetails?.partTime,
      location: jobDetails?.location,
      status: "active",
      _id: jobDetails?._id,
    };
    const URL = API_URL_CONFIG.EDIT_JOB;

    postAPIHandler(URL, postData)
      .then((result) => {
        if (result.response.status === 401) {
          setOpenSnackbar(true);
          setNotificationData({
            message: "Session Expired, Please login",
            severity: "error",
          });
          setTimeout(() => {
            window.location.replace("/admin-console-login");
            localStorage.clear();
          }, 1000);
        }
        if (result.response.status === 200) {
          return (
            setOpenSnackbar(true),
            setNotificationData({
              message: customMessages.jobPostSuccess,
              severity: customMessages.severitySuccess,
            }),
            setTimeout(() => {
              handleCloseButton();
              getJobList(1);
            }, 1000)
          );
        } else if (result.response.status === 412) {
          return (
            setOpenSnackbar(true),
            setNotificationData({
              message: customMessages.commonError,
              severity: customMessages.severityError,
            })
          );
        }
      })
      .catch(() => {
        setOpenSnackbar(true);
        setNotificationData({
          message: customMessages.commonError,
          severity: customMessages.severityError,
        });
      });
  };
  const handleClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      {/* Custom notification snackbar */}
      <CustomSnackbar
        openSnackbar={openSnackbar}
        message={notificationData?.message}
        handleClose={handleClose}
        severity={notificationData?.severity}
      />
      <Grid
        style={{ minWidth: 400 }}
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid xs={12}>
          <span>
            Are you sure want to post -
            <span style={{ color: "#36ab9b" }}>{jobDetails?.jobTitle}</span>
          </span>
        </Grid>
      </Grid>
      <hr />
      <br />
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
        spacing={2}
      >
        <Grid xs={6} sm={3}>
          <CustomButton
            color="secondary"
            onClick={handleCloseButton}
            name="Cancel"
          />
        </Grid>
        <Grid xs={6} sm={3}>
          <CustomButton name="Post" color="primary" onClick={postJob} />
        </Grid>
      </Grid>
    </>
  );
};
export default PostJob;
