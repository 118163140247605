import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import "../../../../assets/styles/css/AdminDashboard.css";
import prililegeImg from "../../../../assets/images/Dashboard/privilage-icon.svg";
import newRequestImg from "../../../../assets/images/Dashboard/total-Req-icon.svg";

import CustomButton from "../../../../components/customComponents/common/CustomButton";
import { useEffect, useState } from "react";
import API_CONFIG_VAR from "../../../../config/API_URL_CONFIG.json";
import { getAPIHandler } from "../../../../apiHandler/RestAPIHandler";
import SuperAdminHeader from "../../../../layouts/superAdmin/Header/Index";
import { Link } from "react-router-dom";
import LoginVerify from "../../../../components/utils/LoginVerify";
import CustomSnackbar from "../../../../components/customComponents/common/CustomSnackbar";
const SuperAdminDashboard = () => {
  const [roleList, setRoleList] = useState([]);
  const [dasboardCounts, setDasboardCounts] = useState({
    rolesCount: null,
    userCount: null,
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [notificationData, setNotificationData] = useState({
    message: "",
    severity: "",
  });
  const getDashboardDetails = () => {
    const URL = API_CONFIG_VAR.SUPER_ADMIN_DASHBOARD;
    getAPIHandler(URL).then((result) => {
      if (result?.response?.status === 200) {
        setRoleList(result?.response?.data?.data.roleList);
        setDasboardCounts({
          ...dasboardCounts,
          rolesCount: result?.response?.data?.data?.rolesCount,
          userCount: result?.response?.data?.data?.userCount,
        });
      } else if (result.response.status === 401) {
        setOpenSnackbar(true);
        setNotificationData({
          message: "Session Expired, Please login",
          severity: "error",
        });
        setTimeout(() => {
          window.location.replace("/admin-console-login");
          localStorage.clear();
        }, 1000);
      } else {
        setRoleList([]);
      }
    });
  };
  useEffect(() => {
    LoginVerify();
    getDashboardDetails();
    // eslint-disable-next-line
  }, []);
  const handleClose = () => {
    setOpenSnackbar(false);
  };
  return (
    <>
      <SuperAdminHeader />
      <CustomSnackbar
        openSnackbar={openSnackbar}
        message={notificationData?.message}
        handleClose={handleClose}
        severity={notificationData?.severity}
      />
      <div className="bx">
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
              <div className="dashboard-card-container">
                <div className="dashboard-card">
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={4}
                  >
                    <Grid item xs={6} sm={2}>
                      <img src={newRequestImg} style={{ width: 60 }} alt="" />
                    </Grid>
                    <Grid item xs={6} sm={8}>
                      <h4 className="dashboard-card-head">Requests</h4>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={10}
                      style={{ marginTop: -39, paddingBottom: 10 }}
                    >
                      <h2 className="super-admin-card-head">
                        {dasboardCounts?.userCount}
                      </h2>
                      <Link to="/requests" style={{ textDecoration: "none" }}>
                        <CustomButton name="View More" size="small" />
                      </Link>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
            <Grid item xs={6} md={4}>
              <div className="dashboard-card-container">
                <div className="dashboard-card">
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={4}
                  >
                    <Grid item xs={6} sm={2}>
                      <img src={prililegeImg} style={{ width: 60 }} alt="" />
                    </Grid>
                    <Grid item xs={6} sm={7}>
                      <h4 className="dashboard-card-head">Roles</h4>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={10}
                      style={{ marginTop: -39, paddingBottom: 10 }}
                    >
                      <h2 className="super-admin-card-head">
                        {dasboardCounts?.rolesCount}
                      </h2>
                      <Link to="/roles" style={{ textDecoration: "none" }}>
                        <CustomButton name="View More" size="small" />
                      </Link>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
            <Grid item xs={6} md={4}></Grid>
          </Grid>
        </Container>
      </div>

      <Container style={{ marginTop: 150 }}>
        <div className="rectangle">
          <div>
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="center"
            >
              <table>
                <thead>
                  <tr className="table100-head">
                    <th scope="col" className="column">
                      Role
                    </th>
                    <th scope="col" className="column">
                      Description
                    </th>
                    <th scope="col" className="column"></th>
                    <th scope="col" className="column"></th>
                  </tr>
                </thead>
                {roleList.map((item, i) => {
                  return (
                    <tbody key={i}>
                      <tr>
                        <td className="column">{item.roleName}</td>
                        <td className="column">{item.roleDescription}</td>
                        <td className="column"></td>
                        <td className="column"></td>
                      </tr>
                    </tbody>
                  );
                })}
              </table>
            </Grid>
          </div>
        </div>
      </Container>
    </>
  );
};
export default SuperAdminDashboard;
