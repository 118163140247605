import React, { useState } from "react";
import "../../../assets/styles/css/LoginPage.css";
import CustomButton from "../../../components/customComponents/common/CustomButton";
import CustomTextField from "../../../components/customComponents/common/CustomTextField";
import API_CONFIG_VAR from "../../../config/API_URL_CONFIG.json";
import { useNavigate } from "react-router-dom";
import { postNoAuthAPIHandler } from "../../../apiHandler/RestAPIHandler";
import { Grid, CssBaseline } from "@mui/material";
import CustomSnackbar from "../../../components/customComponents/common/CustomSnackbar";
import { customMessages } from "../../../CustomDatas";
const OtpVerification = ({ id }) => {
  let navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [otp, setOtp] = useState("");
  const [notificationData, setNotificationData] = useState({
    message: "",
    severity: "",
  });

  const otpVarifyTrigger = (event) => {
    event.preventDefault();
    let data = { _id: id, otp: otp };
    postNoAuthAPIHandler(API_CONFIG_VAR.FORGOT_OTP_VERIFY, data).then(
      (result) => {
        if (result?.response?.status === 200) {
          setOpenSnackbar(true);
          setNotificationData({
            message: customMessages.otpSuccess,
            severity: customMessages.severitySuccess,
          });
          setTimeout(() => {
            navigate("/change-password", { state: id });
          }, 2000);
        } else if (result?.response?.status === 412) {
          return (
            setOpenSnackbar(true),
            setNotificationData({
              message: result?.response?.data?.message,
              severity: customMessages.severityError,
            })
          );
        } else {
          return (
            setOpenSnackbar(true),
            setNotificationData({
              message: customMessages.commonError,
              severity: customMessages.severityError,
            })
          );
        }
      }
    );
  };
  const textChange = (event) => {
    setOtp(event.target.value);
  };

  const handleClose = () => {
    setOpenSnackbar(false);
  };
  return (
    <>
      {/* Custom notification snackbar */}
      <CustomSnackbar
        openSnackbar={openSnackbar}
        message={notificationData?.message}
        handleClose={handleClose}
        severity={notificationData?.severity}
      />

      <CssBaseline />
      <Grid container justifyContent="center" alignItems="center">
        <form style={{ minWidth: 400 }}>
          <Grid item xs={12}>
            <CustomTextField
              name="otp"
              label="OTP  "
              variant="outlined"
              size="small"
              margin="normal"
              fullWidth
              autoFocus
              onChange={textChange}
            />

            <CustomButton
              name="Verify"
              size="small"
              margin="normal"
              fullWidth
              onClick={otpVarifyTrigger}
            />
          </Grid>
          <hr />
        </form>
      </Grid>
    </>
  );
};
export default OtpVerification;
