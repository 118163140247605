import "../../assets/styles/css/IndexPage.css";
import logo from "../../assets/images/indexPage/logo.png";
import React, { useState } from "react";

import "../../assets/styles/css/Navbar.css";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ReadMoreIcon from "@mui/icons-material/ReadMore";

const Navbar = ({ color }) => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <>
      <div className="container" style={{ paddingTop: 10 }}>
        <div className="header">
          <div className="logo-nav">
            <div className="logo-container">
              <span>
                <a href="https://saasvaap.com/">
                  <img src={logo} height="36" alt=".." />
                </a>
              </span>
            </div>
          </div>
          <ul className={click ? "nav-options active" : "nav-options"}>
            <li className="option" onClick={closeMobileMenu}>
              <a style={{ color: color }} className="nav-link active" href="/">
                Home
              </a>
            </li>
            <li className="option" onClick={closeMobileMenu}>
              <a
                style={{ color: color }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://saasvaap.com/contact-us/"
                className="nav-link active"
              >
                Contact Us
              </a>
            </li>
          </ul>
          <div className="mobile-view" onClick={handleClick}>
            {click ? (
              <Button fontSize="large" className="menu-icon">
                <CloseIcon color="black" />
              </Button>
            ) : (
              <Button fontSize="large" className="menu-icon">
                <ReadMoreIcon color="black" />
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Navbar;
