import React, { useEffect, useState } from "react";
import CustomSnackbar from "../../../../components/customComponents/common/CustomSnackbar";
import CustomTextField from "../../../../components/customComponents/common/CustomTextField";
import {
  Autocomplete,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import {
  Qualification,
  Skills,
  locationData,
  noticePeriodData,
  customMessages,
} from "../../../../CustomDatas";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CustomButton from "../../../../components/customComponents/common/CustomButton";
import { postAPIHandler } from "../../../../apiHandler/RestAPIHandler";
import API_URL_CONFIG from "../../../../config/API_URL_CONFIG.json";
import validation from "./Validation";
const UploadResumes = ({ dialogType, handleCloseButton }) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errors, setErrors] = useState({});

  let initialStates = {
    jobId: "",
    jobTitle: "",
    fullName: "",
    email: "",
    qualification: "",
    keySkills: [],
    phone: "",
    expectedCTC: "",
    currentCTC: "",
    location: null,
    experienceInYear: null,
    experienceInMonth: null,
    noticeperiod: null,
    timeshift: "any",
    ctcNegotiable: "true",
    jobType: "",
    resume: "",
    resumeName: "",
    position: "",
  };
  const [validationTrigger, setValidationTrigger] = useState(false);

  const [applicantDetails, setApplicantDetails] = useState(initialStates);

  const [notificationData, setNotificationData] = useState({
    message: "",
    severity: "",
  });
  const handleClose = () => {
    setOpenSnackbar(false);
  };

  const pdfUploadChange = (event) => {
    let file = event.target.files[0];
    toBase64(file);
  };
  const toBase64 = (files) => {
    let reader = new FileReader();
    reader.readAsDataURL(files);
    reader.onload = function () {
      let data = reader?.result;
      setApplicantDetails({
        ...applicantDetails,
        resume: data,
        resumeName: files.name,
      });
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const uploadCandidateDetails = () => {
    setValidationTrigger(true);
    const postData = {
      fullName: applicantDetails.fullName,
      email: applicantDetails.email,
      qualification: applicantDetails.qualification,
      phone: applicantDetails.phone,
      expectedCTC: applicantDetails.expectedCTC,
      currentCTC: applicantDetails.currentCTC,
      location: applicantDetails.location,
      experienceInYear: applicantDetails.experienceInYear,
      experienceInMonth: applicantDetails.experienceInMonth,
      affordableTime: applicantDetails.timeshift,
      resume: applicantDetails.resume,
      noticeperiod: applicantDetails.noticeperiod,
      negotiable: applicantDetails.ctcNegotiable,
      jobType: applicantDetails.jobType,
      jobId: "open",
      jobTitle: applicantDetails.jobTitle,
      keySkills: applicantDetails.keySkills,
      position: applicantDetails.position,
    };
    const URL = API_URL_CONFIG.APPLY_JOB;
    let validationError = validation(applicantDetails);
    if (!validationError.error) {
      postAPIHandler(URL, postData)
        .then((result) => {
          if (result.response.status === 200) {
            return (
              setOpenSnackbar(true),
              setNotificationData({
                message: customMessages.jobApplySuccess,
                severity: customMessages.severitySuccess,
              }),
              setTimeout(() => {
                setApplicantDetails(initialStates);
                setValidationTrigger(false);

                handleCloseButton();
              }, 1000)
            );
          } else if (result.response.status === 401) {
            setOpenSnackbar(true);
            setNotificationData({
              message: "Session Expired, Please login",
              severity: "error",
            });
            setTimeout(() => {
              window.location.replace("/admin-console-login");
              localStorage.clear();
            }, 1000);
          } else if (result.response.status === 412) {
            return (
              setOpenSnackbar(true),
              setNotificationData({
                message: customMessages.commonError,
                severity: customMessages.severityError,
              })
            );
          }
        })
        .catch(() => {
          setOpenSnackbar(true);
          setNotificationData({
            message: customMessages.commonError,
            severity: customMessages.severityError,
          });
        });
    } else {
      return (
        setOpenSnackbar(true),
        setNotificationData({
          message: customMessages.formFillError,
          severity: customMessages.severityError,
        })
      );
    }
  };
  useEffect(() => {
    if (validationTrigger) setErrors(validation(applicantDetails));
  }, [applicantDetails, validationTrigger]);
  const textChange = (event) => {
    const { name, value } = event.target;
    setApplicantDetails({
      ...applicantDetails,
      [name]: value,
    });
  };
  const onChangeQualification = (event, value) => {
    setApplicantDetails({
      ...applicantDetails,
      qualification: value,
    });
  };
  const onCountryChange = (event, value) => {
    setApplicantDetails({
      ...applicantDetails,
      location: value,
    });
  };
  const noticePeriodChange = (event, value) => {
    setApplicantDetails({
      ...applicantDetails,
      noticeperiod: value,
    });
  };
  const timeShiftChange = (event) => {
    setApplicantDetails({
      ...applicantDetails,
      timeshift: event.target.value,
    });
  };
  const onCtcNegotiableChange = (event) => {
    setApplicantDetails({
      ...applicantDetails,
      ctcNegotiable: event.target.value,
    });
  };
  const onJobtypeChange = (event) => {
    setApplicantDetails({
      ...applicantDetails,
      jobType: event.target.value,
    });
  };

  const onChangeKeySkills = (event, value) => {
    setApplicantDetails({
      ...applicantDetails,
      keySkills: value,
    });
  };

  return (
    <>
      <CustomSnackbar
        openSnackbar={openSnackbar}
        message={notificationData?.message}
        handleClose={handleClose}
        severity={notificationData?.severity}
      />
      <div className="container">
        <form className="g-3">
          <div className="row">
            <div className="col-md-12 row">
              <div className="col-md-6">
                <label htmlFor="inputText" className="form-label">
                  Job Title <span className="label-required">*</span>
                </label>
                <CustomTextField
                  name="jobTitle"
                  label="Job Title "
                  variant="outlined"
                  margin="normal"
                  size="small"
                  autoFocus
                  fullWidth
                  value={applicantDetails?.jobTitle}
                  onChange={textChange}
                />
                {errors.jobTitle && (
                  <span className="error-span">{errors.jobTitle}</span>
                )}
              </div>
              <div className="col-md-6">
                <label htmlFor="inputText" className="form-label">
                  Full Name <span className="label-required">*</span>
                </label>
                <CustomTextField
                  name="fullName"
                  label="Full Name "
                  variant="outlined"
                  margin="normal"
                  size="small"
                  autoFocus
                  fullWidth
                  value={applicantDetails?.fullName}
                  onChange={textChange}
                />
                {errors.fullName && (
                  <span className="error-span">{errors.fullName}</span>
                )}
              </div>
              <div className="col-md-6">
                <label htmlFor="inputEmail4" className="form-label">
                  Email <span className="label-required">*</span>
                </label>
                <CustomTextField
                  name="email"
                  label="Email Address"
                  variant="outlined"
                  margin="normal"
                  size="small"
                  fullWidth
                  type="email"
                  onChange={textChange}
                  value={applicantDetails.email}
                />
                {errors.email && (
                  <span className="error-span">{errors.email}</span>
                )}
              </div>
              <div className="col-md-6">
                <label htmlFor="inputText" className="form-label">
                  Position / Role
                </label>
                <CustomTextField
                  name="position"
                  label="Position / Role "
                  variant="outlined"
                  margin="normal"
                  size="small"
                  fullWidth
                  value={applicantDetails.position}
                  onChange={textChange}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="inputText" className="form-label">
                  Qualification <span className="label-required">*</span>
                </label>
                <Autocomplete
                  id="multiple-limit-tags"
                  className="form-label-height"
                  options={Qualification}
                  size="small"
                  margin="normal"
                  onChange={onChangeQualification}
                  getOptionLabel={(option) => option}
                  value={applicantDetails?.qualification}
                  renderInput={(params) => (
                    <TextField {...params} label="Qualification" />
                  )}
                />
                {errors.qualification && (
                  <span className="error-span">{errors.qualification}</span>
                )}
              </div>
              <div className="col-md-6">
                <label htmlFor="inputText" className="form-label">
                  Skills <span className="label-required">*</span>
                </label>
                <Autocomplete
                  multiple
                  id="multiple-limit-tags"
                  className="form-label-height"
                  options={Skills}
                  size="small"
                  margin="normal"
                  onChange={onChangeKeySkills}
                  disabled={dialogType === "AppliedCandidates"}
                  value={applicantDetails?.keySkills}
                  //value={applicantDetails?.keySkills}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField {...params} label="Skills" />
                  )}
                />
                {errors.keySkills && (
                  <span className="error-span">{errors.keySkills}</span>
                )}
              </div>
              <div className="col-md-6">
                <label htmlFor="inputState" className="form-label">
                  Preferred Location <span className="label-required">*</span>
                </label>
                <Autocomplete
                  className="form-label-height"
                  disablePortal
                  id="combo-box-demo"
                  options={locationData}
                  size="small"
                  value={applicantDetails.location}
                  onChange={onCountryChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Select location" />
                  )}
                />
                {errors.location && (
                  <span className="error-span">{errors.location}</span>
                )}
              </div>
              <div className="col-md-6">
                <label htmlFor="inputNumber" className="form-label">
                  Mobile Number <span className="label-required">*</span>
                </label>
                <CustomTextField
                  name="phone"
                  label="Enter Mobile Number"
                  variant="outlined"
                  margin="normal"
                  size="small"
                  fullWidth
                  onChange={textChange}
                  value={applicantDetails.phone}
                />
                {errors.phone && (
                  <span className="error-span">{errors.phone}</span>
                )}
              </div>

              <div className="col-md-6">
                <label htmlFor="inputNumber" className="form-label">
                  Total Experience <span className="label-required">*</span>
                </label>
                <div className="row">
                  <div className="col-md-6">
                    <CustomTextField
                      name="experienceInYear"
                      label=" Experience In Year"
                      variant="outlined"
                      size="small"
                      fullWidth
                      margin="normal"
                      InputProps={{ inputProps: { min: 0 } }}
                      type="Number"
                      onChange={textChange}
                      // value={applicantDetails?.experienceInYear}
                    />
                    {errors.experienceInYear && (
                      <span className="error-span">
                        {errors.experienceInYear}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <CustomTextField
                      name="experienceInMonth"
                      label="  Experience In Month"
                      variant="outlined"
                      size="small"
                      fullWidth
                      margin="normal"
                      InputProps={{ inputProps: { min: 0 } }}
                      type="Number"
                      onChange={textChange}
                      // value={applicantDetails?.experienceInMonth}
                    />
                    {errors.experienceInMonth && (
                      <span className="error-span">
                        {errors.experienceInMonth}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="inputState" className="form-label">
                  Notice period <span className="label-required">*</span>
                </label>
                <Autocomplete
                  className="form-label-height"
                  disablePortal
                  id="combo-box-demo"
                  options={noticePeriodData}
                  size="small"
                  onChange={noticePeriodChange}
                  value={applicantDetails?.noticeperiod}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Notice Period" />
                  )}
                />
                {errors.noticeperiod && (
                  <span className="error-span">{errors.noticeperiod}</span>
                )}
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="inputText" className="form-label">
                      Is Expected CTC negotiable?
                    </label>
                    <RadioGroup
                      style={{ marginTop: 10 }}
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={onCtcNegotiableChange}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="inputText" className="form-label">
                      Preferred Job Type
                    </label>
                    <RadioGroup
                      style={{ marginTop: 10 }}
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={onJobtypeChange}
                    >
                      <FormControlLabel
                        value="full_time"
                        control={<Radio />}
                        label="Full Time"
                      />
                      <FormControlLabel
                        value="part_time"
                        control={<Radio />}
                        label="Part Time"
                      />
                    </RadioGroup>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="inputState" className="form-label">
                  Time Shift
                </label>
                <RadioGroup
                  style={{ marginTop: 10 }}
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={timeShiftChange}
                >
                  <FormControlLabel
                    value="day"
                    control={<Radio />}
                    label="Day"
                  />
                  <FormControlLabel
                    value="night"
                    control={<Radio />}
                    label="Night"
                  />
                  <FormControlLabel
                    value="any"
                    control={<Radio />}
                    label="Any"
                  />
                </RadioGroup>
              </div>

              <div className="col-md-6">
                <label htmlFor="inputText" className="form-label">
                  Current CTC
                </label>

                <CustomTextField
                  name="currentCTC"
                  label="Enter Current CTC in Lakhs Per Annum (INR)"
                  variant="outlined"
                  margin="normal"
                  size="small"
                  fullWidth
                  type="number"
                  onChange={textChange}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="inputText" className="form-label">
                  Expected CTC
                </label>
                <CustomTextField
                  name="expectedCTC"
                  label="Enter Expected CTC in Lakhs Per Annum (INR)"
                  variant="outlined"
                  margin="normal"
                  size="small"
                  fullWidth
                  type="number"
                  onChange={textChange}
                />
              </div>
              <div className="col-md-12">
                <br />
                <div>
                  <Button
                    style={{
                      minWidth: 100,
                      minHeight: 30,
                      textTransform: "none",
                      color: "white",
                    }}
                    startIcon={<UploadFileIcon />}
                    onChange={pdfUploadChange}
                    variant="contained"
                    component="label"
                    size="small"
                  >
                    Upload Resume
                    <input
                      hidden
                      accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword"
                      type="file"
                    />
                  </Button>
                  <span className="label-required">*</span>
                  {errors.resume && (
                    <span className="error-span">{errors.resume}</span>
                  )}
                  <span style={{ fontSize: 12 }}>
                    {applicantDetails?.resumeName?.length
                      ? applicantDetails.resumeName
                      : ""}
                  </span>
                </div>
              </div>
              <div
                className="col-md-12"
                style={{ marginTop: 20, marginRight: 1 }}
              >
                <div className="row">
                  <div className="col-md-4">
                    <CustomButton
                      size="small"
                      onClick={uploadCandidateDetails}
                      name="Submit"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default UploadResumes;
