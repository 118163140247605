const validation = (values) => {
  let errors = { error: false };
  if (!values.roleName) {
    errors.roleName = "Please enter Role Name";
    errors.error = true;
  }
  if (!values.roleDescription) {
    errors.roleDescription = "Please enter  Role Description";
    errors.error = true;
  }

  return errors;
};

export default validation;
