import React, { useEffect, useState } from "react";
import "../../../assets/styles/css/LoginPage.css";
import CustomButton from "../../../components/customComponents/common/CustomButton";
import CustomTextField from "../../../components/customComponents/common/CustomTextField";
import { InputAdornment } from "@mui/material";
import API_CONFIG_VAR from "../../../config/API_URL_CONFIG.json";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { loginAPIHandler } from "../../../apiHandler/RestAPIHandler";
import { Grid, Container, CssBaseline } from "@mui/material";
import validation from "./validation";
import CustomSnackbar from "../../../components/customComponents/common/CustomSnackbar";
import { customMessages } from "../../../CustomDatas";
const LoginPage = () => {
  let navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [visibility, setVisiblity] = useState(false);
  const [validationTrigger, setValidationTrigger] = useState(false);
  const [loginCredential, setLoginCredential] = useState({
    email: "",
    password: "",
  });
  const [notificationData, setNotificationData] = useState({
    message: "",
    severity: "",
  });
  const [errors, setErrors] = useState({});
  const endAdornment = (
    <InputAdornment position="end">
      {visibility ? (
        <Visibility onClick={() => setVisiblity(!visibility)} />
      ) : (
        <VisibilityOff onClick={() => setVisiblity(!visibility)} />
      )}
    </InputAdornment>
  );

  const loginTrigger = (event) => {
    event.preventDefault();
    setValidationTrigger(true);
    const URL = API_CONFIG_VAR.LOGIN;
    loginAPIHandler(loginCredential, URL).then((result) => {
      if (result?.response?.status === 200) {
        if (result?.response?.data?.data?.isPasswordResetRequired) {
          setOpenSnackbar(true);
          setNotificationData({
            message: customMessages.createPasswordInfo,
            severity: customMessages.severityInfo,
          });
          setTimeout(() => {
            navigate("/change-password", {
              state: result?.response?.data?.data?.id,
            });
          }, 2000);
        } else {
          if (result?.response?.data?.data?.isAdmin) {
            navigate("/super-admin-dashboard");
          } else {
            if (result?.response?.data?.data?.role?.length === 0) {
              setOpenSnackbar(true);
              setNotificationData({
                message: customMessages.userRoleEmptyError,
                severity: customMessages.severityError,
              });
            } else {
              navigate("/admin-dashboard", {
                state: { isToken: true },
              });
            }
          }
        }
      } else if (result?.response?.status === 412) {
        return (
          setOpenSnackbar(true),
          setNotificationData({
            message: result?.response?.data?.message,
            severity: customMessages.severityError,
          })
        );
      } else {
        return (
          setOpenSnackbar(true),
          setNotificationData({
            message: customMessages.commonError,
            severity: customMessages.severityError,
          })
        );
      }
    });
  };
  const textChange = (event) => {
    setLoginCredential({
      ...loginCredential,
      [event.target.name]: event.target.value,
    });
  };
  useEffect(() => {
    if (validationTrigger) setErrors(validation(loginCredential));
  }, [loginCredential, validationTrigger]);

  const handleClose = () => {
    setOpenSnackbar(false);
  };
  return (
    <>
      {/* Custom notification snackbar */}
      <CustomSnackbar
        openSnackbar={openSnackbar}
        message={notificationData?.message}
        handleClose={handleClose}
        severity={notificationData?.severity}
      />
      <div className="register-container">
        <Container maxWidth="xs">
          <CssBaseline />
          <Grid
            className="grid-container"
            container
            justifyContent="center"
            alignItems="center"
          >
            <form className="form-container">
              <Grid item xs={12}>
                <h1 className="registration-header">Login</h1>
                <CustomTextField
                  name="email"
                  label=" Email "
                  variant="outlined"
                  size="small"
                  margin="normal"
                  fullWidth
                  autoFocus
                  value={loginCredential.email}
                  onChange={textChange}
                />
                {errors.email && (
                  <span className="login-error-span ">{errors.email}</span>
                )}
                <Grid item xs={12}>
                  <CustomTextField
                    name="password"
                    label=" Password "
                    variant="outlined"
                    size="small"
                    margin="normal"
                    fullWidth
                    value={loginCredential.password}
                    onChange={textChange}
                    type={visibility ? "text" : "password"}
                    InputProps={{
                      endAdornment: endAdornment,
                    }}
                  />
                  {errors.password && (
                    <span className="login-error-span ">{errors.password}</span>
                  )}
                </Grid>

                <CustomButton
                  name="Login"
                  size="small"
                  margin="normal"
                  fullWidth
                  onClick={loginTrigger}
                />
              </Grid>
              <hr />
              <div className="footer-nav-link">
                <div>
                  <Link to="/register"> Register</Link>
                </div>
                <span>
                  <Link to="/forgot-password"> Forgot Password?</Link>
                </span>
              </div>
            </form>
          </Grid>
        </Container>
      </div>
    </>
  );
};
export default LoginPage;
