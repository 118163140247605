import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const StyledMenu = styled((props) => (
  <Menu
    elevation={2}
    anchorOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(3),
    minWidth: 100,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.primary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
export const RowActionMenu = ({
  item,
  OpenDialog,
  userPrivileges,
  isActiveTable,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleMoreIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMoreIcon = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <MoreVertIcon color="primary" onClick={handleMoreIconClick} />
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMoreIcon}
      >
        {isActiveTable ? (
          userPrivileges.includes("JOBS_ACTIVE_TABLE_EDIT_BUTTON") ? (
            <MenuItem onClick={handleCloseMoreIcon} disableRipple>
              <span onClick={() => OpenDialog("EditJob", item)}>
                <EditIcon color="primary" fontSize="small" />
                Edit
              </span>
            </MenuItem>
          ) : (
            <></>
          )
        ) : (
          <MenuItem onClick={handleCloseMoreIcon} disableRipple>
            <span onClick={() => OpenDialog("EditJob", item)}>
              <EditIcon color="primary" fontSize="small" />
              Edit
            </span>
          </MenuItem>
        )}

        {isActiveTable ? (
          userPrivileges.includes("JOBS_ACTIVE_TABLE_DELETE_BUTTON") ? (
            <MenuItem onClick={handleCloseMoreIcon} disableRipple>
              <span onClick={() => OpenDialog("DeleteJob", item)}>
                <DeleteIcon sx={{ color: "#f05545" }} fontSize="small" />
                Delete
              </span>
            </MenuItem>
          ) : (
            <></>
          )
        ) : (
          <MenuItem onClick={handleCloseMoreIcon} disableRipple>
            <span onClick={() => OpenDialog("DeleteJob", item)}>
              <DeleteIcon sx={{ color: "#f05545" }} fontSize="small" />
              Delete
            </span>
          </MenuItem>
        )}
      </StyledMenu>
    </div>
  );
};
