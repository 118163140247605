const validation = (values) => {
  var regexForPassword =
    /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  let errors = { error: false };
  if (!values.newPassword) {
    errors.newPassword = "Please Enter new password";
    errors.error = true;
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = " Please enter confirm password.";
    errors.error = true;
  } else if (values.confirmPassword !== values.newPassword) {
    errors.confirmPassword = "New and Confirm password should be match.";
    errors.error = true;
  }
  if (!regexForPassword.test(values.newPassword)) {
    errors.password =
      " Password should contain minimum 8 letters, with at least a symbol, upper and lower case letters and a number.";
  }

  return errors;
};

export default validation;
