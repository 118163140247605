import lifeAtsaasvaapImage from "../../../../assets/images/indexPage/life@saasvaap.png";

const LifeAtSaasvaap = () => {
  return (
    <>
      <div className="life-saasvaap">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h4>Life at Saasvaap</h4>
              <p>
                Saasvaap is a highly competent software service provider that
                builds uniquely tailored and innovative digital solutions and
                products that meet all your business requirements.  We work
                passionately with advanced technologies and experts in the field
                to devise the most effective, ideal and compact solutions and
                products for our clients, as we strive to get you closer to your
                bigger goals in business.
              </p>
            </div>
            <div className="col-md-6">
              <img
                src={lifeAtsaasvaapImage}
                className="img-fluid"
                alt="Responsive"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LifeAtSaasvaap;
