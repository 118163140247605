import React, { useState, useEffect } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Divider } from "@mui/material";
import { Link } from "react-router-dom";
import { postAPIHandler } from "../../../../apiHandler/RestAPIHandler";
import API_URL_CONFIG from "../../../../config/API_URL_CONFIG.json";
import CustomSnackbar from "../../../../components/customComponents/common/CustomSnackbar";
import ListEmptyState from "../../../../components/customComponents/common/CustomEmptyState";

import CustomDialog from "../../../../components/customComponents/common/CustomDialog";
import ApplicantDetails from "../../../../view/admin/JobsPage/AppliedCandidates/ApplicantDetails";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const NotificationView = (getNotificationCount) => {
  const [notificationData, setNotificationData] = useState({
    message: "",
    severity: "",
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const PAGINATION_ROWS_PER_PAGE = 10000;

  const [dialogType, setDialogType] = useState();

  const [appliedCandidateDetails, setAppliedCandidateDetails] = useState();

  useEffect(() => {
    setAppliedCandidateDetails(null);
    getNotificationList();
  }, []);
  const buildAPIRequestBody = (isforList, pageNumber) => {
    let requestBodyObject = {
      filterCategory: "notifications",
      filterTemplate: "NOTIFICATION_DATA_FILTER_TEMPLATE_V1.json",
    };
    if (isforList) {
      requestBodyObject["sort"] = {
        sortFieldName: "created",
        sortType: "DESC",
      };
      requestBodyObject["range"] = {
        page: pageNumber,
        limit: PAGINATION_ROWS_PER_PAGE,
      };
    }

    return requestBodyObject;
  };
  const getNotificationList = (pageNo = 1) => {
    let requestData = buildAPIRequestBody(true, pageNo);
    //setListLoader(true);
    postAPIHandler(API_URL_CONFIG.LIST_NOTIFICATIONS, requestData)
      .then((result) => {
        //setListLoader(false);
        if (result.response.status === 401) {
          setOpenSnackbar(true);
          setNotificationData({
            message: "Session Expired, Please login",
            severity: "error",
          });
          setTimeout(() => {
            window.location.replace("/admin-console-login");
            localStorage.clear();
          }, 1000);
        }
        if (result.message === "Network Error") {
          setNotificationList([]);
        } else {
          setNotificationList(result?.response?.data?.data);
        }
      })
      .catch((err) => {
        //setListLoader(false);
        setNotificationList([]);
      });
  };
  const handleClose = () => {
    setOpenSnackbar(false);
  };

  const readAllNotifications = () => {
    postAPIHandler(API_URL_CONFIG.MARK_ALL_AS_READ_NOTIFICATIONS)
      .then((result) => {
        if (result.message === "Network Error") {
          setNotificationList([]);
        } else {
          setNotificationList(result?.response?.data?.data);
          //getNotificationCount(1);
        }
      })
      .catch((err) => {
        setNotificationList([]);
      });
  };
  const OpenDialog = (e, type) => {
    setDialogType(e);
    setAppliedCandidateDetails(type);
  };

  const handleCloseButton = () => {
    setDialogType("");
  };

  return (
    <div>
      <CustomSnackbar
        openSnackbar={openSnackbar}
        message={notificationData?.message}
        handleClose={handleClose}
        severity={notificationData?.severity}
      />
      <List
        sx={{
          width: 350,
          scrollbarColor: "grey",
        }}
      >
        <h4 className="popup-card-notification">
          Notifications
          <Divider style={{ marginTop: 10 }} />
        </h4>

        <Link onClick={() => readAllNotifications()}>
          <u className="mark-allread-notification">Mark all as read</u>
        </Link>
        {notificationList ? (
          notificationList.length ? (
            notificationList.map((item, i) => {
              return (
                <List>
                  <ListItem alignItems="flex-start">
                    {item.isOpened === false ? (
                      <ListItemText
                        style={{
                          cursor: "pointer",
                          color: "black",
                          fontSize: 30,
                          padding: 10,
                        }}
                        onClick={() => OpenDialog("AppliedCandidates", item)}
                        primary={
                          "Application received for the job - " +
                          item.jobTitle +
                          "" +
                          `(${item.jobId})` +
                          " from " +
                          item.applicantName
                        }
                        className="hover-effect"
                      />
                    ) : (
                      <ListItemText
                        style={{
                          cursor: "pointer",
                          color: "grey",
                          fontSize: 30,
                          padding: 10,
                        }}
                        onClick={() => OpenDialog("AppliedCandidates", item)}
                        primary={
                          "Application received for the job - " +
                          item.jobTitle +
                          "" +
                          `(${item.jobId})` +
                          " from " +
                          item.applicantName
                        }
                        className="hover-effect"
                      />
                    )}
                    {item.isOpened === false ? (
                      <FiberManualRecordIcon
                        style={{
                          cursor: "pointer",
                          color: "green",
                          fontSize: 15,
                          marginTop: 30,
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </ListItem>
                </List>
              );
            })
          ) : (
            <div className="empty-notification">
              <ListEmptyState emptyState="No New Notifications" />
            </div>
          )
        ) : (
          <div className="empty-notification">
            <ListEmptyState emptyState="No New Notifications" />
          </div>
        )}
      </List>

      <CustomDialog
        openDialog={dialogType === "AppliedCandidates"}
        dialogTitle="Candidate Details"
        handleCloseButton={handleCloseButton}
        component={
          <ApplicantDetails
            appliedCandidateDetails={appliedCandidateDetails}
            getNotificationCount={getNotificationCount}
          />
        }
      ></CustomDialog>
    </div>
  );
};
export default NotificationView;
