import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../../assets/styles/css/RegisterPage.css";
import API_CONFIG_VAR from "../../../config/API_URL_CONFIG.json";
import validation from "./validation";
import CustomButton from "../../../components/customComponents/common/CustomButton";
import CustomTextField from "../../../components/customComponents/common/CustomTextField";
import CustomSnackbar from "../../../components/customComponents/common/CustomSnackbar";
import { postNoAuthAPIHandler } from "../../../apiHandler/RestAPIHandler";
import { customMessages } from "../../../CustomDatas";
import { Grid, Container, CssBaseline } from "@mui/material";
import { useNavigate } from "react-router-dom";
const RegisterPage = () => {
  let navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [validationTrigger, setValidationTrigger] = useState(false);
  const [registerCredential, setRegisterCredential] = useState({
    userName: "",
    email: "",
    contactNumber: "",
    role: [],
    userStatus: "pending",
  });
  const [notificationData, setNotificationData] = useState({
    message: "",
    severity: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (validationTrigger) setErrors(validation(registerCredential));
  }, [registerCredential, validationTrigger]);
  const registerTrigger = (event) => {
    event.preventDefault();
    setValidationTrigger(true);
    let validationError = validation(registerCredential);
    setErrors(validationError);
    if (!validationError?.error) {
      postNoAuthAPIHandler(API_CONFIG_VAR.REGISTER, registerCredential).then(
        (result) => {
          if (result?.response?.status === 200) {
            setOpenSnackbar(true);
            setNotificationData({
              message: customMessages.registrationSuccess,
              severity: customMessages.severitySuccess,
            });
            setTimeout(() => {
              navigate("/admin-console-login");
            }, 3000);
          } else if (result?.response?.status === 412) {
            return (
              setOpenSnackbar(true),
              setNotificationData({
                message: result?.response?.data?.message,
                severity: customMessages.severityError,
              })
            );
          } else {
            return (
              setOpenSnackbar(true),
              setNotificationData({
                message: customMessages.commonError,
                severity: customMessages.severityError,
              })
            );
          }
        }
      );
    } else {
      return (
        setOpenSnackbar(true),
        setNotificationData({
          message: customMessages.formFillError,
          severity: customMessages.severityError,
        })
      );
    }
  };
  const textChange = (event) => {
    setRegisterCredential({
      ...registerCredential,
      [event.target.name]: event.target.value,
    });
  };

  const handleClose = () => {
    setOpenSnackbar(false);
  };
  return (
    <>
      {/* Custom notification snackbar */}
      <CustomSnackbar
        openSnackbar={openSnackbar}
        message={notificationData?.message}
        handleClose={handleClose}
        severity={notificationData?.severity}
      />
      <div className="register-container">
        <Container maxWidth="xs">
          <CssBaseline />
          <Grid
            className="grid-container"
            container
            justifyContent="center"
            alignItems="center"
          >
            <form className="form-container">
              <Grid item xs={12}>
                <h1 className="registration-header">Create Account</h1>
                <CustomTextField
                  name="userName"
                  label=" Full Name "
                  variant="outlined"
                  size="small"
                  margin="normal"
                  fullWidth
                  value={registerCredential?.userName}
                  onChange={textChange}
                />
                {errors.userName && (
                  <span className="register-error-span ">
                    {errors.userName}
                  </span>
                )}
                <Grid item xs={12}>
                  <CustomTextField
                    name="email"
                    label="Email "
                    variant="outlined"
                    size="small"
                    margin="normal"
                    fullWidth
                    value={registerCredential?.email}
                    onChange={textChange}
                  />
                  {errors.email && (
                    <span className="register-error-span ">{errors.email}</span>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    name="contactNumber"
                    label="Mobile Number "
                    variant="outlined"
                    margin="normal"
                    size="small"
                    fullWidth
                    value={registerCredential?.contactNumber}
                    onChange={textChange}
                  />
                  {errors.contactNumber && (
                    <span className="register-error-span ">
                      {errors.contactNumber}
                    </span>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    name="designation"
                    label=" Designation "
                    variant="outlined"
                    margin="normal"
                    size="small"
                    fullWidth
                    value={registerCredential?.designation}
                    onChange={textChange}
                  />
                  {errors.designation && (
                    <span className="register-error-span ">
                      {errors.designation}
                    </span>
                  )}
                </Grid>
                <CustomButton
                  name="Register"
                  size="small"
                  fullWidth
                  onClick={registerTrigger}
                />
              </Grid>
              <hr />
              <div className="footer-nav-link">
                Already have account?
                <span>
                  <Link to="/admin-console-login">Sign In</Link>
                </span>
              </div>
            </form>
          </Grid>
        </Container>
      </div>
    </>
  );
};
export default RegisterPage;
