import React, { useEffect, useState } from "react";
import CustomSnackbar from "../../../../components/customComponents/common/CustomSnackbar";
import { Grid, Autocomplete, TextField } from "@mui/material";
import CustomButton from "../../../../components/customComponents/common/CustomButton";
import {
  getAPIHandler,
  postAPIHandler,
} from "../../../../apiHandler/RestAPIHandler";
import API_URL_CONFIG from "../../../../config/API_URL_CONFIG.json";
import { customMessages } from "../../../../CustomDatas";
import API_CONFIG_VAR from "../../../../config/API_URL_CONFIG.json";

const EditRole = ({ handleCloseButton, requestDetails, dialogType }) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [notificationData, setNotificationData] = useState({
    message: "",
    severity: "",
  });

  const [roleList, setRoleList] = useState([]);
  const [updatedRole, setUpdatedRole] = useState([]);

  const handleClose = () => {
    setOpenSnackbar(false);
  };
  useEffect(() => {
    getRequestDetails();
    getAllRoles();
  }, [dialogType]);
  const getRequestDetails = () => {
    const URL = API_URL_CONFIG.GET_REQUEST_ROLE + "?_id=" + requestDetails?._id;
    getAPIHandler(URL)
      .then((result) => {
        if (result.message === "Network Error") {
          setUpdatedRole({});
        } else {
          let roleDetails = result?.response?.data?.data
            ? result?.response?.data?.data.map((item) => {
                return item;
              })
            : [];
          setUpdatedRole(roleDetails);
        }
        if (result.response.status === 401) {
          setOpenSnackbar(true);
          setNotificationData({
            message: "Session Expired, Please login",
            severity: "error",
          });
          setTimeout(() => {
            window.location.replace("/admin-console-login");
            localStorage.clear();
          }, 1000);
        }
      })
      .catch((err) => {
        setUpdatedRole({});
      });
  };

  const onChangeRole = (event, value) => {
    setUpdatedRole([...value]);
  };
  const updateRole = () => {
    const postData = {
      role: updatedRole
        ? updatedRole.map((item) => item._id || item.roleId)
        : [],

      _id: requestDetails?._id,
    };
    postAPIHandler(API_URL_CONFIG.UPDATE_REQUEST_ROLE, postData)
      .then((result) => {
        if (result?.response?.status === 200) {
          return (
            setOpenSnackbar(true),
            setNotificationData({
              message: customMessages.roleEditedSuccess,
            }),
            setTimeout(() => {
              handleCloseButton();
            }, 1000)
          );
        } else if (result.response.status === 401) {
          setOpenSnackbar(true);
          setNotificationData({
            message: "Session Expired, Please login",
            severity: "error",
          });
          setTimeout(() => {
            window.location.replace("/admin-console-login");
            localStorage.clear();
          }, 1000);
        } else if (result?.response?.status === 412) {
          return (
            setOpenSnackbar(true),
            setNotificationData({
              message: customMessages.roleExistMessage,
              severity: customMessages.severityError,
            })
          );
        }
      })
      .catch((err) => {
        return (
          setOpenSnackbar(true),
          setNotificationData({
            message: customMessages.commonError,
            severity: customMessages.severityError,
          })
        );
      });
  };
  const getAllRoles = () => {
    const URL = API_CONFIG_VAR.GET_ALL_ROLES;

    getAPIHandler(URL).then((result) => {
      if (result?.response?.status === 200) {
        setRoleList(result?.response?.data?.data);
      } else if (result.response.status === 401) {
        setOpenSnackbar(true);
        setNotificationData({
          message: "Session Expired, Please login",
          severity: "error",
        });
        setTimeout(() => {
          window.location.replace("/admin-console-login");
          localStorage.clear();
        }, 1000);
      } else {
        setRoleList([]);
      }
    });
  };

  return (
    <>
      {/* Custom notification snackbar */}
      <CustomSnackbar
        openSnackbar={openSnackbar}
        message={notificationData?.message}
        handleClose={handleClose}
        severity={notificationData?.severity}
      />

      <Grid
        style={{ width: "400px" }}
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid xs={12}>
          <Autocomplete
            multiple
            id="multiple-limit-tags"
            options={roleList}
            value={updatedRole}
            getOptionLabel={(option) => option?.roleName}
            size="small"
            onChange={onChangeRole}
            renderInput={(params) => <TextField {...params} label="Roles" />}
          />
        </Grid>
      </Grid>
      <hr />
      <br />
      <Grid
        style={{ gridGap: "10px" }}
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
        spacing={2}
      >
        <Grid xs={6} sm={3}>
          <CustomButton
            onClick={handleCloseButton}
            color="secondary"
            name="Cancel"
          />
        </Grid>
        <Grid xs={6} sm={3}>
          <CustomButton name="Save" onClick={updateRole} />
        </Grid>
      </Grid>
    </>
  );
};
export default EditRole;
