import React, { useState } from "react";
import { Grid } from "@mui/material";
import "../../../../assets/styles/css/AddJob.css";
import { Autocomplete, TextField } from "@mui/material";
import API_URL_CONFIG from "../../../../config/API_URL_CONFIG.json";
import { postAPIHandler } from "../../../../apiHandler/RestAPIHandler";

import validation from "./Validation";
import CustomTextField from "../../../../components/customComponents/common/CustomTextField";
import CustomButton from "../../../../components/customComponents/common/CustomButton";
import CustomSnackbar from "../../../../components/customComponents/common/CustomSnackbar";
import { customMessages, Configurations } from "../../../../CustomDatas";

const CreateConfigurationEntry = ({
  handleCloseButton,
  getKeySkills,
  getLocation,
  getPosition,
  getQualification,
}) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errors, setErrors] = useState({});
  const [notificationData, setNotificationData] = useState({
    message: "",
    severity: "",
  });
  const [configurationOption, setConfigurationOption] = useState("");

  const [inputValue, setInputValue] = useState("");

  const onChangeConfigurationOptions = (event, value) => {
    setConfigurationOption(value);
  };
  const textChange = (event) => {
    setInputValue(event.target.value);
  };
  const createEntry = () => {
    PositionData(configurationOption, inputValue);
  };

  const PositionData = (configurationOption, inputValue) => {
    let validationError = validation(
      (configurationOption = configurationOption),
      (inputValue = inputValue)
    );
    setErrors(validationError);
    const postData =
      configurationOption === "Location"
        ? { location: inputValue }
        : configurationOption === "Position"
        ? { position: inputValue }
        : configurationOption === "Qualification"
        ? { qualification: inputValue }
        : configurationOption === "Key Skills"
        ? { keySkills: inputValue }
        : null;

    const URL =
      configurationOption === "Location"
        ? API_URL_CONFIG.ADD_LOCATION
        : configurationOption === "Position"
        ? API_URL_CONFIG.ADD_POSITION
        : configurationOption === "Qualification"
        ? API_URL_CONFIG.ADD_QUALIFICATION
        : configurationOption === "Key Skills"
        ? API_URL_CONFIG.ADD_KEYSKILLS
        : null;
    if (!validationError?.error) {
      postAPIHandler(URL, postData)
        .then((result) => {
          if (result.response.status === 401) {
            setOpenSnackbar(true);
            setNotificationData({
              message: "Session Expired, Please login",
              severity: "error",
            });
            setTimeout(() => {
              window.location.replace("/admin-console-login");
              localStorage.clear();
            }, 1000);
          }
          if (result.response.status === 200) {
            return (
              setOpenSnackbar(true),
              setNotificationData({
                message: customMessages.entryAdded,
                severity: customMessages.severitySuccess,
              }),
              setTimeout(() => {
                handleCloseButton();
                getKeySkills();
                getLocation();
                getPosition();
                getQualification();

                setConfigurationOption("");
                setInputValue("");
              }, 1000)
            );
          } else if (result.response.status === 412) {
            return (
              setOpenSnackbar(true),
              setNotificationData({
                message: customMessages.entryExistMessage,
                severity: customMessages.severityError,
              })
            );
          }
        })
        .catch((err) => {
          return (
            setOpenSnackbar(true),
            setNotificationData({
              message: customMessages.commonError,
              severity: customMessages.severityError,
            })
          );
        });
    } else {
      return (
        setOpenSnackbar(true),
        setNotificationData({
          message: customMessages.formFillError,
          severity: customMessages.severityError,
        })
      );
    }
  };
  const handleClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      {/* Custom notification snackbar */}
      <CustomSnackbar
        openSnackbar={openSnackbar}
        message={notificationData?.message}
        handleClose={handleClose}
        severity={notificationData?.severity}
      />
      <Grid
        style={{ width: 400 }}
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid xs={12}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={Configurations}
            onChange={onChangeConfigurationOptions}
            size="small"
            value={configurationOption}
            renderInput={(params) => (
              <TextField {...params} label="Select Field" />
            )}
          />
          {errors.configurationOption && (
            <span className="role-error-span ">
              {errors.configurationOption}
            </span>
          )}
        </Grid>
        <Grid xs={12}>
          <CustomTextField
            name="roleName"
            label="Input Value"
            variant="outlined"
            size="small"
            fullWidth
            value={inputValue}
            margin="normal"
            onChange={textChange}
          />
          {errors.inputValue && (
            <span className="role-error-span">{errors.inputValue}</span>
          )}
        </Grid>
      </Grid>
      <hr />
      <br />
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
        spacing={2}
      >
        <Grid xs={6} sm={3}>
          <CustomButton
            onClick={handleCloseButton}
            color="secondary"
            name="Cancel"
          />
        </Grid>
        <Grid xs={3} sm={3}>
          <CustomButton name="Create" onClick={createEntry} />
        </Grid>
      </Grid>
    </>
  );
};
export default CreateConfigurationEntry;
