const validation = (values) => {
  let errors = { error: false };
  if (!values.jobTitle) {
    errors.jobTitle = "Please enter Job Title";
    errors.error = true;
  }
  if (!values.jobDescription) {
    errors.jobDescription = "Please enter  Job Description";
    errors.error = true;
  }
  if (!values.maximumExperience) {
    errors.maximumExperience = "Enter Maximum Experience";
    errors.error = true;
  } else if (
    Number(values.minimumExperience) >= Number(values.maximumExperience)
  ) {
    errors.minimumMaxExperience = "Minimum Exp should be less than Maximum Exp";
    errors.error = true;
  }
  if (!values.minimumExperience && Number(values.minimumExperience !== 0)) {
    errors.minimumExperience = "Enter Minimum Experience";
    errors.error = true;
  }

  if (!values.position) {
    errors.position = "Select Position";
    errors.error = true;
  }

  if (values?.location?.length === 0) {
    errors.location = "Select Location";
    errors.error = true;
  }
  if (
    Number(values.minimumAnnualSalary) !== "" &&
    Number(values.minimumAnnualSalary)
  ) {
    if (
      Number(values.maximumAnnualSalary) <=
        Number(values.minimumAnnualSalary) &&
      values.minimumAnnualSalary !== 0 &&
      values.maximumAnnualSalary !== 0
    ) {
      errors.minimumAnnualSalary =
        "Maximum CTC should be greater  than  Minimum CTC ";
      errors.error = true;
    }
  }
  if (
    Number(values.maximumAnnualSalary) !== "" &&
    Number(values.maximumAnnualSalary)
  ) {
    if (Number(values.minimumAnnualSalary) === "") {
      errors.minimumAnnualSalary =
        "Maximum CTC should be greater  than  Minimum CTC ";
      errors.error = true;
    }
  }

  if (values?.keySkills?.length === 0) {
    errors.keySkills = "Select skill";
    errors.error = true;
  }

  return errors;
};

export default validation;
