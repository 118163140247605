import React, { useState, useEffect } from "react";
import CustomButton from "../../../../components/customComponents/common/CustomButton";
import { Link } from "react-router-dom";
import { postNoAuthAPIHandler } from "../../../../apiHandler/RestAPIHandler";
import API_URL_CONFIG from "../../../../config/API_URL_CONFIG.json";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ButtonGroup, Grid } from "@mui/material";
import { JobCardListSkeleton } from "../../../../components/Skeleton/public";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const CurrentOpenings = () => {
  const [jobList, setJobList] = useState([]);
  const [listLoader, setListLoader] = useState(false);

  useEffect(() => {
    setListLoader(true);
    const URL = API_URL_CONFIG.LIST_JOB; // eslint-disable-next-line
    const data = {
      range: {
        page: 1,
        limit: 10,
      },
      sort: {
        sortFieldName: "created",
        sortType: "DESC",
      },
      filter: [
        {
          fieldKey: "status",
          filterValue: "active",
          fieldOperator: "EQUAL",
          isLikeFilter: false,
        },
      ],
      filterCategory: "jobs",
      filterTemplate: "JOBS_FILTER_TEMPLATE_V1.json",
    };
    postNoAuthAPIHandler(URL, data)
      .then((result) => {
        setListLoader(false);
        if (result.message === "Network Error") {
          setJobList([]);
        } else {
          setJobList(result?.response?.data?.data);
        }
      })
      .catch((err) => {
        setListLoader(false);
        setJobList([]);
      });
  }, []);

  // Carosal Props

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },

      items: jobList
        ? jobList.length === 1
          ? 1
          : jobList.length === 2
          ? 2
          : jobList.length === 3
          ? 3
          : 4
        : null,

      slidesToSlide: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const sliderParams = {
    additionalTransfrom: 0,
    arrows: true,
    autoPlaySpeed: 1000,
    centerMode: false,
    slidesToSlide: 1,
    items: 4,
    containerClass: "carousel-container",
    customButtonGroup: <ButtonGroup />,
    itemClass: "carousel-item-padding-40-px",
    focusOnSelect: true,
    infinite: false,
    keyBoardControl: true,
    minimumTouchDrag: 80,
    renderButtonGroupOutside: true,
    renderDotsOutside: false,
    responsive: responsive,
    showDots: false,
    sliderClass: "",
    swipeable: true,
    draggable: false,
    dotListClass: "custom-dot-list-style",
    customTransition: "transform 500ms ease-in-out",
    transitionDuration: 500,
  };
  return (
    <>
      <div className="current-openings">
        <div className="container">
          <h4 className="mb-0">Current Openings</h4>
          <p id="are-you-looking">
            Are you looking for an exciting environment to grow as a
            professional? You are at the right place. We might have the ideal
            job you've been looking for.
          </p>
          {listLoader ? (
            <JobCardListSkeleton />
          ) : jobList ? (
            jobList.length ? (
              <Carousel
                {...sliderParams}
                removeArrowOnDeviceType={["tablet", "mobile"]}
              >
                {jobList.map((item, i) => {
                  return (
                    <Grid
                      key={i}
                      spacing={{ xs: 4, md: 6, sm: 6 }}
                      container
                      direction="row"
                      justifyContent="space-around"
                      alignItems="center"
                    >
                      <Grid item key={item?.id}>
                        <div style={{ width: 250 }} className="card">
                          <div className="card-body">
                            <>
                              <h5>{item.jobTitle}</h5>
                              <p className="card-text">
                                Exp: {item.minimumExperience}-
                                {item.maximumExperience} Yrs
                              </p>
                              <p className="card-text">
                                <LocationOnIcon
                                  color="primary"
                                  fontSize="small"
                                />

                                {item.location.length > 1
                                  ? item.location.join(", ")
                                  : item.location}
                              </p>
                              <Link
                                to={`/job-description/${item._id}`}
                                style={{ textDecoration: "none" }}
                              >
                                <CustomButton size="small" name="More Info" />
                              </Link>
                            </>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  );
                })}
              </Carousel>
            ) : (
              <div className="empty-list">
                <p>No jobs posted yet</p>
              </div>
            )
          ) : (
            <div className="empty-list">
              <p>No jobs posted yet</p>
            </div>
          )}
        </div>
      </div>

      <div className="current-opening-button">
        <Link to="/job-list" style={{ textDecoration: "none" }}>
          <CustomButton size="small" name="View More Openings" />
        </Link>
      </div>
    </>
  );
};
export default CurrentOpenings;
