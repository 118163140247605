import "../../../assets/styles/css/JobList.css";
import React, { useEffect, useState } from "react";
import Navbar from "../../../layouts/admin/Header/index";
import CustomButton from "../../../components/customComponents/common/CustomButton";
import CustomDialog from "../../../components/customComponents/common/CustomDialog";
import ChangeInterviewStatus from "./ChangeInterviewStatus/Index";
import RejecteCandidate from "./RejectCandidate/Index";
import OnboardedCandidate from "./OnboardedCandidate/Index";
import ResumeView from "../JobsPage/AppliedCandidates/ResumeView";
import ApplicantDetails from "./ApplicantDetails";
import {
  FormControl,
  InputLabel,
  Container,
  Select,
  Typography,
  MenuItem,
} from "@mui/material";

import {
  postAPIHandler,
  getAPIHandler,
} from "../../../apiHandler/RestAPIHandler";
import API_URL_CONFIG from "../../../config/API_URL_CONFIG.json";

import CustomSnackbar from "../../../components/customComponents/common/CustomSnackbar";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import {
  ShortListed,
  FirstRound,
  SecoundRound,
  Selected,
  Onboarded,
  Rejected,
} from "./InterviewTable";
import ListEmptyState from "../../../components/customComponents/common/CustomEmptyState";
import ScheduleInterview from "./ScheduleInterview";

/*Tab switch start*/
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};
/*Tab switch end */

const ApplicantInterviewList = () => {
  const PAGINATION_ROWS_PER_PAGE = 5;

  const [pageLimit, setPageLimit] = useState("");
  const [listLoader, setListLoader] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [applicantList, setApplicantList] = useState([]);
  const [applicantListCount, setApplicantListCount] = useState("");
  const [applicantId, setApplicantId] = useState("");
  const [toggle, setToggle] = useState(false);
  const [status, setStatus] = useState(0);
  const [dialogType, setDialogType] = useState("");
  const [resumeDetails, setResumeDetails] = useState();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [notificationData, setNotificationData] = useState({
    message: "",
    severity: "",
  });
  const [base64, setBase64] = useState();
  const [validationTrigger, setValidationTrigger] = useState(false);

  useEffect(() => {
    getCountAPI().then(() => {
      getListAPI(1);
    }); // eslint-disable-next-line
  }, [status, toggle]);

  useEffect(() => {
    getCountAPI().then(() => {
      getListAPI(1);
    }); // eslint-disable-next-line
  }, [pageLimit]);

  const buildAPIRequestBody = (isforList, pageNumber) => {
    let requestBodyObject = {
      filterCategory: "applicant",
      filterTemplate: "APPLICANT_FILTER_TEMPLATE_V1.json",
    };
    if (isforList) {
      requestBodyObject["sort"] = {
        sortFieldName: "created",
        sortType: "DESC",
      };
      requestBodyObject["range"] = {
        page: pageNumber,
        limit: PAGINATION_ROWS_PER_PAGE,
      };
    }
    if (pageLimit) {
      requestBodyObject["sort"] = {
        sortFieldName: "created",
        sortType: "DESC",
      };
      requestBodyObject["range"] = {
        page: pageNumber,
        limit: applicantListCount,
      };
    }

    let filterArray = [];
    if (status === 0) {
      filterArray.push({
        fieldKey: "interviewStatus",
        filterValue: ["shortlisted", "L1 scheduled"],
        fieldOperator: "IN",
        isLikeFilter: false,
      });
    }

    if (status && status === 1) {
      filterArray.push({
        fieldKey: "interviewStatus",
        filterValue: ["L1 completed", "L2 scheduled"],
        fieldOperator: "IN",
        isLikeFilter: false,
      });
    }
    if (status && status === 2) {
      filterArray.push({
        fieldKey: "interviewStatus",
        filterValue: ["L2 completed", "final round scheduled"],
        fieldOperator: "IN",
        isLikeFilter: false,
      });
    }

    if (status && status === 3) {
      filterArray.push({
        fieldKey: "interviewStatus",
        filterValue: "final round completed",
        fieldOperator: "EQUAL",
        isLikeFilter: false,
      });
    }
    if (status && status === 4) {
      filterArray.push({
        fieldKey: "interviewStatus",
        filterValue: "onboarded",
        fieldOperator: "EQUAL",
        isLikeFilter: false,
      });
    }

    if (status && status === 5) {
      filterArray.push({
        fieldKey: "interviewStatus",
        filterValue: "rejected",
        fieldOperator: "EQUAL",
        isLikeFilter: false,
      });
    }
    requestBodyObject["filter"] = filterArray;

    return requestBodyObject;
  };

  const getCountAPI = () => {
    let requestBodyObject = buildAPIRequestBody(false, null);
    return new Promise((resolve, reject) => {
      postAPIHandler(API_URL_CONFIG.APPLICANT_COUNT, requestBodyObject)
        .then((result) => {
          if (result.message === "Network Error") {
            setApplicantListCount(0);
            resolve();
          } else {
            setApplicantListCount(result?.response.data.data[0].count);

            resolve();
          }
        })
        .catch((err) => {
          setApplicantListCount(0);
          resolve();
        });
    });
  };

  const getListAPI = (pageNumber = 1) => {
    setListLoader(true);
    let requestBodyObject = buildAPIRequestBody(true, pageNumber);
    const URL = API_URL_CONFIG.LIST_JOB_APPLICANT;
    postAPIHandler(URL, requestBodyObject)
      .then((result) => {
        setListLoader(false);
        if (result.response.status === 401) {
          setOpenSnackbar(true);
          setNotificationData({
            message: "Session Expired, Please login",
            severity: "error",
          });
          setTimeout(() => {
            window.location.replace("/admin-console-login");
            localStorage.clear();
          }, 1000);
        } else if (result.message === "Network Error") {
          setApplicantList([]);
        } else {
          if (pageLimit) {
            setPageLimit("");
          } else {
            setApplicantList(result?.response?.data?.data);
          }
        }
      })
      .catch((err) => {
        setListLoader(false);
        setApplicantList([]);
      });
  };

  const paginationChange = (event, page) => {
    getListAPI(page);
    setPageNo(page);
  };

  const OpenDialog = (e, item) => {
    setDialogType(e);
    setApplicantId(item);
    setResumeDetails(item);
    viewResume(item.documentId);
  };
  const handleChangeTab = (event, newValue) => {
    setStatus(event.target.value);
    setPageNo(1);
  };
  const handleClose = () => {
    setOpenSnackbar(false);
  };
  const handleCloseButton = () => {
    setDialogType("");
    getListAPI();
    setValidationTrigger(false);
  };

  const viewResume = (documentId) => {
    getAPIHandler(API_URL_CONFIG.GET_RESUMES + "?_id=" + documentId, {
      responseType: "arraybuffer",
    }).then((result) => {
      setBase64(result.response.data.data.documentData);
    });
  };
  return (
    <>
      <Navbar />

      <div>
        <CustomSnackbar
          openSnackbar={openSnackbar}
          message={notificationData?.message}
          handleClose={handleClose}
          severity={notificationData?.severity}
        />
        {/* all job list  */}
        <Container maxWidth="xl">
          <div className="row justify-content-between">
            <div style={{ paddingLeft: 34, marginTop: 15 }} className="col-3">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={status}
                  label="Option"
                  size="small"
                  onChange={handleChangeTab}
                >
                  <MenuItem
                    {...a11yProps(0)}
                    value={0}
                    onClick={() => setToggle(false)}
                  >
                    Shorlisted Candidates
                  </MenuItem>
                  <MenuItem
                    {...a11yProps(1)}
                    value={1}
                    onClick={() => setToggle(false)}
                  >
                    L1 Completed Candidates
                  </MenuItem>
                  <MenuItem
                    {...a11yProps(2)}
                    value={2}
                    onClick={() => setToggle(false)}
                  >
                    L2 Completed Candidates
                  </MenuItem>
                  <MenuItem
                    {...a11yProps(3)}
                    value={3}
                    onClick={() => setToggle(false)}
                  >
                    Selected Candidates
                  </MenuItem>
                  <MenuItem
                    {...a11yProps(4)}
                    value={4}
                    onClick={() => setToggle(false)}
                  >
                    Onboarded Candidates
                  </MenuItem>
                  <MenuItem
                    {...a11yProps(5)}
                    value={5}
                    onClick={() => setToggle(false)}
                  >
                    Rejected Candidates
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>

          <TabPanel value={status} index={0}>
            <ShortListed
              applicantList={applicantList}
              ListEmptyState={ListEmptyState}
              listLoader={listLoader}
              OpenDialog={OpenDialog}
              CustomButton={CustomButton}
              pageNo={pageNo}
              paginationChange={paginationChange}
              applicantListCount={applicantListCount}
              toggle={toggle}
              setToggle={setToggle}
            />
          </TabPanel>
          <TabPanel value={status} index={1}>
            <FirstRound
              applicantList={applicantList}
              ListEmptyState={ListEmptyState}
              listLoader={listLoader}
              OpenDialog={OpenDialog}
              CustomButton={CustomButton}
              pageNo={pageNo}
              paginationChange={paginationChange}
              toggle={toggle}
              setToggle={setToggle}
              applicantListCount={applicantListCount}
            />
          </TabPanel>
          <TabPanel value={status} index={2}>
            <SecoundRound
              applicantList={applicantList}
              ListEmptyState={ListEmptyState}
              listLoader={listLoader}
              OpenDialog={OpenDialog}
              CustomButton={CustomButton}
              pageNo={pageNo}
              paginationChange={paginationChange}
              applicantListCount={applicantListCount}
              toggle={toggle}
              setToggle={setToggle}
            />
          </TabPanel>
          <TabPanel value={status} index={3}>
            <Selected
              applicantList={applicantList}
              ListEmptyState={ListEmptyState}
              listLoader={listLoader}
              OpenDialog={OpenDialog}
              CustomButton={CustomButton}
              pageNo={pageNo}
              paginationChange={paginationChange}
              applicantListCount={applicantListCount}
              toggle={toggle}
              setToggle={setToggle}
            />
          </TabPanel>

          <TabPanel value={status} index={4}>
            <Onboarded
              applicantList={applicantList}
              ListEmptyState={ListEmptyState}
              listLoader={listLoader}
              OpenDialog={OpenDialog}
              CustomButton={CustomButton}
              pageNo={pageNo}
              paginationChange={paginationChange}
              applicantListCount={applicantListCount}
              toggle={toggle}
              setToggle={setToggle}
            />
          </TabPanel>
          <TabPanel value={status} index={5}>
            <Rejected
              applicantList={applicantList}
              ListEmptyState={ListEmptyState}
              listLoader={listLoader}
              OpenDialog={OpenDialog}
              CustomButton={CustomButton}
              pageNo={pageNo}
              paginationChange={paginationChange}
              applicantListCount={applicantListCount}
              toggle={toggle}
              setToggle={setToggle}
            />
          </TabPanel>
        </Container>
      </div>
      {/*Resume user Dialog */}
      <CustomDialog
        openDialog={dialogType === "ReadResume"}
        dialogTitle="View Attachment"
        handleCloseButton={handleCloseButton}
        component={
          <ResumeView
            getApplicantList={resumeDetails}
            handleCloseButton={handleCloseButton}
            base64={base64}
          />
        }
      ></CustomDialog>

      <CustomDialog
        openDialog={dialogType === "AppliedCandidatesPage"}
        dialogTitle="Candidate Details"
        handleCloseButton={handleCloseButton}
        component={
          <ApplicantDetails
            dialogType={dialogType}
            handleCloseButton={handleCloseButton}
            userDetails={resumeDetails}
            getApplicantList={resumeDetails}
          />
        }
      ></CustomDialog>
      <CustomDialog
        openDialog={dialogType === "MoveTo"}
        dialogTitle="Change Status"
        handleCloseButton={handleCloseButton}
        component={
          <ChangeInterviewStatus
            handleCloseButton={handleCloseButton}
            applicantId={applicantId}
            selectedOption={status}
          />
        }
      ></CustomDialog>

      <CustomDialog
        openDialog={dialogType === "Onborded"}
        dialogTitle="Onboarded Candidate"
        handleCloseButton={handleCloseButton}
        component={
          <OnboardedCandidate
            handleCloseButton={handleCloseButton}
            applicantId={applicantId}
          />
        }
      ></CustomDialog>
      <CustomDialog
        openDialog={dialogType === "Reject"}
        dialogTitle="Reject Candidate"
        handleCloseButton={handleCloseButton}
        component={
          <RejecteCandidate
            handleCloseButton={handleCloseButton}
            applicantId={applicantId}
          />
        }
      ></CustomDialog>

      {/* scheduled interview */}
      <CustomDialog
        openDialog={dialogType === "Schedule" || dialogType === "Re-Schedule"}
        dialogTitle={
          dialogType === "Schedule"
            ? "Schedule-Interview"
            : "Re-Schedule Interview"
        }
        handleCloseButton={handleCloseButton}
        component={
          <ScheduleInterview
            handleCloseButton={handleCloseButton}
            applicantList={applicantList}
            applicantId={applicantId}
            dialogType={dialogType}
            validationTrigger={validationTrigger}
            setValidationTrigger={setValidationTrigger}
            getListAPI={getListAPI}
          />
        }
      ></CustomDialog>
    </>
  );
};
export default ApplicantInterviewList;
