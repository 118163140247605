import React, { useEffect, useState } from "react";
import "../../../assets/styles/css/ForgotPasswordPage.css";
import CustomButton from "../../../components/customComponents/common/CustomButton";
import CustomTextField from "../../../components/customComponents/common/CustomTextField";
import API_CONFIG_VAR from "../../../config/API_URL_CONFIG.json";
import { postNoAuthAPIHandler } from "../../../apiHandler/RestAPIHandler";
import validation from "./validation";
import { customMessages } from "../../../CustomDatas";
import CustomSnackbar from "../../../components/customComponents/common/CustomSnackbar";
import CustomDialog from "../../../components/customComponents/common/CustomDialog";
import OtpVerification from "./OtpVerification";
import { Link } from "react-router-dom";

const ForgotPasswordPage = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [validationTrigger, setValidationTrigger] = useState(false);
  const [forgotDetails, setForgotDetails] = useState({
    email: "",
  });
  const [id, setId] = useState("");
  const [dialogType, setDialogType] = React.useState("");
  const [notificationData, setNotificationData] = useState({
    message: "",
    severity: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (validationTrigger) setErrors(validation(forgotDetails));
  }, [forgotDetails, validationTrigger]);
  const forgotTrigger = (event) => {
    event.preventDefault();
    setValidationTrigger(true);
    let validationError = validation(forgotDetails);
    setErrors(validationError);
    if (!validationError?.error) {
      postNoAuthAPIHandler(API_CONFIG_VAR.FORGOT_PASSWORD, forgotDetails).then(
        (result) => {
          if (result?.response?.status === 200) {
            setId(result?.response?.data?.data?.id);
            setOpenSnackbar(true);
            setNotificationData({
              message: customMessages.forgotPasswordSuccess,
              severity: customMessages.severitySuccess,
            });
            OpenDialog("OTPVerify");
          } else if (result?.response?.status === 412) {
            setOpenSnackbar(true);
            setNotificationData({
              message: result?.response?.data?.message,
              severity: customMessages.severityError,
            });
          } else {
            return (
              setOpenSnackbar(true),
              setNotificationData({
                message: customMessages.forgotError,
                severity: customMessages.severityError,
              })
            );
          }
        }
      );
    } else {
      return (
        setOpenSnackbar(true),
        setNotificationData({
          message: customMessages.formFillError,
          severity: customMessages.severityError,
        })
      );
    }
  };
  const textChange = (event) => {
    setForgotDetails({
      ...forgotDetails,
      [event.target.name]: event.target.value,
    });
  };
  const OpenDialog = (e, item) => {
    setDialogType(e);
  };
  const handleClose = () => {
    setOpenSnackbar(false);
  };
  const handleCloseButton = () => {
    setDialogType("");
  };
  return (
    <>
      {/* Custom notification snackbar */}
      <CustomSnackbar
        openSnackbar={openSnackbar}
        message={notificationData?.message}
        handleClose={handleClose}
        severity={notificationData?.severity}
      />
      <div className="bg-gradient-primary">
        <div className="container">
          <div className="row justify-content-center w-height-forgotpage">
            <div className="col-xl-9 col-lg-12 col-md-9">
              <div className="card o-hidden border-0 shadow-lg my-5">
                <div className="card-body p-0">
                  <div className="row">
                    <div className="col-lg-6 d-none d-lg-block bg-password-image"></div>
                    <div className="col-lg-6">
                      <div className="p-5">
                        <div className="text-center">
                          <h1 className="h4 text-gray-900 mb-2">
                            Forgot Your Password?
                          </h1>
                          <p className="mb-4">
                            We get it, stuff happens. Just enter your email
                            address below and we'll send you a link to reset
                            your password!
                          </p>
                        </div>
                        <form className="user">
                          <div className="form-group">
                            <CustomTextField
                              name="email"
                              label=" Email  "
                              variant="outlined"
                              margin="normal"
                              size="small"
                              value={forgotDetails?.email}
                              fullWidth
                              onChange={textChange}
                            />
                            {errors.email && (
                              <span className="register-error-span ">
                                {errors.email}
                              </span>
                            )}
                          </div>

                          <hr />
                          <div className="text-center">
                            <CustomButton
                              name="Submit"
                              size="small"
                              fullWidth
                              onClick={forgotTrigger}
                            />
                          </div>
                        </form>
                        <div className="footer-nav-link-forgot">
                          Back to
                          <span>
                            <Link to="/admin-console-login"> Sign In?</Link>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Applied candidates dialog */}
          <CustomDialog
            openDialog={dialogType === "OTPVerify"}
            dialogTitle="Verify  OTP "
            handleCloseButton={handleCloseButton}
            component={
              <OtpVerification handleCloseButton={handleCloseButton} id={id} />
            }
          ></CustomDialog>
        </div>
      </div>
    </>
  );
};
export default ForgotPasswordPage;
