const validation = (changeInterviewStatusOption, inputValue) => {
  let errors = { error: false };
  if (!changeInterviewStatusOption) {
    errors.changeInterviewStatusOption = "Please select option";
    errors.error = true;
  }
  if (!inputValue) {
    errors.inputValue = "Please enter comments";
    errors.error = true;
  }

  return errors;
};

export default validation;
