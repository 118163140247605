import React, { useState, useEffect } from "react";
import CustomSnackbar from "../../../../components/customComponents/common/CustomSnackbar";
import { Divider } from "@mui/material";
import API_URL_CONFIG from "../../../../config/API_URL_CONFIG.json";
import { getAPIHandler } from "../../../../apiHandler/RestAPIHandler";

const ApplicantDetails = ({
  appliedCandidateDetails,
  userDetails,

  getNotificationCount,
}) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [appliedUserDetails, setAppliedUserDetails] = useState({});

  useEffect(() => {
    if (appliedCandidateDetails) {
      readApplicantDetails(appliedCandidateDetails.applicantId);
    }
    if (userDetails) {
      readApplicantDetails(userDetails._id);
    }
  });
  const handleClose = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {}, [appliedUserDetails?.applicantStatus]);

  const readApplicantDetails = (applicantId) => {
    getAPIHandler(
      API_URL_CONFIG.APPLICANT_DETAILS + "?_id=" + applicantId
    ).then((result) => {
      if (result?.response?.status === 200) {
        setAppliedUserDetails(result?.response?.data?.data);
        getNotificationCount(1);
      }
    });
  };

  return (
    <>
      {/* Custom notification snackbar */}
      <CustomSnackbar openSnackbar={openSnackbar} handleClose={handleClose} />
      <div className="Applicant-Details">
        <div className="container">
          <form className="g-3">
            <div className="row">
              <div className="col-md-12 row">
                <div className="col-md-4">
                  <label
                    htmlFor="inputText"
                    className="form-label-applicant-details"
                  >
                    Full Name
                  </label>
                  <div
                    autoFocus
                    name="jobTitle"
                    variant="outlined"
                    size="small"
                    style={{ marginBottom: 20 }}
                    fullWidth
                  >
                    {appliedUserDetails?.fullName}
                  </div>
                </div>
                <div className="col-md-4">
                  <label
                    htmlFor="inputEmail4"
                    className="form-label-applicant-details"
                  >
                    Email
                  </label>
                  <div
                    name="email"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    style={{ marginBottom: 20 }}
                    fullWidth
                  >
                    {appliedUserDetails?.email}
                  </div>
                </div>
                <div className="col-md-4">
                  <label
                    htmlFor="inputText"
                    className="form-label-applicant-details"
                  >
                    Position / Role
                  </label>
                  <div
                    name="position"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    style={{ marginBottom: 20 }}
                    fullWidth
                  >
                    {appliedUserDetails?.position
                      ? appliedUserDetails.position
                      : "(Not given)"}
                  </div>
                </div>
                <div className="col-md-4">
                  <label
                    htmlFor="inputText"
                    className="form-label-applicant-details"
                  >
                    Qualification
                  </label>
                  <div
                    name="qualification"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    fullWidth
                    style={{ marginBottom: 20 }}
                  >
                    {appliedUserDetails?.qualification}
                  </div>
                </div>
                <div className="col-md-4">
                  <label
                    htmlFor="inputText"
                    className="form-label-applicant-details"
                  >
                    Skills
                  </label>
                  <div
                    name="skills"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    style={{ marginBottom: 20 }}
                    fullWidth
                  >
                    {appliedUserDetails.keySkills
                      ? appliedUserDetails.keySkills.length
                        ? appliedUserDetails.keySkills.join(",")
                        : "(Not given)"
                      : "(Not given)"}
                  </div>
                </div>
                <div className="col-md-4">
                  <label
                    htmlFor="inputText"
                    className="form-label-applicant-details"
                  >
                    Preferred Location
                  </label>
                  <div
                    name="location"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    fullWidth
                    style={{ marginBottom: 20 }}
                  >
                    {appliedUserDetails?.location}
                  </div>
                </div>
                <div className="col-md-4">
                  <label
                    htmlFor="inputText"
                    className="form-label-applicant-details"
                  >
                    Mobile Number
                  </label>
                  <div
                    name="location"
                    variant="outlined"
                    margin="normal"
                    style={{ marginBottom: 20 }}
                    size="small"
                    fullWidth
                  >
                    {appliedUserDetails?.phone}
                  </div>
                </div>
                <div className="col-md-4">
                  <label
                    htmlFor="inputNumber"
                    className="form-label-applicant-details"
                  >
                    Total Experience
                  </label>
                  <div className="row">
                    <div className="col-md-6">
                      <div
                        name="experienceInYear"
                        variant="outlined"
                        size="small"
                        fullWidth
                        margin="normal"
                        style={{ marginBottom: 20 }}
                        InputProps={{ inputProps: { min: 0 } }}
                        type="Number"
                      >
                        {appliedUserDetails?.experienceInYear}{" "}
                        {appliedUserDetails.experienceInYear === "1" ||
                        appliedUserDetails.experienceInYear === "0"
                          ? "year and "
                          : "years and "}
                        {appliedUserDetails?.experienceInMonth}{" "}
                        {appliedUserDetails.experienceInMonth === "1" ||
                        appliedUserDetails.experienceInMonth === "0"
                          ? " month"
                          : " months"}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <label
                    htmlFor="inputNumber"
                    className="form-label-applicant-details"
                  >
                    Relevant Experience
                  </label>
                  <div className="row">
                    <div className="col-md-6">
                      <div
                        name="relevantExperienceInYear"
                        variant="outlined"
                        size="small"
                        fullWidth
                        margin="normal"
                        style={{ marginBottom: 20 }}
                        InputProps={{ inputProps: { min: 0 } }}
                        type="Number"
                      >
                        {appliedUserDetails?.relevantExperienceInYear}{" "}
                        {appliedUserDetails.relevantExperienceInYear === "1" ||
                        appliedUserDetails.relevantExperienceInYear === "0"
                          ? "year and "
                          : "years and "}
                        {appliedUserDetails?.relevantExperienceInMonth}{" "}
                        {appliedUserDetails.relevantExperienceInMonth === "1" ||
                        appliedUserDetails.relevantExperienceInMonth === "0"
                          ? " month"
                          : " months"}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <label
                    htmlFor="inputState"
                    className="form-label-applicant-details"
                  >
                    Notice period
                  </label>
                  <div
                    name="location"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    fullWidth
                    style={{ marginBottom: 20 }}
                  >
                    {appliedUserDetails?.noticeperiod}
                  </div>
                </div>
                <div className="col-md-4">
                  <label
                    htmlFor="inputState"
                    className="form-label-applicant-details"
                  >
                    Time Shift
                  </label>
                  <div
                    name="location"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    fullWidth
                    style={{ marginBottom: 20 }}
                  >
                    {appliedUserDetails?.affordableTime
                      ? appliedUserDetails?.affordableTime
                      : "(Not given)"}
                  </div>
                </div>

                <div className="col-md-4">
                  <label
                    htmlFor="inputText"
                    className="form-label-applicant-details"
                  >
                    Is Expected CTC negotiable?
                  </label>
                  <div
                    name="location"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    fullWidth
                    style={{ marginBottom: 20 }}
                  >
                    {appliedUserDetails?.negotiable === true ? "Yes" : "No"}
                  </div>
                </div>
                <div className="col-md-4">
                  <label
                    htmlFor="inputText"
                    className="form-label-applicant-details"
                  >
                    Preferred Job Type
                  </label>
                  <div
                    name="location"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    fullWidth
                    style={{ marginBottom: 20 }}
                  >
                    {appliedUserDetails?.jobType
                      ? appliedUserDetails?.jobType === "full_time"
                        ? "Full Time"
                        : "Part Time"
                      : "(Not given)"}
                  </div>
                </div>

                <div className="col-md-4">
                  <label
                    htmlFor="inputText"
                    className="form-label-applicant-details"
                  >
                    Current CTC
                  </label>

                  <div
                    name="currentCTC"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    fullWidth
                    style={{ marginBottom: 20 }}
                  >
                    {appliedUserDetails?.currentCTC
                      ? appliedUserDetails?.currentCTC
                      : "(Not given)"}
                  </div>
                </div>
                <div className="col-md-4">
                  <label
                    htmlFor="inputText"
                    className="form-label-applicant-details"
                  >
                    Expected CTC
                  </label>
                  <div
                    name="expectedCTC"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    fullWidth
                    style={{ marginBottom: 20 }}
                  >
                    {appliedUserDetails?.expectedCTC
                      ? appliedUserDetails?.expectedCTC
                      : "(Not given)"}
                  </div>
                </div>
              </div>
            </div>
          </form>

          {/* {appliedUserDetails.comments != null ? (
            <div> */}
          <Divider style={{ marginTop: 10 }} />
          <div className="container">
            <form className="g-3">
              <div className="col-md-6">
                <label
                  className="form-label-applicant-details"
                  style={{
                    marginTop: 20,
                  }}
                >
                  Comments :
                </label>

                <div
                  className="comment-border-style"
                  style={{
                    width: 1000,
                    height: 100,
                    overflowX: "hidden",
                    overflowY: "auto",
                  }}
                >
                  <div
                    style={{
                      color: "rgb(186, 181, 181)",
                      fontWeight: "500",
                      fontSize: "0.9rem",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: appliedUserDetails.comments,
                    }}
                  ></div>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* ) : (
            <></>
          )}
        </div> */}
      </div>
    </>
  );
};
export default ApplicantDetails;
