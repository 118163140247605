import React, { useEffect, useState } from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import LinkedInShare from "../LinkedInShare";
import FaceBookShare from "../FaceBookShare";
import CustomDialog from "../../../../components/customComponents/common/CustomDialog";
const ShareJob = ({ jobDetails }) => {
  const [dialogType, setDialogType] = useState("");

  const handleCloseButton = () => {
    setDialogType("");
  };

  const OpenDialog = (e) => {
    setDialogType(e);
  };
  return (
    <div className="share-window">
      <FacebookIcon
        fontSize="large"
        style={{ fill: "#0072b1", margin: 20 }}
        onClick={() => OpenDialog("FaceBookShare")}
      />
      <LinkedInIcon
        fontSize="large"
        style={{ fill: "#0072b1", margin: 20 }}
        onClick={() => OpenDialog("LinkedInShare")}
      />
      <CustomDialog
        openDialog={dialogType === "LinkedInShare"}
        dialogTitle="Share Job"
        handleCloseButton={handleCloseButton}
        component={
          <LinkedInShare
            handleCloseButton={handleCloseButton}
            jobDetails={jobDetails}
          />
        }
      ></CustomDialog>

      <CustomDialog
        openDialog={dialogType === "FaceBookShare"}
        dialogTitle="Share Job"
        handleCloseButton={handleCloseButton}
        component={
          <FaceBookShare
            handleCloseButton={handleCloseButton}
            jobDetails={jobDetails}
          />
        }
      ></CustomDialog>
    </div>
  );
};
export default ShareJob;
