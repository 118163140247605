import CustomButton from "../../../../components/customComponents/common/CustomButton";
import "../../../../assets/styles/css/IndexPage.css";
import { Link } from "react-router-dom";

const UploadResume = () => {
  return (
    <>
      <div className="last-section">
        <div className="container">
          <div className="row">
            <p>
              If you still don’t see the opening you were looking for, don’t be
              put off. We’re always looking for talented people. Drop us your
              resume and we’ll let you know when there’s a vacancy.
            </p>

            <div className="col-md-6 ">
              <div className="experience">
                <h5>Got Experience ?</h5>

                <Link
                  to={`/job-apply/${"experienced"}/${"open"}`}
                  style={{ textDecoration: "none" }}
                >
                  <CustomButton size="small" name="Upload Your Resume" />
                </Link>
              </div>
            </div>
            <div className="col-md-6 ">
              <div className="experience">
                <h5>Are You A Fresher ?</h5>

                <Link
                  to={`/job-apply/${"fresher"}/${"open"}`}
                  style={{ textDecoration: "none" }}
                >
                  <CustomButton size="small" name="Upload Your Resume" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UploadResume;
