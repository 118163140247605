import React, { useState } from "react";
import { Grid } from "@mui/material";
import { postAPIHandler } from "../../../../apiHandler/RestAPIHandler";
import CustomButton from "../../../../components/customComponents/common/CustomButton";
import API_URL_CONFIG from "../../../../config/API_URL_CONFIG.json";
import CustomSnackbar from "../../../../components/customComponents/common/CustomSnackbar";
import { customMessages } from "../../../../CustomDatas";
const RejectRequest = ({
  handleCloseButton,
  getRequestList,
  requestDetails,
}) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [notificationData, setNotificationData] = useState({
    message: "",
    severity: "",
  });

  const requestRejectApi = () => {
    const URL = API_URL_CONFIG.UPDATE_USER;
    const data = {
      _id: requestDetails?._id,
      role: [],
      userStatus: "reject",
      isPasswordResetRequired: true,
    };
    postAPIHandler(URL, data)
      .then((result) => {
        if (result?.response?.status === 200) {
          return (
            setOpenSnackbar(true),
            setNotificationData({
              message: customMessages.requestRejectSuccess,
              severity: customMessages.severitySuccess,
            }),
            setTimeout(() => {
              getRequestList(1);
              handleCloseButton();
            }, 1000)
          );
        } else if (result.response.status === 401) {
          setOpenSnackbar(true);
          setNotificationData({
            message: "Session Expired, Please login",
            severity: "error",
          });
          setTimeout(() => {
            window.location.replace("/admin-console-login");
            localStorage.clear();
          }, 1000);
        } else if (result?.response?.status === 412) {
          return (
            setOpenSnackbar(true),
            setNotificationData({
              message: customMessages.commonError,
              severity: customMessages.severityError,
            })
          );
        }
      })
      .catch((err) => {
        return (
          setOpenSnackbar(true),
          setNotificationData({
            message: customMessages.commonError,
            severity: customMessages.severityError,
          })
        );
      });
    setTimeout(() => {
      handleCloseButton();
    }, 1000);
  };
  const handleClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      {/* Custom notification snackbar */}
      <CustomSnackbar
        openSnackbar={openSnackbar}
        message={notificationData?.message}
        handleClose={handleClose}
        severity={notificationData?.severity}
      />
      <Grid
        style={{ minWidth: 400 }}
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid xs={12}>
          <p>
            Are you sure want to Reject -
            <span style={{ color: "rgba(54, 171, 155)" }}>
              {requestDetails?.userName}
            </span>
          </p>
        </Grid>
      </Grid>
      <hr />
      <br />
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
        spacing={2}
      >
        <Grid xs={6} sm={3}>
          <CustomButton
            onClick={handleCloseButton}
            color="secondary"
            name="Cancel"
          />
        </Grid>
        <Grid xs={6} sm={3}>
          <CustomButton
            name="Reject"
            color="error"
            onClick={requestRejectApi}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default RejectRequest;
