import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material";

export const JobListSkeletonPublic = () => {
  return Array(3)
    .fill()
    .map((item, i) => (
      <>
        <div key={i} className="job-listing">
          <div className="container">
            <div className="job-list-seprate" style={{ minHeight: 200 }}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={12} md={2}>
                  <div className="job-list-left">
                    <Stack spacing={1}>
                      <Skeleton animation="wave" variant="rounded" />
                      <Skeleton animation="wave" variant="rounded" />
                      <Skeleton animation="wave" variant="rounded" />
                      <Skeleton animation="wave" variant="rounded" />
                    </Stack>
                  </div>
                </Grid>
                <Grid item xs={12} md={1}>
                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    height="30px"
                    width="90px"
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </>
    ));
};
export const PaginationSkeleton = () => {
  return (
    <div className="container">
      <div className="job-list-pagination">
        <Skeleton animation="wave" variant="rounded" width="250px" />
      </div>
    </div>
  );
};

// Current Openings
export const JobCardListSkeleton = () => {
  return (
    <>
      <Grid
        spacing={{ xs: 4, md: 4, sm: 4 }}
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid item sm={3}>
          <div style={{ width: 250 }} className="card">
            <div className="card-body">
              <>
                <h5>
                  <Skeleton />
                </h5>
                <p className="card-text">
                  <Skeleton />
                </p>
                <p className="card-text">
                  <Skeleton />
                </p>
                <Skeleton />
              </>
            </div>
          </div>
        </Grid>
        <Grid item sm={3}>
          <div style={{ width: 250 }} className="card">
            <div className="card-body">
              <>
                <h5>
                  <Skeleton />
                </h5>
                <p className="card-text">
                  <Skeleton />
                </p>
                <p className="card-text">
                  <Skeleton />
                </p>
                <Skeleton />
              </>
            </div>
          </div>
        </Grid>
        <Grid item sm={3}>
          <div style={{ width: 250 }} className="card">
            <div className="card-body">
              <>
                <h5>
                  <Skeleton />
                </h5>
                <p className="card-text">
                  <Skeleton />
                </p>
                <p className="card-text">
                  <Skeleton />
                </p>
                <Skeleton />
              </>
            </div>
          </div>
        </Grid>
        <Grid item sm={3}>
          <div style={{ width: 250 }} className="card">
            <div className="card-body">
              <>
                <h5>
                  <Skeleton />
                </h5>
                <p className="card-text">
                  <Skeleton />
                </p>
                <p className="card-text">
                  <Skeleton />
                </p>
                <Skeleton />
              </>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

// job discription  page
export const JobDiscriptionHeadSkeleton = () => {
  return (
    <>
      <Skeleton width="250px" />
      <Skeleton width="250px" />
    </>
  );
};
export const JobDiscriptionBodySkeleton = () => {
  return (
    <>
      <div className="career-info">
        <div className="container">
          <div className="career-details-content">
            <div className="block-wrapper">
              <div className="block-item">
                <h2 className="main-title">Job Information</h2>
                <span>
                  <Skeleton width="250px" />
                  <p></p>
                  <h3 className="sub-title">Skills Required</h3>
                  <p></p>
                  <Skeleton width="250px" />
                </span>
                <h3 className="sub-title">Experience</h3>
                <p className="location-details">
                  <Skeleton width="250px" />
                </p>
                <h3 className="sub-title">Qualification</h3>
                <p className="location-details">
                  <Skeleton width="250px" />
                </p>
                <h3 className="sub-title"> Location</h3>
                <p className="location-details">
                  <Skeleton width="250px" />
                </p>
                <h3 className="sub-title">Work Type</h3>
                <p className="location-details">
                  <Skeleton width="250px" />
                </p>
                <h3 className="sub-title">Time Shift</h3>
                <p className="location-details">
                  <Skeleton width="250px" />
                </p>
                <Skeleton width="250px" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
