import React from "react";
import {
  ListSkeleton,
  PaginationSkeleton,
} from "../../../components/Skeleton/admin";
import { Pagination } from "@mui/material";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import ArrowCircleRightSharpIcon from "@mui/icons-material/ArrowCircleRightSharp";
import CustomButton from "../../../components/customComponents/common/CustomButton";

// shortlisted start
export const ShortListed = ({
  listLoader,
  applicantList,
  OpenDialog,
  ListEmptyState,
  pageNo,
  paginationChange,
  applicantListCount,
}) => {
  return (
    <div className="table-responsive-xl">
      <div className="wrap-table100">
        <div className="shadow table100" style={{ minHeight: 300 }}>
          <table>
            <thead>
              <tr className="table100-head">
                <th scope="col" className="column">
                  SL No.
                </th>
                <th scope="col" className="column-td">
                  Name
                </th>
                <th scope="col" className="column-td">
                  Email
                </th>
                <th scope="col" className="column-td">
                  Job Title
                </th>
                <th scope="col" className="column-td">
                  Job ID
                </th>
                <th scope="col" className="column-td">
                  Experience
                </th>
                <th scope="col" className="column-td">
                  Interview Status
                </th>

                <th scope="col" className="column">
                  Resume
                </th>

                <th scope="col" className="column"></th>
                <th scope="col" className="column"></th>
                <th scope="col" className="column"></th>
                <th scope="col" className="column"></th>
              </tr>
            </thead>
            {listLoader ? (
              <ListSkeleton />
            ) : applicantList ? (
              applicantList.length ? (
                applicantList.map((item, i) => {
                  return (
                    <tbody key={i}>
                      <tr>
                        <td className="column">
                          {pageNo === 1 ? i + 1 : (pageNo - 1) * 5 + (i + 1)}
                        </td>

                        <td
                          style={{
                            color: "rgba(54, 171, 155)",
                          }}
                          className="column-td"
                          onClick={() =>
                            OpenDialog("AppliedCandidatesPage", item)
                          }
                        >
                          {" "}
                          {item?.fullName}
                        </td>
                        <td className="column-td"> {item?.email}</td>
                        <td className="column-td"> {item?.jobTitle}</td>
                        <td className="column-td"> {item?.jobId}</td>
                        <td className="column-td">
                          {item?.experienceInYear}
                          {item?.experienceInYear === "1"
                            ? " year "
                            : " years "}
                          & {item?.experienceInMonth}
                          {item?.experienceInMonth === "1"
                            ? " month"
                            : " months"}
                        </td>

                        <td className="column-td">
                          {item.interviewMode === "L1 Interview"
                            ? "L1 Scheduled"
                            : item.interviewMode === "open"
                            ? "Shortlisted"
                            : ""}
                        </td>

                        <td className="column">
                          <SimCardDownloadIcon
                            onClick={() => OpenDialog("ReadResume", item)}
                            color="primary"
                          />
                        </td>

                        <td className="column">
                          {item.interviewMode === "L1 Interview" ? (
                            <CustomButton
                              size="small"
                              color="primary"
                              onClick={() => OpenDialog("Re-Schedule", item)}
                              name="Re-Schedule"
                            />
                          ) : item.interviewMode === "open" ? (
                            <CustomButton
                              size="small"
                              onClick={() => OpenDialog("Schedule", item)}
                              name="Schedule"
                              color="primary"
                            />
                          ) : (
                            <CustomButton
                              size="small"
                              onClick={() => OpenDialog("Schedule", item)}
                              name="Schedule"
                              color="primary"
                            />
                          )}
                        </td>
                        <td className="column">
                          <CustomButton
                            size="small"
                            onClick={() => OpenDialog("Reject", item)}
                            name="Reject"
                            color="error"
                          />
                        </td>
                        <td className="column">
                          <ArrowCircleRightSharpIcon
                            onClick={() => OpenDialog("MoveTo", item)}
                            color="primary"
                          />
                        </td>
                      </tr>
                    </tbody>
                  );
                })
              ) : (
                <ListEmptyState emptyState="No results found" />
              )
            ) : (
              <ListEmptyState emptyState="No results found" />
            )}
          </table>
        </div>
        {listLoader ? (
          <PaginationSkeleton />
        ) : applicantList ? (
          applicantListCount > 5 ? (
            <div className="job-list-pagination">
              <Pagination
                count={
                  applicantListCount < 5 ? 1 : Math.ceil(applicantListCount / 5)
                }
                onChange={paginationChange}
                page={pageNo}
                color="primary"
                size="small"
              />
            </div>
          ) : (
            <div style={{ marginBottom: 20 }} />
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
// shortlisted  end

// first round start
export const FirstRound = ({
  listLoader,
  applicantList,
  OpenDialog,
  ListEmptyState,
  pageNo,
  paginationChange,
  applicantListCount,
}) => {
  return (
    <div className="table-responsive-xl">
      <div className="wrap-table100">
        <div className="shadow table100" style={{ minHeight: 300 }}>
          <table>
            <thead>
              <tr className="table100-head">
                <th scope="col" className="column">
                  SL No.
                </th>
                <th scope="col" className="column-td">
                  Name
                </th>
                <th scope="col" className="column-td">
                  Email
                </th>
                <th scope="col" className="column-td">
                  Job Title
                </th>
                <th scope="col" className="column-td">
                  Job ID
                </th>
                <th scope="col" className="column-td">
                  Experience
                </th>
                <th scope="col" className="column-td">
                  Interview Status
                </th>
                {/* <th scope="col" className="column-td">
                  Interview Level
                </th> */}
                <th scope="col" className="column">
                  Resume
                </th>

                <th scope="col" className="column"></th>
                <th scope="col" className="column"></th>
                <th scope="col" className="column"></th>
                <th scope="col" className="column"></th>
              </tr>
            </thead>
            {listLoader ? (
              <ListSkeleton />
            ) : applicantList ? (
              applicantList.length ? (
                applicantList.map((item, i) => {
                  return (
                    <tbody key={i}>
                      <tr>
                        <td className="column">
                          {pageNo === 1 ? i + 1 : (pageNo - 1) * 10 + (i + 1)}
                        </td>

                        <td
                          style={{
                            color: "rgba(54, 171, 155)",
                          }}
                          className="column-td"
                          onClick={() =>
                            OpenDialog("AppliedCandidatesPage", item)
                          }
                        >
                          {" "}
                          {item?.fullName}
                        </td>
                        <td className="column-td"> {item?.email}</td>
                        <td className="column-td"> {item?.jobTitle}</td>
                        <td className="column-td"> {item?.jobId}</td>
                        <td className="column-td">
                          {item?.experienceInYear}
                          {item?.experienceInYear === "1"
                            ? " year "
                            : " years "}
                          & {item?.experienceInMonth}
                          {item?.experienceInMonth === "1"
                            ? " month"
                            : " months"}
                        </td>
                        <td>
                          {item.interviewMode === "L1 Interview"
                            ? "L1 Completed"
                            : item.interviewMode === "L2 Interview"
                            ? "L2 Scheduled"
                            : ""}
                        </td>

                        <td className="column">
                          <SimCardDownloadIcon
                            onClick={() => OpenDialog("ReadResume", item)}
                            color="primary"
                          />
                        </td>
                        <td className="column">
                          {item.interviewMode === "L2 Interview" ? (
                            <CustomButton
                              size="small"
                              color="primary"
                              onClick={() => OpenDialog("Re-Schedule", item)}
                              name="Re-Schedule"
                            />
                          ) : item.interviewMode === "L1 Interview" ? (
                            <CustomButton
                              size="small"
                              onClick={() => OpenDialog("Schedule", item)}
                              name="Schedule"
                              color="primary"
                            />
                          ) : (
                            <CustomButton
                              size="small"
                              onClick={() => OpenDialog("Schedule", item)}
                              name="Schedule"
                              color="primary"
                            />
                          )}
                        </td>
                        <td className="column">
                          <CustomButton
                            size="small"
                            onClick={() => OpenDialog("Reject", item)}
                            name="Reject"
                            color="error"
                          />
                        </td>
                        <td className="column">
                          <ArrowCircleRightSharpIcon
                            onClick={() => OpenDialog("MoveTo", item)}
                            color="primary"
                          />
                        </td>
                      </tr>
                    </tbody>
                  );
                })
              ) : (
                <ListEmptyState emptyState="No results found" />
              )
            ) : (
              <ListEmptyState emptyState="No results found" />
            )}
          </table>
        </div>
        {listLoader ? (
          <PaginationSkeleton />
        ) : applicantList ? (
          applicantListCount > 5 ? (
            <div className="job-list-pagination">
              <Pagination
                count={
                  applicantListCount < 5 ? 1 : Math.ceil(applicantListCount / 5)
                }
                onChange={paginationChange}
                page={pageNo}
                color="primary"
                size="small"
              />
            </div>
          ) : (
            <div style={{ marginBottom: 20 }} />
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
// first round  end

// secound round start
export const SecoundRound = ({
  listLoader,
  applicantList,
  OpenDialog,
  ListEmptyState,
  pageNo,
  paginationChange,
  applicantListCount,
}) => {
  return (
    <div className="table-responsive-xl">
      <div className="wrap-table100">
        <div className="shadow table100" style={{ minHeight: 300 }}>
          <table>
            <thead>
              <tr className="table100-head">
                <th scope="col" className="column">
                  SL No.
                </th>
                <th scope="col" className="column-td">
                  Name
                </th>
                <th scope="col" className="column-td">
                  Email
                </th>
                <th scope="col" className="column-td">
                  Job Title
                </th>
                <th scope="col" className="column-td">
                  Job ID
                </th>
                <th scope="col" className="column-td">
                  Experience
                </th>
                <th scope="col" className="column-td">
                  Interview Status
                </th>
                {/* <th scope="col" className="column-td">
                  Interview Level
                </th> */}
                <th scope="col" className="column">
                  Resume
                </th>

                <th scope="col" className="column"></th>
                <th scope="col" className="column"></th>
                <th scope="col" className="column"></th>
                <th scope="col" className="column"></th>
              </tr>
            </thead>
            {listLoader ? (
              <ListSkeleton />
            ) : applicantList ? (
              applicantList.length ? (
                applicantList.map((item, i) => {
                  return (
                    <tbody key={i}>
                      <tr>
                        <td className="column">
                          {pageNo === 1 ? i + 1 : (pageNo - 1) * 10 + (i + 1)}
                        </td>

                        <td
                          style={{
                            color: "rgba(54, 171, 155)",
                          }}
                          className="column-td"
                          onClick={() =>
                            OpenDialog("AppliedCandidatesPage", item)
                          }
                        >
                          {" "}
                          {item?.fullName}
                        </td>
                        <td className="column-td"> {item?.email}</td>
                        <td className="column-td"> {item?.jobTitle}</td>
                        <td className="column-td"> {item?.jobId}</td>
                        <td className="column-td">
                          {item?.experienceInYear}
                          {item?.experienceInYear === "1"
                            ? " year "
                            : " years "}
                          & {item?.experienceInMonth}
                          {item?.experienceInMonth === "1"
                            ? " month"
                            : " months"}
                        </td>
                        <td>
                          {item.interviewMode === "L2 Interview"
                            ? "L2 Completed"
                            : item.interviewMode === "HR Interview"
                            ? "HR Scheduled"
                            : ""}
                        </td>

                        <td className="column">
                          <SimCardDownloadIcon
                            onClick={() => OpenDialog("ReadResume", item)}
                            color="primary"
                          />
                        </td>

                        <td className="column">
                          {item.interviewMode === "HR Interview" ? (
                            <CustomButton
                              size="small"
                              color="primary"
                              onClick={() => OpenDialog("Re-Schedule", item)}
                              name="Re-Schedule"
                            />
                          ) : item.interviewMode === "L2 Interview" ? (
                            <CustomButton
                              size="small"
                              onClick={() => OpenDialog("Schedule", item)}
                              name="Schedule"
                              color="primary"
                            />
                          ) : (
                            <CustomButton
                              size="small"
                              onClick={() => OpenDialog("Schedule", item)}
                              name="Schedule"
                              color="primary"
                            />
                          )}
                        </td>
                        <td className="column">
                          <CustomButton
                            size="small"
                            onClick={() => OpenDialog("Reject", item)}
                            name="Reject"
                            color="error"
                          />
                        </td>
                        <td className="column">
                          <ArrowCircleRightSharpIcon
                            onClick={() => OpenDialog("MoveTo", item)}
                            color="primary"
                          />
                        </td>
                      </tr>
                    </tbody>
                  );
                })
              ) : (
                <ListEmptyState emptyState="No results found" />
              )
            ) : (
              <ListEmptyState emptyState="No results found" />
            )}
          </table>
        </div>
        {listLoader ? (
          <PaginationSkeleton />
        ) : applicantList ? (
          applicantListCount > 5 ? (
            <div className="job-list-pagination">
              <Pagination
                count={
                  applicantListCount < 5 ? 1 : Math.ceil(applicantListCount / 5)
                }
                onChange={paginationChange}
                page={pageNo}
                color="primary"
                size="small"
              />
            </div>
          ) : (
            <div style={{ marginBottom: 20 }} />
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
// second round  end

// Selected round start
export const Selected = ({
  listLoader,
  applicantList,
  OpenDialog,
  ListEmptyState,
  pageNo,
  paginationChange,
  applicantListCount,
}) => {
  return (
    <div className="table-responsive-xl">
      <div className="wrap-table100">
        <div className="shadow table100" style={{ minHeight: 300 }}>
          <table>
            <thead>
              <tr className="table100-head">
                <th scope="col" className="column">
                  SL No.
                </th>
                <th scope="col" className="column-td">
                  Name
                </th>
                <th scope="col" className="column-td">
                  Email
                </th>
                <th scope="col" className="column-td">
                  Job Title
                </th>
                <th scope="col" className="column-td">
                  Job ID
                </th>
                <th scope="col" className="column-td">
                  Experience
                </th>
                <th scope="col" className="column-td">
                  Interview Status
                </th>
                <th scope="col" className="column">
                  Resume
                </th>

                <th scope="col" className="column"></th>
                <th scope="col" className="column"></th>
                <th scope="col" className="column"></th>
              </tr>
            </thead>
            {listLoader ? (
              <ListSkeleton />
            ) : applicantList ? (
              applicantList.length ? (
                applicantList.map((item, i) => {
                  return (
                    <tbody key={i}>
                      <tr>
                        <td className="column">
                          {pageNo === 1 ? i + 1 : (pageNo - 1) * 10 + (i + 1)}
                        </td>

                        <td
                          style={{
                            color: "rgba(54, 171, 155)",
                          }}
                          className="column-td"
                          onClick={() =>
                            OpenDialog("AppliedCandidatesPage", item)
                          }
                        >
                          {" "}
                          {item?.fullName}
                        </td>
                        <td className="column-td"> {item?.email}</td>
                        <td className="column-td"> {item?.jobTitle}</td>
                        <td className="column-td"> {item?.jobId}</td>
                        <td className="column-td">
                          {item?.experienceInYear}
                          {item?.experienceInYear === "1"
                            ? " year "
                            : " years "}
                          & {item?.experienceInMonth}
                          {item?.experienceInMonth === "1"
                            ? " month"
                            : " months"}
                        </td>
                        <td className="column-td"> {item?.interviewStatus}</td>

                        <td className="column">
                          <SimCardDownloadIcon
                            onClick={() => OpenDialog("ReadResume", item)}
                            color="primary"
                          />
                        </td>
                        <td className="column">
                          <CustomButton
                            size="small"
                            onClick={() => OpenDialog("Reject", item)}
                            name="Reject"
                            color="error"
                          />
                        </td>
                        <td className="column">
                          <CustomButton
                            size="small"
                            onClick={() => OpenDialog("Onborded", item)}
                            name="Onboard"
                            color="primary"
                          />
                        </td>
                      </tr>
                    </tbody>
                  );
                })
              ) : (
                <ListEmptyState emptyState="No results found" />
              )
            ) : (
              <ListEmptyState emptyState="No results found" />
            )}
          </table>
        </div>
        {listLoader ? (
          <PaginationSkeleton />
        ) : applicantList ? (
          applicantListCount > 5 ? (
            <div className="job-list-pagination">
              <Pagination
                count={
                  applicantListCount < 5 ? 1 : Math.ceil(applicantListCount / 5)
                }
                onChange={paginationChange}
                page={pageNo}
                color="primary"
                size="small"
              />
            </div>
          ) : (
            <div style={{ marginBottom: 20 }} />
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
// selected round  end

// onborded start
export const Onboarded = ({
  listLoader,
  applicantList,
  OpenDialog,
  ListEmptyState,
  pageNo,
  paginationChange,
  applicantListCount,
}) => {
  return (
    <div className="table-responsive-xl">
      <div className="wrap-table100">
        <div className="shadow table100" style={{ minHeight: 300 }}>
          <table>
            <thead>
              <tr className="table100-head">
                <th scope="col" className="column">
                  SL No.
                </th>
                <th scope="col" className="column-td">
                  Name
                </th>
                <th scope="col" className="column-td">
                  Email
                </th>
                <th scope="col" className="column-td">
                  Job Title
                </th>
                <th scope="col" className="column-td">
                  Job ID
                </th>
                <th scope="col" className="column-td">
                  Experience
                </th>
                <th scope="col" className="column-td">
                  Interview Status
                </th>
                <th scope="col" className="column">
                  Resume
                </th>

                <th scope="col" className="column"></th>
                <th scope="col" className="column"></th>
                <th scope="col" className="column"></th>
              </tr>
            </thead>
            {listLoader ? (
              <ListSkeleton />
            ) : applicantList ? (
              applicantList.length ? (
                applicantList.map((item, i) => {
                  return (
                    <tbody key={i}>
                      <tr>
                        <td className="column">
                          {pageNo === 1 ? i + 1 : (pageNo - 1) * 10 + (i + 1)}
                        </td>

                        <td
                          style={{
                            color: "rgba(54, 171, 155)",
                          }}
                          className="column-td"
                          onClick={() =>
                            OpenDialog("AppliedCandidatesPage", item)
                          }
                        >
                          {" "}
                          {item?.fullName}
                        </td>
                        <td className="column-td"> {item?.email}</td>
                        <td className="column-td"> {item?.jobTitle}</td>
                        <td className="column-td"> {item?.jobId}</td>
                        <td className="column-td">
                          {item?.experienceInYear}
                          {item?.experienceInYear === "1"
                            ? " year "
                            : " years "}
                          & {item?.experienceInMonth}
                          {item?.experienceInMonth === "1"
                            ? " month"
                            : " months"}
                        </td>
                        <td className="column-td"> {item?.interviewStatus}</td>

                        <td className="column">
                          <SimCardDownloadIcon
                            onClick={() => OpenDialog("ReadResume", item)}
                            color="primary"
                          />
                        </td>
                      </tr>
                    </tbody>
                  );
                })
              ) : (
                <ListEmptyState emptyState="No results found" />
              )
            ) : (
              <ListEmptyState emptyState="No results found" />
            )}
          </table>
        </div>
        {listLoader ? (
          <PaginationSkeleton />
        ) : applicantList ? (
          applicantListCount > 5 ? (
            <div className="job-list-pagination">
              <Pagination
                count={
                  applicantListCount < 5 ? 1 : Math.ceil(applicantListCount / 5)
                }
                onChange={paginationChange}
                page={pageNo}
                color="primary"
                size="small"
              />
            </div>
          ) : (
            <div style={{ marginBottom: 20 }} />
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
// onborded  end

// rejected start
export const Rejected = ({
  listLoader,
  applicantList,
  OpenDialog,
  ListEmptyState,
  pageNo,
  paginationChange,
  applicantListCount,
}) => {
  return (
    <div className="table-responsive-xl">
      <div className="wrap-table100">
        <div className="shadow table100" style={{ minHeight: 300 }}>
          <table>
            <thead>
              <tr className="table100-head">
                <th scope="col" className="column">
                  SL No.
                </th>
                <th scope="col" className="column-td">
                  Name
                </th>
                <th scope="col" className="column-td">
                  Email
                </th>
                <th scope="col" className="column-td">
                  Job Title
                </th>
                <th scope="col" className="column-td">
                  Job ID
                </th>
                <th scope="col" className="column-td">
                  Experience
                </th>
                <th scope="col" className="column-td">
                  Interview Status
                </th>
                <th scope="col" className="column">
                  Resume
                </th>

                <th scope="col" className="column"></th>
                <th scope="col" className="column"></th>
                <th scope="col" className="column"></th>
              </tr>
            </thead>
            {listLoader ? (
              <ListSkeleton />
            ) : applicantList ? (
              applicantList.length ? (
                applicantList.map((item, i) => {
                  return (
                    <tbody key={i}>
                      <tr>
                        <td className="column">
                          {pageNo === 1 ? i + 1 : (pageNo - 1) * 10 + (i + 1)}
                        </td>

                        <td
                          style={{
                            color: "rgba(54, 171, 155)",
                          }}
                          className="column-td"
                          onClick={() =>
                            OpenDialog("AppliedCandidatesPage", item)
                          }
                        >
                          {" "}
                          {item?.fullName}
                        </td>
                        <td className="column-td"> {item?.email}</td>
                        <td className="column-td"> {item?.jobTitle}</td>
                        <td className="column-td"> {item?.jobId}</td>
                        <td className="column-td">
                          {item?.experienceInYear}
                          {item?.experienceInYear === "1"
                            ? " year "
                            : " years "}
                          & {item?.experienceInMonth}
                          {item?.experienceInMonth === "1"
                            ? " month"
                            : " months"}
                        </td>
                        <td className="column-td"> {item?.interviewStatus}</td>

                        <td className="column">
                          <SimCardDownloadIcon
                            onClick={() => OpenDialog("ReadResume", item)}
                            color="primary"
                          />
                        </td>
                      </tr>
                    </tbody>
                  );
                })
              ) : (
                <ListEmptyState emptyState="No results found" />
              )
            ) : (
              <ListEmptyState emptyState="No results found" />
            )}
          </table>
        </div>
        {listLoader ? (
          <PaginationSkeleton />
        ) : applicantList ? (
          applicantListCount > 5 ? (
            <div className="job-list-pagination">
              <Pagination
                count={
                  applicantListCount < 5 ? 1 : Math.ceil(applicantListCount / 5)
                }
                onChange={paginationChange}
                page={pageNo}
                color="primary"
                size="small"
              />
            </div>
          ) : (
            <div style={{ marginBottom: 20 }} />
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
// rejected  end
