import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import "../../../../assets/styles/css/AddJob.css";
import API_URL_CONFIG from "../../../../config/API_URL_CONFIG.json";
import {
  postAPIHandler,
  getAPIHandler,
} from "../../../../apiHandler/RestAPIHandler";

import validation from "./Validation";
import CustomTextField from "../../../../components/customComponents/common/CustomTextField";
import CustomButton from "../../../../components/customComponents/common/CustomButton";
import CustomSnackbar from "../../../../components/customComponents/common/CustomSnackbar";
import { customMessages } from "../../../../CustomDatas";

const AddAndEditRole = ({
  dialogType,
  handleCloseButton,
  getRoleList,
  setValidationTrigger,
  validationTrigger,
  roleDetails,
}) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errors, setErrors] = useState({});
  const [notificationData, setNotificationData] = useState({
    message: "",
    severity: "",
  });
  let initialStates = {
    roleName: "",
    roleDescription: "",
  };
  const [privileges, setPrivileges] = useState([]);
  const [roleFields, setRoleFields] = useState(initialStates);

  useEffect(() => {
    if (validationTrigger) setErrors(validation(roleFields));
  }, [roleFields, validationTrigger]);

  const textChange = (event) => {
    const { name, value } = event.target;
    setRoleFields({
      ...roleFields,
      [name]: value,
    });
  };

  const getRoleDetails = () => {
    if (dialogType === "EditRole") {
      const URL =
        API_URL_CONFIG.GET_PERTICULAR_ROLE_DETAILS + "?_id=" + roleDetails?._id;
      getAPIHandler(URL)
        .then((result) => {
          if (result.message === "Network Error") {
            setRoleFields({});
          } else {
            setRoleFields(result?.response?.data?.data);
          }
          if (result.response.status === 401) {
            setOpenSnackbar(true);
            setNotificationData({
              message: "Session Expired, Please login",
              severity: "error",
            });
            setTimeout(() => {
              window.location.replace("/admin-console-login");
              localStorage.clear();
            }, 1000);
          }
        })
        .catch((err) => {
          setRoleFields({});
        });
    }
  };
  const AddOrEditApi = () => {
    setValidationTrigger(true);
    let validationError = validation(roleFields);
    setErrors(validationError);

    const postData = {
      roleName: roleFields.roleName,
      roleDescription: roleFields.roleDescription,
      privileges: privileges,
      _id: dialogType === "EditRole" ? roleDetails?._id : null,
    };
    if (!validationError?.error) {
      const URL =
        dialogType === "EditRole"
          ? API_URL_CONFIG.EDIT_ROLE
          : API_URL_CONFIG.ADD_ROLE;

      postAPIHandler(URL, postData)
        .then((result) => {
          if (result?.response?.status === 200) {
            return (
              setOpenSnackbar(true),
              setNotificationData({
                message:
                  dialogType === "EditRole"
                    ? customMessages.roleEditedSuccess
                    : customMessages.roleAddedSuccess,
                severity: customMessages.severitySuccess,
              }),
              setTimeout(() => {
                handleCloseButton();
                getRoleList(1);
              }, 1000)
            );
          } else if (result.response.status === 401) {
            setOpenSnackbar(true);
            setNotificationData({
              message: "Session Expired, Please login",
              severity: "error",
            });
            setTimeout(() => {
              window.location.replace("/admin-console-login");
              localStorage.clear();
            }, 1000);
          } else if (result?.response?.status === 412) {
            return (
              setOpenSnackbar(true),
              setNotificationData({
                message: customMessages.roleExistMessage,
                severity: customMessages.severityError,
              })
            );
          }
        })
        .catch((err) => {
          return (
            setOpenSnackbar(true),
            setNotificationData({
              message: customMessages.commonError,
              severity: customMessages.severityError,
            })
          );
        });
    } else {
      return (
        setOpenSnackbar(true),
        setNotificationData({
          message: customMessages.formFillError,
          severity: customMessages.severityError,
        })
      );
    }
  };

  const handleClose = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    setRoleFields(initialStates);
    getRoleDetails();
  }, [dialogType]);

  return (
    <>
      {/* Custom notification snackbar */}
      <CustomSnackbar
        openSnackbar={openSnackbar}
        message={notificationData?.message}
        handleClose={handleClose}
        severity={notificationData?.severity}
      />
      <Grid
        style={{ maxWidth: 450 }}
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid xs={12}>
          <CustomTextField
            name="roleName"
            label="Role"
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            value={roleFields?.roleName}
            onChange={textChange}
          />
          {errors.roleName && (
            <span className="role-error-span">{errors.roleName}</span>
          )}
        </Grid>
        <Grid xs={12}>
          <CustomTextField
            name="roleDescription"
            label="Role Description"
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            value={roleFields?.roleDescription}
            onChange={textChange}
          />
          {errors.roleDescription && (
            <span className="role-error-span ">{errors.roleDescription}</span>
          )}
        </Grid>
      </Grid>
      <hr />
      <br />
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
        spacing={2}
      >
        <Grid xs={3} sm={3}>
          <CustomButton
            onClick={handleCloseButton}
            color="secondary"
            name="Cancel"
          />
        </Grid>
        <Grid xs={3} sm={3}>
          <CustomButton
            name={dialogType === "AddRole" ? "Add" : "Save"}
            onClick={AddOrEditApi}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default AddAndEditRole;
