import React from "react";
import { TextField } from "@mui/material";
const CustomTextField = (props) => {
  const {
    label = "",
    variant = "",
    name,
    autoFocus = false,
    endAdornment,
    textChange,
    value,
  } = props;
  return (
    <>
      <TextField
        name={name}
        label={label}
        variant={variant}
        autoFocus={autoFocus}
        size="small"
        value={value}
        endAdornment={endAdornment}
        onChange={textChange}
        {...props}
      />
    </>
  );
};
export default CustomTextField;
