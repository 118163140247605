import React, { useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../../../assets/styles/css/AddJob.css";
import validation from "./Validation";
import CustomTextField from "../../../../components/customComponents/common/CustomTextField";
import CustomButton from "../../../../components/customComponents/common/CustomButton";
import CustomSnackbar from "../../../../components/customComponents/common/CustomSnackbar";
import { getAPIHandler } from "../../../../apiHandler/RestAPIHandler";
import API_URL_CONFIG from "../../../../config/API_URL_CONFIG.json";
import {
  timeShiftData,
  jobTypeData,
  customMessages,
  WorkMode,
} from "../../../../CustomDatas";
import { addJobAPIHandler, getJobDetails } from "./ApiHandler";
import { border, borderRadius } from "@mui/system";

const AddJob = ({
  dialogType,
  handleCloseButton,
  jobDetails,
  getJobList,
  validationTrigger,
  setValidationTrigger,
  repost,
}) => {
  const [keySkillsList, setKeySkillsList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [qualificationList, setQualificationList] = useState([]);
  const [positionList, setPositionList] = useState([]);

  useEffect(() => {
    getKeySkills();
    getLocation();
    getPosition();
    getQualification();
  }, []);

  const getKeySkills = () => {
    getAPIHandler(API_URL_CONFIG.KEYSKILLS_LIST, {
      responseType: "arraybuffer",
    }).then((result) => {
      const keySkillsArray = result.response.data.data.map(
        (item) => item.keySkills
      );
      setKeySkillsList(keySkillsArray);
    });
  };
  const getLocation = () => {
    getAPIHandler(API_URL_CONFIG.LOCATION_LIST, {
      responseType: "arraybuffer",
    }).then((result) => {
      const locationArray = result.response.data.data.map(
        (item) => item.location
      );
      setLocationList(locationArray);
    });
  };
  const getQualification = () => {
    getAPIHandler(API_URL_CONFIG.QUALIFICATION_LIST, {
      responseType: "arraybuffer",
    }).then((result) => {
      const qualificationArray = result.response.data.data.map(
        (item) => item.qualification
      );
      setQualificationList(qualificationArray);
    });
  };
  const getPosition = () => {
    getAPIHandler(API_URL_CONFIG.POSITION_LIST, {
      responseType: "arraybuffer",
    }).then((result) => {
      const positionArray = result.response.data.data.map(
        (item) => item.position
      );
      setPositionList(positionArray);
    });
  };

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errors, setErrors] = useState({});
  const [notificationData, setNotificationData] = useState({
    message: "",
    severity: "",
  });
  let initialStates = {
    jobTitle: "",
    jobDescription: "",
    location: [],
    position: "",
    keySkills: [],
    qualification: [],
    minimumExperience: "",
    maximumExperience: "",
    minimumAnnualSalary: "",
    maximumAnnualSalary: "",
    timeShift: "",
    fullTime: false,
    partTime: false,
    jobType: "",
    workMode: "",
  };
  const [jobFields, setJobFields] = useState(initialStates);

  const handleJobDescriptionChange = (value) => {
    setJobFields({
      ...jobFields,
      jobDescription: value,
    });
  };

  const textChange = (event) => {
    const { name, value } = event.target;
    setJobFields({
      ...jobFields,
      [name]: value,
    });
  };

  const addJob = (value) => {
    addJobAPIHandler(
      value,
      jobFields,
      customMessages,
      setNotificationData,
      setOpenSnackbar,
      handleCloseButton,
      getJobList,
      initialStates,
      setJobFields,
      setValidationTrigger,
      dialogType,
      errors,
      jobDetails,
      setErrors,
      repost
    );
  };
  useEffect(() => {
    if (validationTrigger) {
      setErrors(validation(jobFields));
    } else {
      setErrors(false);
    }
  }, [jobFields, validationTrigger]);
  const onChageLocation = (event, value) => {
    setJobFields({
      ...jobFields,
      location: value,
    });
  };
  const onChageTimeShift = (event, value) => {
    setJobFields({
      ...jobFields,
      timeShift: value,
    });
  };
  const onChageJobType = (event, value) => {
    if (value === "Full time") {
      setJobFields({
        ...jobFields,
        partTime: false,
        fullTime: true,
        jobType: value,
      });
    } else if (value === "Part time") {
      setJobFields({
        ...jobFields,
        partTime: true,
        fullTime: false,
        jobType: value,
      });
    } else {
      setJobFields({
        ...jobFields,
        partTime: false,
        fullTime: false,
        jobType: value,
      });
    }
  };
  const onChangePosition = (event, value) => {
    setJobFields({
      ...jobFields,
      position: value,
    });
  };
  const onChangeKeySkills = (event, value) => {
    setJobFields({
      ...jobFields,
      keySkills: value,
    });
  };
  const onChangeQualification = (event, value) => {
    setJobFields({
      ...jobFields,
      qualification: value,
    });
  };

  const handleClose = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    setJobFields(initialStates);
    getJobDetails(dialogType, jobDetails, setJobFields); // eslint-disable-next-line
  }, [jobDetails, dialogType]);

  const onChangeWorkMode = (event, value) => {
    setJobFields({
      ...jobFields,
      workMode: value,
    });
  };

  return (
    <>
      {/* Custom notification snackbar */}
      <CustomSnackbar
        openSnackbar={openSnackbar}
        message={notificationData?.message}
        handleClose={handleClose}
        severity={notificationData?.severity}
      />
      <div className="container">
        <form className="g-3">
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="inputText" className="form-label">
                Job Title
                <span className="label-required">*</span>
              </label>
              <CustomTextField
                autoFocus
                name="jobTitle"
                label=" Job Title"
                variant="outlined"
                size="small"
                fullWidth
                disabled={dialogType === "JobDetails"}
                value={jobFields?.jobTitle}
                onChange={textChange}
              />
              {errors.jobTitle && (
                <span className="error-span">{errors.jobTitle}</span>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="inputText" className="form-label">
                Position
                <span className="label-required">*</span>
              </label>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={positionList}
                onChange={onChangePosition}
                size="small"
                disabled={dialogType === "JobDetails"}
                value={jobFields?.position}
                renderInput={(params) => (
                  <TextField {...params} label="Position" />
                )}
              />
              {errors.position && (
                <span className="error-span">{errors.position}</span>
              )}
            </div>
            <div className="col-md-6">
              <label className="form-label">
                Job Description <span className="label-required">*</span>
              </label>
              {dialogType === "JobDetails" ? (
                <div
                  className="border-style"
                  style={{
                    width: 538,
                    height: 100,
                    overflowX: "hidden",
                    overflowY: "auto",
                  }}
                >
                  <div
                    style={{
                      color: "rgb(186, 181, 181)",
                      fontWeight: "500",
                      fontSize: "0.9rem",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: jobFields?.jobDescription,
                    }}
                  ></div>
                </div>
              ) : (
                <ReactQuill
                  value={jobFields?.jobDescription}
                  onChange={handleJobDescriptionChange}
                  modules={{ toolbar: true }}
                  disabled={false}
                />
              )}
              {errors.jobDescription && (
                <span className="error-span">{errors.jobDescription}</span>
              )}
            </div>

            <div className="col-md-6">
              <label htmlFor="inputText" className="form-label">
                Qualification
              </label>
              <Autocomplete
                multiple
                options={qualificationList}
                size="small"
                onChange={onChangeQualification}
                value={jobFields?.qualification}
                disabled={dialogType === "JobDetails"}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField {...params} label="Select Qualification" />
                )}
              />
              <label htmlFor="inputText" className="form-label">
                Skills Required <span className="label-required">*</span>
              </label>
              <Autocomplete
                multiple
                id="multiple-limit-tags"
                options={keySkillsList}
                size="small"
                disabled={dialogType === "JobDetails"}
                onChange={onChangeKeySkills}
                value={jobFields?.keySkills}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField {...params} label="skills" />
                )}
              />
              {errors.keySkills && (
                <span className="error-span">{errors.keySkills}</span>
              )}
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="inputNumber" className="form-label">
                    Minimum Experience <span className="label-required">*</span>
                  </label>

                  <CustomTextField
                    name="minimumExperience"
                    label="  Minimum Experience"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled={dialogType === "JobDetails"}
                    InputProps={{ inputProps: { min: 0 } }}
                    type="Number"
                    value={jobFields?.minimumExperience}
                    onChange={textChange}
                  />
                  {errors.minimumExperience && (
                    <span className="error-span">
                      {errors.minimumExperience}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputNumber" className="form-label">
                    Maximum Experience <span className="label-required">*</span>
                  </label>

                  <CustomTextField
                    name="maximumExperience"
                    label="  Maximum Experience"
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{ inputProps: { min: 1 } }}
                    type="Number"
                    disabled={dialogType === "JobDetails"}
                    value={jobFields?.maximumExperience}
                    onChange={textChange}
                  />
                  {errors.maximumExperience && (
                    <span className="error-span">
                      {errors.maximumExperience}
                    </span>
                  )}
                </div>
              </div>
              {errors.minimumMaxExperience && (
                <span className="error-span">
                  {errors.minimumMaxExperience}
                </span>
              )}
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="inputText" className="form-label">
                    Minimum CTC in Lakhs
                  </label>
                  <CustomTextField
                    name="minimumAnnualSalary"
                    label="Minimum CTC in Lakhs"
                    variant="outlined"
                    size="small"
                    type="Number"
                    disabled={dialogType === "JobDetails"}
                    InputProps={{ inputProps: { min: 0 } }}
                    fullWidth
                    value={jobFields?.minimumAnnualSalary}
                    onChange={textChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputText" className="form-label">
                    Maximum CTC in Lakhs
                  </label>
                  <CustomTextField
                    name="maximumAnnualSalary"
                    label="  Maximum CTC in Lakhs"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled={dialogType === "JobDetails"}
                    InputProps={{ inputProps: { min: 1 } }}
                    type="Number"
                    value={jobFields?.maximumAnnualSalary}
                    onChange={textChange}
                  />
                </div>
              </div>
              {errors.minimumAnnualSalary && (
                <span className="error-span">{errors.minimumAnnualSalary}</span>
              )}
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="inputState" className="form-label">
                    Location <span className="label-required">*</span>
                  </label>
                  <Autocomplete
                    multiple
                    id="multiple-limit-tags"
                    options={locationList}
                    size="small"
                    onChange={onChageLocation}
                    value={jobFields?.location}
                    disabled={dialogType === "JobDetails"}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Location" />
                    )}
                  />
                  {errors.location && (
                    <span className="error-span">{errors.location}</span>
                  )}
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputState" className="form-label">
                    Work Mode
                  </label>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={WorkMode}
                    onChange={onChangeWorkMode}
                    size="small"
                    value={jobFields?.workMode}
                    disabled={dialogType === "JobDetails"}
                    renderInput={(params) => (
                      <TextField {...params} label="Work Mode" />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="inputState" className="form-label">
                    Time Shift
                  </label>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={timeShiftData}
                    onChange={onChageTimeShift}
                    size="small"
                    value={jobFields?.timeShift}
                    disabled={dialogType === "JobDetails"}
                    renderInput={(params) => (
                      <TextField {...params} label="Time Shift" />
                    )}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputState" className="form-label">
                    Job Type
                  </label>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={jobTypeData}
                    onChange={onChageJobType}
                    size="small"
                    disabled={dialogType === "JobDetails"}
                    value={
                      jobFields?.fullTime
                        ? "Full Time"
                        : jobFields?.partTime
                        ? "Part Time"
                        : "Any"
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Job Type" />
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12" style={{ marginTop: 20 }}>
              <div className="row">
                <div className="col-md-2 ">
                  <span
                    style={{
                      display: dialogType === "JobDetails" ? "none" : "",
                    }}
                  >
                    <CustomButton
                      color="secondary"
                      name="Clear"
                      onClick={() => setJobFields(initialStates)}
                      fullWidth
                    />
                  </span>
                </div>
                <div className="col-md-2 ">
                  <span
                    style={{
                      display:
                        dialogType === "EditJob" || dialogType === "JobDetails"
                          ? "none"
                          : "",
                    }}
                  >
                    <CustomButton
                      fullWidth
                      name="Save as draft"
                      onClick={() => addJob("draft")}
                    />
                  </span>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-2">
                  <span
                    style={{
                      display: dialogType === "JobDetails" ? "none" : "",
                    }}
                  >
                    <CustomButton
                      onClick={() => addJob("submitted")}
                      fullWidth
                      name="Submit"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default AddJob;
