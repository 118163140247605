import "../../../assets/styles/css/JobList.css";
import React, { useEffect, useState } from "react";
import Navbar from "../../../layouts//admin/Header/index";
import CustomTextField from "../../../components/customComponents/common/CustomTextField";
import CustomButton from "../../../components/customComponents/common/CustomButton";
import CustomDialog from "../../../components/customComponents/common/CustomDialog";

import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";

import ResumeView from "../JobsPage/AppliedCandidates/ResumeView";
import ApplicantDetails from "../JobsPage/AppliedCandidates/ApplicantDetails";
import {
  Autocomplete,
  FormControl,
  TextField,
  InputLabel,
  Container,
  Select,
  Typography,
  MenuItem,
} from "@mui/material";
import { CSVLink } from "react-csv";
import DriveFileMoveOutlinedIcon from "@mui/icons-material/DriveFileMoveOutlined";
import {
  postAPIHandler,
  getAPIHandler,
} from "../../../apiHandler/RestAPIHandler";
import API_URL_CONFIG from "../../../config/API_URL_CONFIG.json";

import { locationData, ExperienceDataInResumes } from "../../../CustomDatas";
import CustomSnackbar from "../../../components/customComponents/common/CustomSnackbar";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import { AllResumes, ShortListResumes, RejectedResumes } from "./ResumesTable";
import ListEmptyState from "../../../components/customComponents/common/CustomEmptyState";
import UploadResumes from "./UploadResumes";

/*Tab switch start*/
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};
/*Tab switch end */

const ApplicantList = () => {
  const PAGINATION_ROWS_PER_PAGE = 10;

  const [pageLimit, setPageLimit] = useState("");
  const [location, setLocation] = useState("");
  const [experience, setExperience] = useState("");
  const [excelList, setExcelList] = useState([]);

  const [searchValue, setSearchValue] = useState("");

  const [listLoader, setListLoader] = useState(false);
  const [pageNo, setPageNo] = useState(1);

  const [applicantList, setApplicantList] = useState([]);
  const [applicantListCount, setApplicantListCount] = useState("");

  const [toggle, setToggle] = useState(false);
  const [status, setStatus] = useState(0);
  const [dialogType, setDialogType] = useState("");
  const [resumeDetails, setResumeDetails] = useState();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [notificationData, setNotificationData] = useState({
    message: "",
    severity: "",
  });
  const [base64, setBase64] = useState();

  useEffect(() => {
    getCountAPI().then(() => {
      getListAPI(1);
    }); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setExcelList([]);
    getCountAPI().then(() => {
      getListAPI(1);
    }); // eslint-disable-next-line
  }, [searchValue]);

  useEffect(() => {
    setExcelList([]);
    getCountAPI().then(() => {
      getListAPI(1);
    }); // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    setExcelList([]);
    getCountAPI().then(() => {
      getListAPI(1);
    }); // eslint-disable-next-line
  }, [experience]);

  useEffect(() => {
    setExcelList([]);
    getCountAPI().then(() => {
      getListAPI(1);
    }); // eslint-disable-next-line
  }, [status]);
  useEffect(() => {
    getCountAPI().then(() => {
      getListAPI(1);
    }); // eslint-disable-next-line
  }, [pageLimit]);

  const buildAPIRequestBody = (isforList, pageNumber) => {
    let requestBodyObject = {
      filterCategory: "applicant",
      filterTemplate: "APPLICANT_FILTER_TEMPLATE_V1.json",
    };
    if (isforList) {
      requestBodyObject["sort"] = {
        sortFieldName: "created",
        sortType: "DESC",
      };
      requestBodyObject["range"] = {
        page: pageNumber,
        limit: PAGINATION_ROWS_PER_PAGE,
      };
    }
    if (pageLimit) {
      requestBodyObject["sort"] = {
        sortFieldName: "created",
        sortType: "DESC",
      };
      requestBodyObject["range"] = {
        page: pageNumber,
        limit: applicantListCount,
      };
    }

    let filterArray = [];
    if (searchValue && searchValue !== "") {
      filterArray.push({
        fieldKey: "jobTitle",
        filterValue: searchValue,
        fieldOperator: "",
        isLikeFilter: true,
      });
    }

    if (location && location !== "" && location.length != 0) {
      filterArray.push({
        fieldKey: "location",
        filterValue: location,
        fieldOperator: "IN",
        isLikeFilter: false,
      });
    }

    if (experience && experience === "Below 1 year") {
      filterArray.push({
        fieldKey: "experienceInYear",
        filterValue: 1,
        fieldOperator: "LESS_THAN",
        isLikeFilter: false,
      });
    }

    if (experience && experience === "1-2 years") {
      filterArray.push(
        {
          fieldKey: "experienceInYear",
          filterValue: 2,
          fieldOperator: "LESS_THAN",
          isLikeFilter: false,
        },
        {
          fieldKey: "experienceInYear",
          filterValue: 1,
          fieldOperator: "GRATER_THAN_EQUAL",
          isLikeFilter: false,
        }
      );
    }

    if (experience && experience === "2-5 years") {
      filterArray.push(
        {
          fieldKey: "experienceInYear",
          filterValue: 5,
          fieldOperator: "LESS_THAN",
          isLikeFilter: false,
        },
        {
          fieldKey: "experienceInYear",
          filterValue: 2,
          fieldOperator: "GRATER_THAN_EQUAL",
          isLikeFilter: false,
        }
      );
    }

    if (experience && experience === "5 years & above") {
      filterArray.push({
        fieldKey: "experienceInYear",
        filterValue: 5,
        fieldOperator: "GRATER_THAN_EQUAL",
        isLikeFilter: false,
      });
    }
    if (experience && experience === "10 years & above") {
      filterArray.push({
        fieldKey: "experienceInYear",
        filterValue: 10,
        fieldOperator: "GRATER_THAN_EQUAL",
        isLikeFilter: false,
      });
    }

    if (status && status === 0) {
      filterArray.push({
        fieldKey: "applicantStatus",
        filterValue: "applied",
        fieldOperator: "EQUAL",
        isLikeFilter: false,
      });
    }

    if (status && status === 1) {
      filterArray.push({
        fieldKey: "applicantStatus",
        filterValue: "shortlisted",
        fieldOperator: "EQUAL",
        isLikeFilter: false,
      });
    }
    if (status && status === 2) {
      filterArray.push({
        fieldKey: "applicantStatus",
        filterValue: "rejected",
        fieldOperator: "EQUAL",
        isLikeFilter: false,
      });
    }
    requestBodyObject["filter"] = filterArray;

    return requestBodyObject;
  };

  const getCountAPI = () => {
    let requestBodyObject = buildAPIRequestBody(false, null);
    return new Promise((resolve, reject) => {
      postAPIHandler(API_URL_CONFIG.APPLICANT_COUNT, requestBodyObject)
        .then((result) => {
          if (result.message === "Network Error") {
            setApplicantListCount(0);
            resolve();
          } else {
            setApplicantListCount(result?.response.data.data[0].count);

            resolve();
          }
        })
        .catch((err) => {
          setApplicantListCount(0);
          resolve();
        });
    });
  };

  const getListAPI = (pageNumber) => {
    setListLoader(true);
    let requestBodyObject = buildAPIRequestBody(true, pageNumber);
    const URL = API_URL_CONFIG.LIST_JOB_APPLICANT;
    postAPIHandler(URL, requestBodyObject)
      .then((result) => {
        setListLoader(false);
        if (result.response.status === 401) {
          setOpenSnackbar(true);
          setNotificationData({
            message: "Session Expired, Please login",
            severity: "error",
          });
          setTimeout(() => {
            window.location.replace("/admin-console-login");
            localStorage.clear();
          }, 1000);
        } else if (result.message === "Network Error") {
          setApplicantList([]);
        } else {
          if (pageLimit) {
            setExcelList(result?.response?.data?.data);
            setPageLimit("");
          } else {
            setApplicantList(result?.response?.data?.data);
          }
        }
      })
      .catch((err) => {
        setListLoader(false);
        setApplicantList([]);
      });
  };

  const onChageLocation = (event, value) => {
    setLocation(value);
  };
  const onChangeExperience = (event, value) => {
    setExperience(value);
  };

  const paginationChange = (event, page) => {
    getListAPI(page);
    setPageNo(page);
  };

  const textChange = (event) => {
    setSearchValue(event?.target?.value);
  };
  const OpenDialog = (e, item) => {
    setDialogType(e);
    setResumeDetails(item);
    viewResume(item.documentId);
  };
  const handleChangeTab = (event, newValue) => {
    setStatus(event.target.value);
    //setPageNo(1);
  };
  const handleClose = () => {
    setOpenSnackbar(false);
  };
  const handleCloseButton = () => {
    setDialogType("");
    getListAPI();
  };

  const viewResume = (documentId) => {
    getAPIHandler(API_URL_CONFIG.GET_RESUMES + "?_id=" + documentId, {
      responseType: "arraybuffer",
    }).then((result) => {
      setBase64(result.response.data.data.documentData);
    });
  };
  return (
    <>
      <Navbar />

      {/* search */}
      <div className="search-cont">
        <div className="container">
          <form className="d-flex">
            <CustomTextField
              name="searchValue"
              label="Search Job Title"
              variant="standard"
              margin="normal"
              size="medium"
              autoFocus
              fullWidth
              type="search"
              onChange={textChange}
            />

            <div className="dropdown">
              <FormControl variant="standard" sx={{ m: 2, minWidth: 300 }}>
                <Autocomplete
                  multiple
                  options={locationData}
                  getOptionLabel={(option) => option}
                  id="clear-on-escape"
                  clearOnEscape
                  onChange={onChageLocation}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=" Location"
                      variant="standard"
                    />
                  )}
                />
              </FormControl>
            </div>

            <div className="dropdown">
              <FormControl variant="standard" sx={{ m: 2, minWidth: 250 }}>
                <Autocomplete
                  options={ExperienceDataInResumes}
                  getOptionLabel={(option) => option}
                  id="clear-on-escape"
                  clearOnEscape
                  onChange={onChangeExperience}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Experience"
                      variant="standard"
                    />
                  )}
                />
              </FormControl>
            </div>
          </form>
        </div>
      </div>
      <div>
        <CustomSnackbar
          openSnackbar={openSnackbar}
          message={notificationData?.message}
          handleClose={handleClose}
          severity={notificationData?.severity}
        />
        {/* all job list  */}
        <Container maxWidth="xl">
          <div className="row justify-content-between">
            <div style={{ paddingLeft: 34, marginTop: 15 }} className="col-3">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={status}
                  label="Option"
                  size="small"
                  onChange={handleChangeTab}
                >
                  <MenuItem
                    {...a11yProps(0)}
                    value={0}
                    onClick={() => setToggle(false)}
                  >
                    All Resumes
                  </MenuItem>
                  <MenuItem
                    {...a11yProps(1)}
                    value={1}
                    onClick={() => setToggle(false)}
                  >
                    Shorlisted Resumes
                  </MenuItem>
                  <MenuItem
                    {...a11yProps(2)}
                    value={2}
                    onClick={() => setToggle(false)}
                  >
                    Rejected Resumes
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="row-link-container">
            <div className="upload-resume">
              <a
                className="upload-resume-container"
                style={{
                  color: "rgba(54, 171, 155)",
                  cursor: "pointer",
                }}
                onClick={() => OpenDialog("UploadResume")}
              >
                Upload Resume
              </a>
              <DriveFolderUploadIcon
                style={{
                  color: "#36ab9b",
                  cursor: "pointer",
                  fontSize: 25,
                  marginLeft: 5,
                }}
              />
            </div>
            {excelList.length != 0 || applicantList.length != 0 ? (
              <div className="export-excel">
                {excelList.length != 0 ? (
                  <CSVLink
                    className="export-excel-csv"
                    data={excelList}
                    filename={"applicants.csv"}
                  >
                    Click Here to Download
                  </CSVLink>
                ) : (
                  <a
                    className="upload-resume-container"
                    style={{
                      color: "rgba(54, 171, 155)",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setPageLimit(applicantListCount);
                    }}
                  >
                    Export to Excel
                  </a>
                )}
                <DriveFileMoveOutlinedIcon
                  style={{
                    color: "#36ab9b",
                    cursor: "pointer",
                    fontSize: 25,
                    marginLeft: 5,
                  }}
                />
              </div>
            ) : (
              ""
            )}
          </div>
          <TabPanel value={status} index={0}>
            <AllResumes
              applicantList={applicantList}
              ListEmptyState={ListEmptyState}
              listLoader={listLoader}
              OpenDialog={OpenDialog}
              CustomButton={CustomButton}
              pageNo={pageNo}
              paginationChange={paginationChange}
              applicantListCount={applicantListCount}
              toggle={toggle}
              setToggle={setToggle}
            />
          </TabPanel>
          <TabPanel value={status} index={1}>
            <ShortListResumes
              applicantList={applicantList}
              ListEmptyState={ListEmptyState}
              listLoader={listLoader}
              OpenDialog={OpenDialog}
              CustomButton={CustomButton}
              pageNo={pageNo}
              paginationChange={paginationChange}
              toggle={toggle}
              setToggle={setToggle}
              applicantListCount={applicantListCount}
            />
          </TabPanel>
          <TabPanel value={status} index={2}>
            <RejectedResumes
              applicantList={applicantList}
              ListEmptyState={ListEmptyState}
              listLoader={listLoader}
              OpenDialog={OpenDialog}
              CustomButton={CustomButton}
              pageNo={pageNo}
              paginationChange={paginationChange}
              applicantListCount={applicantListCount}
              toggle={toggle}
              setToggle={setToggle}
            />
          </TabPanel>
        </Container>
      </div>
      {/*Resume user Dialog */}
      <CustomDialog
        openDialog={dialogType === "ReadResume"}
        dialogTitle="View Attachment"
        handleCloseButton={handleCloseButton}
        component={
          <ResumeView
            getApplicantList={resumeDetails}
            handleCloseButton={handleCloseButton}
            base64={base64}
          />
        }
      ></CustomDialog>

      <CustomDialog
        openDialog={dialogType === "AppliedCandidatesPage"}
        dialogTitle="Candidate Details"
        handleCloseButton={handleCloseButton}
        component={
          <ApplicantDetails
            dialogType={dialogType}
            handleCloseButton={handleCloseButton}
            userDetails={resumeDetails}
            getApplicantList={resumeDetails}
          />
        }
      ></CustomDialog>

      <CustomDialog
        openDialog={dialogType === "UploadResume"}
        dialogTitle="Upload Candidate Details"
        handleCloseButton={handleCloseButton}
        component={
          <UploadResumes
            dialogType={dialogType}
            handleCloseButton={handleCloseButton}
          />
        }
      ></CustomDialog>
    </>
  );
};
export default ApplicantList;
