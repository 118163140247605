import { createTheme } from "@mui/material/styles";

export const Colors = {
  primaryColor: "rgba(54, 171, 155)",
  secondaryColor: "#656d6b",
  white: "rgba(255,255,255,1)",
  black: "#000000",
  dark: "rgba(100,100,100,1)",
  default: "#a9a9a9",
  success: "#4caf50",
  info: "#2196f3",
  error: "#b61827",
  warning: "#ff9800",
};
export const ParentContainer = {
  background: Colors.white,
  height: "100%",
};

export const theme = createTheme({
  palette: {
    primary: {
      main: Colors.primaryColor,
    },
    secondary: {
      main: Colors.secondaryColor,
    },
    white: {
      main: "#FFFFFF",
    },
    error: {
      main: Colors.error,
    },
    warning: {
      main: "#F88808",
    },
    textPrimary: {
      main: Colors.black,
    },
  },
  typography: {
    h5: {
      fontWeight: "500",
      fontSize: "1.25rem",
      margin: 10,
    },
    //Top Headding
    h3: {
      fontWeight: "500",
      fontSize: "1.50rem",
    },

    h6: { fontWeight: "600" },

    subtitle1: { fontWeight: "500", fontSize: "1.05rem" },
    //Table Head
    caption: {
      fontWeight: "600",
      fontSize: "1.05rem",
    },

    subtitle2: { fontWeight: "400", fontSize: "1.05rem" },
    body1: { fontWeight: "500", fontSize: "0.9rem" },

    body2: { fontWeight: "400", fontSize: "0.9rem" },

    overline: {
      fontWeight: "400",
      fontSize: "0.9rem",
      textTransform: "none",
    },
  },
});
