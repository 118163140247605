const validation = (inputValue) => {
  let errors = { error: false };

  if (!inputValue) {
    errors.inputValue = "Please enter comments";
    errors.error = true;
  }

  return errors;
};

export default validation;
