import React from "react";
import "../../../assets/styles/css/PageNotFound.css";
import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div id="page-top" style={{ height: "100vh" }}>
      <div id="wrapper">
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <div className="container w-height ">
              <h2>Oops! Page not found.</h2>
              <p>we can't find the page you're looking for.</p>
              <Link to="/" className="nav-link active">
                <HomeIcon fontSize="medium" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PageNotFound;
