import React, { useState, useEffect } from "react";
import "../../../assets/styles/css/JobsPage.css";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CustomDialog from "../../../components/customComponents/common/CustomDialog";
import CustomButton from "../../../components/customComponents/common/CustomButton";
import API_URL_CONFIG from "../../../config/API_URL_CONFIG.json";
import { postAPIHandler } from "../../../apiHandler/RestAPIHandler";
import DeleteJob from "./DeleteJob";
import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import AdminHeader from "../../../layouts/admin/Header";
import PostJob from "./PostJob";
import AddJob from "./AddAndEditJob";
import ShareJob from "./ShareJob";
import PriorityJob from "./PriorityJob";
import ListEmptyState from "../../../components/customComponents/common/CustomEmptyState";
import {
  ActiveJobs,
  ClosedJobs,
  DraftedJobs,
  SubmittedJobs,
} from "./JobsTables";
import CloseJob from "./CloseJob";
import AppliedCandidates from "./AppliedCandidates";
import { useLocation } from "react-router-dom";
import LoginVerify from "../../../components/utils/LoginVerify";
import CustomSnackbar from "../../../components/customComponents/common/CustomSnackbar";

/*Tab switch start*/
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};
/*Tab switch end */

const JobsPage = (props) => {
  const location = useLocation();

  const tabValue = location?.state?.tab;

  const [dialogType, setDialogType] = useState("");
  const [jobList, setJobList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [listLoader, setListLoader] = useState(false);
  const [priority, setPriority] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [value, setValue] = useState(0);
  const [jobDetails, setJobDetails] = useState();
  const [validationTrigger, setValidationTrigger] = useState(false);
  const [jobListCount, setJobListCount] = useState("");
  const PAGINATION_ROWS_PER_PAGE = 5;
  const [repost, setRepost] = useState("");
  const [userPrivileges, setUserPrivileges] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [notificationData, setNotificationData] = useState({
    message: "",
    severity: "",
  });
  useEffect(() => {
    LoginVerify();

    getCountAPI().then(() => {
      getJobList(1);
    }); // eslint-disable-next-line
  }, [value, toggle]);

  useEffect(() => {
    const privileges = localStorage.getItem("user_privileges");
    setUserPrivileges(privileges);
  });

  useEffect(() => {
    if (tabValue === "active") setValue(2); // eslint-disable-next-line
  }, []);

  const buildAPIRequestBody = (isforList, pageNumber) => {
    let requestBodyObject = {
      filterCategory: "jobs",
      filterTemplate: "JOBS_FILTER_TEMPLATE_V1.json",
    };
    if (isforList) {
      requestBodyObject["sort"] = {
        sortFieldName: "created",
        sortType: "DESC",
      };
      requestBodyObject["range"] = {
        page: pageNumber,
        limit: PAGINATION_ROWS_PER_PAGE,
      };
    }
    let filterArray = [];
    let jobStatus;
    let fieldOperator;
    let fieldKey;
    if (value === 0) {
      jobStatus = "draft";
      fieldKey = "status";
      fieldOperator = "EQUAL";
    } else if (value === 1) {
      jobStatus = "submitted";
      fieldOperator = "EQUAL";
      fieldKey = "status";
    } else if (value === 2) {
      jobStatus = "active";
      fieldOperator = "EQUAL";
      fieldKey = "status";
    } else {
      fieldKey = "status";
      jobStatus = "closed";
      fieldOperator = "EQUAL";
    }

    if (toggle) {
      fieldKey = "isPrioritized";
      jobStatus = true;
      fieldOperator = "EQUAL";
    }

    filterArray.push({
      fieldKey: fieldKey,
      filterValue: jobStatus,
      fieldOperator: fieldOperator,
      isLikeFilter: false,
    });

    requestBodyObject["filter"] = filterArray;

    return requestBodyObject;
  };
  const getCountAPI = () => {
    let requestData = buildAPIRequestBody(false, null);
    return new Promise((resolve, reject) => {
      postAPIHandler(API_URL_CONFIG.COUNT_LIST_JOB_AUTH, requestData)
        .then((result) => {
          if (result.message === "Network Error") {
            setJobListCount(0);
            resolve();
          } else {
            setJobListCount(result?.response.data.data[0].count);
            resolve();
          }
        })
        .catch((err) => {
          setJobListCount(0);
          resolve();
        });
    });
  };
  const getJobList = (pageNo = 1) => {
    let requestData = buildAPIRequestBody(true, pageNo);
    setListLoader(true);
    postAPIHandler(API_URL_CONFIG.LIST_JOB_AUTH, requestData)
      .then((result) => {
        setListLoader(false);
        if (result.message === "Network Error") {
          setJobList([]);
        } else if (result.response.status === 401) {
          setOpenSnackbar(true);
          setNotificationData({
            message: "Session Expired, Please login",
            severity: "error",
          });
          setTimeout(() => {
            window.location.replace("/admin-console-login");
            localStorage.clear();
          }, 1000);
        } else {
          setJobList(result?.response?.data?.data);
        }
      })
      .catch((err) => {
        setListLoader(false);
        setJobList([]);
      });
  };
  const handleChangeTab = (event, newValue) => {
    setValue(event.target.value);
    setPageNo(1);
  };

  const OpenDialog = (e, item, priorityStatus, repostJob) => {
    setDialogType(e);
    setJobDetails(item);
    setPriority(priorityStatus);
    setRepost(repostJob);
  };

  const handleCloseButton = () => {
    setDialogType("");
    setValidationTrigger(false);
  };

  const paginationChange = (event, pageNo) => {
    setPageNo(pageNo);
    getJobList(pageNo);
  };
  const handleClose = () => {
    setOpenSnackbar(false);
  };
  return (
    <div>
      <AdminHeader />
      {/* Custom notification snackbar */}
      <CustomSnackbar
        openSnackbar={openSnackbar}
        message={notificationData?.message}
        handleClose={handleClose}
        severity={notificationData?.severity}
      />
      {/* Drafted job list start  */}
      <Container maxWidth="xl">
        <div
          style={{
            paddingTop: 30,
            textAlign: "center",
          }}
        >
          <div className="row justify-content-between">
            <div style={{ paddingLeft: 34 }} className="col-3">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={value}
                  label="Option"
                  size="small"
                  onChange={handleChangeTab}
                >
                  {userPrivileges.includes("JOBS_DRAFT_TABLE") ? (
                    <MenuItem
                      {...a11yProps(0)}
                      value={0}
                      onClick={() => setToggle(false)}
                    >
                      Draft Jobs
                    </MenuItem>
                  ) : (
                    <></>
                  )}
                  {userPrivileges.includes("JOBS_SUBMITTED_TABLE") ? (
                    <MenuItem
                      {...a11yProps(1)}
                      value={1}
                      onClick={() => setToggle(false)}
                    >
                      Submitted Jobs
                    </MenuItem>
                  ) : (
                    <></>
                  )}
                  {userPrivileges.includes("JOBS_ACTIVE_TABLE") ? (
                    <MenuItem
                      {...a11yProps(1)}
                      value={2}
                      onClick={() => setToggle(false)}
                    >
                      Active Jobs
                    </MenuItem>
                  ) : (
                    <></>
                  )}
                  {userPrivileges.includes("JOBS_CLOSED_TABLE") ? (
                    <MenuItem
                      {...a11yProps(1)}
                      value={3}
                      onClick={() => setToggle(false)}
                    >
                      Closed Jobs
                    </MenuItem>
                  ) : (
                    <></>
                  )}
                </Select>
              </FormControl>
            </div>
            {userPrivileges.includes("JOBS_POST_BUTTON") ? (
              <div className="col-2">
                <CustomButton
                  name="Post a Job"
                  onClick={() => OpenDialog("AddJob")}
                />
              </div>
            ) : (
              ""
            )}
          </div>
          {userPrivileges.includes("JOBS_DRAFT_TABLE") ? (
            <TabPanel value={value} index={0}>
              <DraftedJobs
                listLoader={listLoader}
                jobList={jobList}
                OpenDialog={OpenDialog}
                ListEmptyState={ListEmptyState}
              />
            </TabPanel>
          ) : (
            ""
          )}

          {userPrivileges.includes("JOBS_SUBMITTED_TABLE") ? (
            <TabPanel value={value} index={1}>
              <SubmittedJobs
                listLoader={listLoader}
                jobList={jobList}
                OpenDialog={OpenDialog}
                ListEmptyState={ListEmptyState}
                CustomButton={CustomButton}
                pageNo={pageNo}
                paginationChange={paginationChange}
                jobListCount={jobListCount}
              />
            </TabPanel>
          ) : (
            ""
          )}

          {userPrivileges.includes("JOBS_ACTIVE_TABLE") ? (
            <TabPanel value={value} index={2}>
              <ActiveJobs
                listLoader={listLoader}
                jobList={jobList}
                OpenDialog={OpenDialog}
                ListEmptyState={ListEmptyState}
                CustomButton={CustomButton}
                pageNo={pageNo}
                paginationChange={paginationChange}
                toggle={toggle}
                setToggle={setToggle}
                jobListCount={jobListCount}
                userPrivileges={userPrivileges}
              />
            </TabPanel>
          ) : (
            ""
          )}
          {userPrivileges.includes("JOBS_CLOSED_TABLE") ? (
            <TabPanel value={value} index={3}>
              <ClosedJobs
                listLoader={listLoader}
                jobList={jobList}
                OpenDialog={OpenDialog}
                ListEmptyState={ListEmptyState}
                CustomButton={CustomButton}
                pageNo={pageNo}
                paginationChange={paginationChange}
                jobListCount={jobListCount}
                userPrivileges={userPrivileges}
              />
            </TabPanel>
          ) : (
            ""
          )}
        </div>

        {/*Create and Edit Dialog */}
        <CustomDialog
          openDialog={
            dialogType === "AddJob" ||
            dialogType === "EditJob" ||
            dialogType === "JobDetails"
          }
          dialogTitle={
            dialogType === "AddJob"
              ? "Add Job"
              : dialogType === "JobDetails"
              ? "Job Details"
              : "Edit Job"
          }
          handleCloseButton={handleCloseButton}
          component={
            <AddJob
              validationTrigger={validationTrigger}
              setValidationTrigger={setValidationTrigger}
              dialogType={dialogType}
              handleCloseButton={handleCloseButton}
              jobDetails={jobDetails}
              getJobList={getJobList}
              repost={repost}
            />
          }
        ></CustomDialog>
        {/*Delete Dialog */}
        <CustomDialog
          openDialog={dialogType === "DeleteJob"}
          dialogTitle="Delete Job"
          handleCloseButton={handleCloseButton}
          component={
            <DeleteJob
              getJobList={getJobList}
              handleCloseButton={handleCloseButton}
              jobDetails={jobDetails}
            />
          }
        ></CustomDialog>
        {/*Post job Dialog */}

        <CustomDialog
          openDialog={dialogType === "PostJob"}
          dialogTitle="Post Job"
          handleCloseButton={handleCloseButton}
          component={
            <PostJob
              getJobList={getJobList}
              handleCloseButton={handleCloseButton}
              jobDetails={jobDetails}
            />
          }
        ></CustomDialog>
        {/*  Priority Job  Dialog */}

        <CustomDialog
          openDialog={dialogType === "PriorityJob"}
          dialogTitle="Priority"
          handleCloseButton={handleCloseButton}
          component={
            <PriorityJob
              getJobList={getJobList}
              handleCloseButton={handleCloseButton}
              jobDetails={jobDetails}
              priority={priority}
            />
          }
        ></CustomDialog>
        {/* CloseJob dialog */}
        <CustomDialog
          openDialog={dialogType === "CloseJob"}
          dialogTitle="Close a Job"
          handleCloseButton={handleCloseButton}
          component={
            <CloseJob
              getJobList={getJobList}
              handleCloseButton={handleCloseButton}
              jobDetails={jobDetails}
            />
          }
        ></CustomDialog>
        {/* Applied candidates dialog */}
        <CustomDialog
          openDialog={dialogType === "AppliedCandidates"}
          dialogTitle={
            "Applied Candidates - " +
            jobDetails?.jobTitle.charAt(0).toUpperCase() +
            jobDetails?.jobTitle.slice(1)
          }
          handleCloseButton={handleCloseButton}
          component={
            <AppliedCandidates
              userPrivileges={userPrivileges}
              handleCloseButton={handleCloseButton}
              jobDetails={jobDetails}
            />
          }
        ></CustomDialog>
        {/* Share jobs dialog */}
        <CustomDialog
          openDialog={dialogType === "ShareJobPost"}
          dialogTitle="Share Post"
          handleCloseButton={handleCloseButton}
          component={<ShareJob jobDetails={jobDetails} />}
        ></CustomDialog>
      </Container>
    </div>
  );
};
export default JobsPage;
