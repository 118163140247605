import "../../../assets/styles/css/JobList.css";
import React, { useEffect, useState } from "react";
import Navbar from "../../../layouts/Header/Navbar";
import Footer from "../../../layouts/Footer";
import CustomTextField from "../../../components/customComponents/common/CustomTextField";
import CustomButton from "../../../components/customComponents/common/CustomButton";
import {
  Autocomplete,
  FormControl,
  Pagination,
  TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
import {
  postNoAuthAPIHandler,
  getAPIHandler,
} from "../../../apiHandler/RestAPIHandler";
import API_URL_CONFIG from "../../../config/API_URL_CONFIG.json";
import {
  JobListSkeletonPublic,
  PaginationSkeleton,
} from "../../../components/Skeleton/public";
import {
  ExperienceData,
  locationData,
  PositionData,
} from "../../../CustomDatas";
import TopBar from "../../../layouts/Header/TopBar";
const JobList = () => {
  const [location, setLocation] = useState("");
  const [position, setPosition] = useState("");
  const [experience, setExperience] = useState({
    minimumExperience: null,
    maximumExperience: null,
  });
  const [searchValue, setSearchValue] = useState("");

  const [jobList, setJobList] = useState([]);
  const [listLoader, setListLoader] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [jobListCount, setJobListCount] = useState("");
  const [locationList, setLocationList] = useState([]);
  const [positionList, setPositionList] = useState([]);

  const PAGINATION_ROWS_PER_PAGE = 5;

  useEffect(() => {
    getLocation();
    getPosition();
  }, []);

  useEffect(() => {
    getCountAPI().then(() => {
      getListAPI(1);
    }); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getCountAPI().then(() => {
      getListAPI(1);
    }); // eslint-disable-next-line
  }, [searchValue]);

  useEffect(() => {
    getCountAPI().then(() => {
      getListAPI(1);
    }); // eslint-disable-next-line
  }, [position]);

  useEffect(() => {
    getCountAPI().then(() => {
      getListAPI(1);
    }); // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    getCountAPI().then(() => {
      getListAPI(1);
    }); // eslint-disable-next-line
  }, [experience]);

  const getLocation = () => {
    getAPIHandler(API_URL_CONFIG.LOCATION_LIST, {
      responseType: "arraybuffer",
    }).then((result) => {
      const locationArray = result.response.data.data.map(
        (item) => item.location
      );
      setLocationList(locationArray);
    });
  };

  const getPosition = () => {
    getAPIHandler(API_URL_CONFIG.POSITION_LIST, {
      responseType: "arraybuffer",
    }).then((result) => {
      const positionArray = result.response.data.data.map(
        (item) => item.position
      );
      setPositionList(positionArray);
    });
  };

  const buildAPIRequestBody = (isforList, pageNumber) => {
    let requestBodyObject = {
      filterCategory: "jobs",
      filterTemplate: "JOBS_FILTER_TEMPLATE_V1.json",
    };
    if (isforList) {
      requestBodyObject["sort"] = {
        sortFieldName: "created",
        sortType: "DESC",
      };
      requestBodyObject["range"] = {
        page: pageNumber,
        limit: PAGINATION_ROWS_PER_PAGE,
      };
    }

    let filterArray = [
      {
        fieldKey: "status",
        filterValue: "active",
        fieldOperator: "EQUAL",
        isLikeFilter: false,
      },
    ];
    if (searchValue && searchValue !== "") {
      filterArray.push({
        fieldKey: "jobTitle",
        filterValue: searchValue,
        fieldOperator: "",
        isLikeFilter: true,
      });
    }
    if (position && position !== "") {
      filterArray.push({
        fieldKey: "position",
        filterValue: position,
        fieldOperator: "EQUAL",
        isLikeFilter: false,
      });
    }
    if (location && location !== "") {
      filterArray.push({
        fieldKey: "location",
        filterValue: location,
        fieldOperator: "EQUAL",
        isLikeFilter: false,
      });
    }

    if (experience && experience === "1 and below") {
      filterArray.push({
        fieldKey: "maximumExperience",
        filterValue: 1,
        fieldOperator: "LESS_THAN_EQUAL",
        isLikeFilter: false,
      });
    }

    if (experience && experience === "2 and below") {
      filterArray.push(
        {
          fieldKey: "maximumExperience",
          filterValue: 2,
          fieldOperator: "LESS_THAN_EQUAL",
          isLikeFilter: false,
        },
        {
          fieldKey: "minimumExperience",
          filterValue: 0,
          fieldOperator: "GRATER_THAN_EQUAL",
          isLikeFilter: false,
        }
      );
    }

    if (experience && experience === "5 and below") {
      filterArray.push(
        {
          fieldKey: "maximumExperience",
          filterValue: 5,
          fieldOperator: "LESS_THAN_EQUAL",
          isLikeFilter: false,
        },
        {
          fieldKey: "minimumExperience",
          filterValue: 0,
          fieldOperator: "GRATER_THAN_EQUAL",
          isLikeFilter: false,
        }
      );
    }

    if (experience && experience === "10 and below") {
      filterArray.push(
        {
          fieldKey: "minimumExperience",
          filterValue: 0,
          fieldOperator: "GRATER_THAN_EQUAL",
          isLikeFilter: false,
        },
        {
          fieldKey: "maximumExperience",
          filterValue: 10,
          fieldOperator: "LESS_THAN_EQUAL",
          isLikeFilter: false,
        }
      );
    }

    requestBodyObject["filter"] = filterArray;

    return requestBodyObject;
  };

  const getCountAPI = () => {
    let requestBodyObject = buildAPIRequestBody(false, null);
    return new Promise((resolve, reject) => {
      postNoAuthAPIHandler(API_URL_CONFIG.GET_LIST_COUNT, requestBodyObject)
        .then((result) => {
          if (result.message === "Network Error") {
            setJobListCount(0);
            resolve();
          } else {
            setJobListCount(result?.response.data.data[0].count);
            resolve();
          }
        })
        .catch((err) => {
          setJobListCount(0);
          resolve();
        });
    });
  };

  const getListAPI = (pageNumber) => {
    setListLoader(true);
    let requestBodyObject = buildAPIRequestBody(true, pageNumber);
    postNoAuthAPIHandler(API_URL_CONFIG.LIST_JOB, requestBodyObject)
      .then((result) => {
        setListLoader(false);
        if (result.message === "Network Error") {
          setJobList([]);
        } else {
          setJobList(result?.response?.data?.data);
        }
      })
      .catch((err) => {
        setListLoader(false);
        setJobList([]);
      });
  };

  const onChangePosition = (event, value) => {
    setPosition(value);
  };
  const onChageLocation = (event, value) => {
    setLocation(value);
  };
  const onChangeExperience = (event, value) => {
    setExperience(value);
  };

  const paginationChange = (event, page) => {
    getListAPI(page);
    setPageNo(page);
  };

  const textChange = (event) => {
    setSearchValue(event?.target?.value);
  };
  return (
    <>
      <TopBar />
      <div className="job-list-hero-image">
        <div className="container">
          <div className="row">
            <Navbar />
          </div>
        </div>
      </div>
      {/* search */}
      <div className="search-cont">
        <div className="container">
          <form className="d-flex">
            <CustomTextField
              name="searchValue"
              label="Search for Jobs"
              variant="standard"
              margin="normal"
              size="medium"
              autoFocus
              fullWidth
              type="search"
              onChange={textChange}
            />

            <div className="dropdown ">
              <FormControl variant="standard" sx={{ m: 2, minWidth: 280 }}>
                <Autocomplete
                  options={positionList}
                  getOptionLabel={(option) => option}
                  id="clear-on-escape"
                  clearOnEscape
                  onChange={onChangePosition}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Position"
                      variant="standard"
                    />
                  )}
                />
              </FormControl>
            </div>

            <div className="dropdown">
              <FormControl variant="standard" sx={{ m: 2, minWidth: 180 }}>
                <Autocomplete
                  options={locationList}
                  getOptionLabel={(option) => option}
                  id="clear-on-escape"
                  clearOnEscape
                  onChange={onChageLocation}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=" Location"
                      variant="standard"
                    />
                  )}
                />
              </FormControl>
            </div>

            <div className="dropdown">
              <FormControl variant="standard" sx={{ m: 2, minWidth: 180 }}>
                <Autocomplete
                  options={ExperienceData}
                  getOptionLabel={(option) => option}
                  id="clear-on-escape"
                  clearOnEscape
                  onChange={onChangeExperience}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Experience"
                      variant="standard"
                    />
                  )}
                />
              </FormControl>
            </div>
          </form>
        </div>
      </div>
      {listLoader ? (
        <JobListSkeletonPublic />
      ) : jobList ? (
        jobList.length ? (
          jobList.map((item, i) => {
            return (
              <div key={i} className="job-listing">
                <div className="container">
                  <div className="job-list-seprate">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="job-list-left">
                            <h3>{item.jobTitle}</h3>
                            <p className="card-text">
                              Exp: {item.minimumExperience}-
                              {item.maximumExperience} Yrs
                            </p>
                            <p>
                              Work Type:
                              {item.partTime === true
                                ? "Part Time"
                                : item.fullTime === true
                                ? "FullTime"
                                : "Any"}
                            </p>
                            <p>Location: {item.location}</p>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="job-list-right">
                            <Link
                              style={{ textDecoration: "none" }}
                              to={`/job-description/${item._id}`}
                            >
                              <CustomButton size="small" name="More Info" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="empty-list">
            <p>No jobs posted yet</p>
          </div>
        )
      ) : (
        <div className="empty-list">
          <p>No jobs posted yet</p>
        </div>
      )}
      {listLoader ? (
        <PaginationSkeleton />
      ) : jobList ? (
        jobListCount > 5 ? (
          <div className="container">
            <div className="job-list-pagination">
              <Pagination
                count={jobListCount < 5 ? 1 : Math.ceil(jobListCount / 5)}
                onChange={paginationChange}
                page={pageNo}
                color="primary"
              />
            </div>
          </div>
        ) : (
          <div style={{ marginBottom: 20 }} />
        )
      ) : (
        ""
      )}
      <Footer />
    </>
  );
};
export default JobList;
