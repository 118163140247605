export const ListEmptyState = ({ emptyState }) => {
  return (
    <>
      <tbody>
        <tr>
          <td className="jobs-empty-state" colSpan="12">
            {emptyState}
          </td>
        </tr>
      </tbody>
    </>
  );
};
export default ListEmptyState;
