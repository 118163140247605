import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import logo from "../../../assets/images/indexPage/logo.png";
import CustomDialog from "../../../components/customComponents/common/CustomDialog";
import FieldSettings from "./FieldSettings";

import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

const SuperAdminHeader = () => {
  let navigate = useNavigate();

  const [profileOptions, setProfileOptions] = useState(null);
  const [dialogType, setDialogType] = useState("");

  const open = Boolean(profileOptions);
  const handleClick = (event) => {
    setProfileOptions(event.currentTarget);
  };
  const handleClose = () => {
    setProfileOptions(null);
  };
  const logOut = () => {
    localStorage.clear();
    navigate("/admin-console-login");
  };
  const OpenDialog = (e, item) => {
    setDialogType(e);
  };
  const handleCloseButton = () => {
    setDialogType("");
  };

  return (
    <>
      <div
        className="navbar navbar-expand navbar-light bg-white topbar mb-6 static-top shadow"
        style={{ height: 80 }}
      >
        <div className="container">
          <Link
            to="/super-admin-dashboard"
            className="sidebar-brand d-flex align-items-center "
            style={{ marginLeft: 20 }}
          >
            <img src={logo} alt="saasvaap" height="36" />
          </Link>

          <div>
            <Link
              className="sidebar-brand d-flex align-items-center "
              style={{
                marginLeft: 300,
                marginTop: 10,
                color: "rgba(54, 171, 155)",
                textDecoration: "none",
                fontWeight: 700,
              }}
              to="/requests"
            >
              <p>Requests</p>
            </Link>
          </div>
          <div>
            <Link
              className="sidebar-brand d-flex align-items-center "
              style={{
                marginLeft: 50,
                marginTop: 10,
                color: "rgba(54, 171, 155)",
                textDecoration: "none",
                fontWeight: 700,
              }}
              to="/roles"
            >
              <p>Roles</p>
            </Link>
          </div>
          <div>
            <a
              className="sidebar-brand d-flex align-items-center "
              style={{
                marginLeft: 50,
                marginTop: -5,
                color: "rgba(54, 171, 155)",
                textDecoration: "none",
                fontWeight: 700,
              }}
              onClick={() => OpenDialog("fieldSettings")}
            >
              Field Settings
            </a>
          </div>
          <ul className="navbar-nav ml-auto">
            <div>
              <AccountCircleOutlinedIcon
                id="basic-button"
                fontSize="large"
                color="primary"
                onClick={handleClick}
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              />
              <Menu
                id="basic-menu"
                anchorEl={profileOptions}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={logOut}>Logout</MenuItem>
              </Menu>
            </div>
          </ul>
        </div>
        <CustomDialog
          openDialog={dialogType === "fieldSettings"}
          dialogTitle="Field Settings"
          handleCloseButton={handleCloseButton}
          component={
            <FieldSettings
              dialogType={dialogType}
              handleCloseButton={handleCloseButton}
            />
          }
        ></CustomDialog>
      </div>
    </>
  );
};
export default SuperAdminHeader;
