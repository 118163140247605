import React, { useEffect, useState } from "react";
import "../../../../assets/styles/css/LoginPage.css";
import CustomButton from "../../../../components/customComponents/common/CustomButton";
import CustomTextField from "../../../../components/customComponents/common/CustomTextField";
import API_CONFIG_VAR from "../../../../config/API_URL_CONFIG.json";
import { useNavigate } from "react-router-dom";
import { postNoAuthAPIHandler } from "../../../../apiHandler/RestAPIHandler";
import { Grid, InputAdornment } from "@mui/material";
import validation from "./validation";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CustomSnackbar from "../../../../components/customComponents/common/CustomSnackbar";
import { customMessages } from "../../../../CustomDatas";
const ChangePassword = ({ _id }) => {
  let navigate = useNavigate();

  const [visibilityNew, setVisiblityNew] = useState(false);
  const [visibilityConfirm, setVisiblityConfirm] = useState(false);

  const endAdornment = (item) => {
    let passwordType;
    let setPasswordType;
    if (item === "newPassword") {
      passwordType = visibilityNew;
      setPasswordType = setVisiblityNew;
    } else {
      passwordType = visibilityConfirm;
      setPasswordType = setVisiblityConfirm;
    }
    return (
      <InputAdornment position="end">
        {passwordType ? (
          <Visibility onClick={() => setPasswordType(!passwordType)} />
        ) : (
          <VisibilityOff onClick={() => setPasswordType(!passwordType)} />
        )}
      </InputAdornment>
    );
  };
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [validationTrigger, setValidationTrigger] = useState(false);
  const [changePassword, setChangePassword] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [notificationData, setNotificationData] = useState({
    message: "",
    severity: "",
  });
  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (validationTrigger) setErrors(validation(changePassword));
  }, [changePassword, validationTrigger]);

  const changePasswordTrigger = (event) => {
    event.preventDefault();
    setValidationTrigger(true);
    let validationError = validation(changePassword);
    setErrors(validationError);
    if (!validationError.error) {
      let data = {
        _id: _id,
        password: changePassword.newPassword,
      };

      postNoAuthAPIHandler(API_CONFIG_VAR.CHANGE_PASSWORD, data).then(
        (result) => {
          if (result?.response?.status === 200) {
            setOpenSnackbar(true);
            setNotificationData({
              message: customMessages.changePasswordSuccess,
              severity: customMessages.severitySuccess,
            });
            setTimeout(() => {
              navigate("/admin-console-login");
            }, 2000);
          } else if (result?.response?.status === 412) {
            return (
              setOpenSnackbar(true),
              setNotificationData({
                message: result?.response?.data?.message,
                severity: customMessages.severityError,
              })
            );
          } else {
            return (
              setOpenSnackbar(true),
              setNotificationData({
                message: customMessages.commonError,
                severity: customMessages.severityError,
              })
            );
          }
        }
      );
    } else {
      return (
        setOpenSnackbar(true),
        setNotificationData({
          message: customMessages.formFillError,
          severity: customMessages.severityError,
        })
      );
    }
  };
  const textChange = (event) => {
    setChangePassword({
      ...changePassword,
      [event.target.name]: event.target.value,
    });
  };

  const handleClose = () => {
    setOpenSnackbar(false);
  };
  return (
    <>
      {/* Custom notification snackbar */}
      <CustomSnackbar
        openSnackbar={openSnackbar}
        message={notificationData?.message}
        handleClose={handleClose}
        severity={notificationData?.severity}
      />

      <Grid container justifyContent="center" alignItems="center">
        <form style={{ width: 450 }}>
          <Grid item xs={12}>
            <CustomTextField
              name="newPassword"
              label="New Password  "
              variant="outlined"
              size="small"
              margin="normal"
              fullWidth
              autoFocus
              type={visibilityNew ? "text" : "password"}
              InputProps={{
                endAdornment: endAdornment("newPassword"),
              }}
              value={changePassword.newPassword}
              onChange={textChange}
            />
            {errors.newPassword && (
              <span className="login-error-span ">{errors.newPassword}</span>
            )}
            <Grid item xs={12}>
              <CustomTextField
                name="confirmPassword"
                label=" Confirm Password "
                variant="outlined"
                size="small"
                margin="normal"
                fullWidth
                type={visibilityConfirm ? "text" : "password"}
                InputProps={{
                  endAdornment: endAdornment("confirmPassword"),
                }}
                value={changePassword.confirmPassword}
                onChange={textChange}
              />
              {errors.confirmPassword && (
                <span className="login-error-span ">
                  {errors.confirmPassword}
                </span>
              )}
            </Grid>
            {errors.password && (
              <span className="login-error-span ">{errors.password}</span>
            )}
            <CustomButton
              name="Submit"
              size="small"
              margin="normal"
              fullWidth
              onClick={changePasswordTrigger}
            />
          </Grid>
          <hr />
        </form>
      </Grid>
    </>
  );
};
export default ChangePassword;
