import healthImage from "../../../../assets/images/indexPage/health.png";
import clockImage from "../../../../assets/images/indexPage/clock.png";
import workLifeImage from "../../../../assets/images/indexPage/work-life.png";
import cuttingEdgeImage from "../../../../assets/images/indexPage/cutting-edge.png";

const Benefits = () => {
  return (
    <>
      <div className="benifit">
        <div className="benifit-headings">
          <h4>Benefits</h4>
          <p>Where Technology and Passion Unites</p>
        </div>

        <div className="benifit-cards">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img
                        src={healthImage}
                        className="card-img-top"
                        alt="..."
                      />
                      Health Insurance
                    </h5>
                    <p className="card-text">
                      Keeping in mind the heath and happiness of all our
                      employees, a comprehensive healthcare is provided to all
                      the Saasvaapians.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img
                        src={clockImage}
                        className="card-img-top"
                        alt="..."
                      />
                      Career growth
                    </h5>
                    <p className="card-text">
                      New opportunities, exposure to different clients,
                      challenging roles; you have all that's needed for a steady
                      growth in career and self-development.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img
                        src={workLifeImage}
                        className="card-img-top"
                        alt="..."
                      />
                      Work Life Balance
                    </h5>
                    <p className="card-text">
                      To better balance professional and personal commitments,
                      we at Saasvaap care for the well-being and work-life
                      balance of our associates and their families.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img
                        src={cuttingEdgeImage}
                        className="card-img-top"
                        alt="..."
                      />
                      Cutting-edge Tech
                    </h5>
                    <p className="card-text">
                      You can work on the latest and the cutting-edge
                      technologies in different domains here.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Benefits;
