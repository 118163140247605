import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import locationIcon from "../../assets/images/indexPage/location.png";
import phoneIcon from "../../assets/images/indexPage/phone.png";
import mailIcon from "../../assets/images/indexPage/mail.png";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
const TopBar = () => {
  return (
    <>
      <div className="top-bar">
        <div className="container">
          <div className="row">
            <div className="social-media col-md-4 col-4">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/profile.php?id=100054555844102"
              >
                <FacebookIcon />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://twitter.com/support83810593?t=kGnEVmhUXuV3KWOq39fpcA&s=15"
              >
                <TwitterIcon />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/saasvaap/"
              >
                <InstagramIcon />
              </a>

              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/company/saasvaap-inc"
              >
                <LinkedInIcon />
              </a>
            </div>
            <div className="address col-md-8 row col-8">
              <div className="location col-md-3">
                <p>
                  <img src={locationIcon} alt="address" /> 85 Saratoga Avenue
                </p>
              </div>
              <div className="phone-number col-md-5">
                <p>
                  <img src={phoneIcon} alt="phone no" />
                  <a href="tel:+1 (408) 520- 0753">+1 (408) 520- 0753</a> ,
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="tel:+1 (408) 724- 7219"
                  >
                    +1 (408) 724- 7219
                  </a>
                </p>
              </div>
              <div className="mail-address col-md-4">
                <p>
                  <img src={mailIcon} alt="email-address" />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="mailto:enquiries.us@saasvaap.com"
                    className="form-label-width"
                  >
                    enquiries.us@saasvaap.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TopBar;
