import React, { useState, useEffect } from "react";
import "../../../assets/styles/css/RolesPage.css";
import CustomDialog from "../../../components/customComponents/common/CustomDialog";
import CustomButton from "../../../components/customComponents/common/CustomButton";

import { Container } from "@mui/material";
import ListEmptyState from "../../../components/customComponents/common/CustomEmptyState";
import { RolesTable } from "./RolesTables";
import { postAPIHandler } from "../../../apiHandler/RestAPIHandler";
import API_URL_CONFIG from "../../../config/API_URL_CONFIG.json";
import DeleteRole from "./DeleteRole/Index";
import AddAndEditRole from "./AddAndEditRole/Index";
import Privilege from "./Privilege/Index";
import SuperAdminHeader from "../../../layouts/superAdmin/Header/Index";
import LoginVerify from "../../../components/utils/LoginVerify";
import CustomSnackbar from "../../../components/customComponents/common/CustomSnackbar";

const RolesPage = () => {
  const [dialogType, setDialogType] = useState("");
  const [roleList, setRoleList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [listLoader, setListLoader] = useState(false);
  const [roleDetails, setRoleDetails] = useState();
  const [validationTrigger, setValidationTrigger] = useState(false);
  const [roleListCount, setRoleListCount] = useState("");
  const PAGINATION_ROWS_PER_PAGE = 5;
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [notificationData, setNotificationData] = useState({
    message: "",
    severity: "",
  });
  useEffect(() => {
    LoginVerify();
    getCountAPI().then(() => {
      getRoleList(1);
    }); // eslint-disable-next-line
  }, []);
  const buildAPIRequestBody = (isforList, pageNumber) => {
    let requestBodyObject = {
      filterCategory: "roles",
      filterTemplate: "ROLES_FILTER_TEMPLATE_V1.json",
    };
    if (isforList) {
      requestBodyObject["sort"] = {
        sortFieldName: "created",
        sortType: "DESC",
      };
      requestBodyObject["range"] = {
        page: pageNumber,
        limit: PAGINATION_ROWS_PER_PAGE,
      };
    }
    let filterArray = [];

    requestBodyObject["filter"] = filterArray;

    return requestBodyObject;
  };
  const getCountAPI = () => {
    let requestData = buildAPIRequestBody(false, null);
    return new Promise((resolve, reject) => {
      postAPIHandler(API_URL_CONFIG.ROLE_LIST_COUNT, requestData)
        .then((result) => {
          if (result.response.status === 401) {
            setOpenSnackbar(true);
            setNotificationData({
              message: "Session Expired, Please login",
              severity: "error",
            });
            setTimeout(() => {
              window.location.replace("/admin-console-login");
              localStorage.clear();
            }, 1000);
          }
          if (result.message === "Network Error") {
            setRoleListCount(0);
            resolve();
          } else {
            setRoleListCount(result?.response.data.data[0].count);
            resolve();
          }
        })
        .catch((err) => {
          setRoleListCount(0);
          resolve();
        });
    });
  };
  const getRoleList = (pageNo = 1) => {
    let requestData = buildAPIRequestBody(true, pageNo);
    setListLoader(true);
    postAPIHandler(API_URL_CONFIG.ROLE_LIST_AUTH, requestData)
      .then((result) => {
        if (result.response.status === 401) {
          setOpenSnackbar(true);
          setNotificationData({
            message: "Session Expired, Please login",
            severity: "error",
          });
          setTimeout(() => {
            window.location.replace("/admin-console-login");
            localStorage.clear();
          }, 1000);
        }
        setListLoader(false);
        if (result.message === "Network Error") {
          setRoleList([]);
        } else {
          setRoleList(result?.response?.data?.data);
        }
      })
      .catch((err) => {
        setListLoader(false);
        setRoleList([]);
      });
  };
  const OpenDialog = (e, item) => {
    setDialogType(e);
    setRoleDetails(item);
  };

  const handleCloseButton = () => {
    setDialogType("");
    setValidationTrigger(false);
  };

  const paginationChange = (event, pageNo) => {
    setPageNo(pageNo);
    getRoleList(pageNo);
  };
  const handleClose = () => {
    setOpenSnackbar(false);
  };
  return (
    <div>
      <SuperAdminHeader />
      {/* Role user list start  */}
      <CustomSnackbar
        openSnackbar={openSnackbar}
        message={notificationData?.message}
        handleClose={handleClose}
        severity={notificationData?.severity}
      />
      <Container maxWidth="xl">
        <div
          style={{
            paddingTop: 30,
            textAlign: "center",
          }}
        >
          <div className="row justify-content-between">
            <div className="col-3"></div>

            <div style={{ textAlign: "right" }} className="col-2">
              <CustomButton
                name="Add Role "
                onClick={() => OpenDialog("AddRole")}
              />
            </div>
          </div>
          <div style={{ paddingTop: 15 }}>
            <RolesTable
              listLoader={listLoader}
              OpenDialog={OpenDialog}
              ListEmptyState={ListEmptyState}
              roleList={roleList}
              pageNo={pageNo}
              paginationChange={paginationChange}
              roleListCount={roleListCount}
            />
          </div>
        </div>
        {/*Create and Edit Dialog */}
        <CustomDialog
          openDialog={dialogType === "AddRole" || dialogType === "EditRole"}
          dialogTitle={dialogType === "AddRole" ? "Add Role" : "Edit Role"}
          handleCloseButton={handleCloseButton}
          component={
            <AddAndEditRole
              dialogType={dialogType}
              validationTrigger={validationTrigger}
              setValidationTrigger={setValidationTrigger}
              handleCloseButton={handleCloseButton}
              getRoleList={getRoleList}
              roleDetails={roleDetails}
            />
          }
        ></CustomDialog>
        {/*Create and Edit Dialog */}
        {/*Delete Dialog */}
        <CustomDialog
          openDialog={dialogType === "DeleteRole"}
          dialogTitle="Delete Role"
          handleCloseButton={handleCloseButton}
          component={
            <DeleteRole
              getRoleList={getRoleList}
              roleDetails={roleDetails}
              handleCloseButton={handleCloseButton}
            />
          }
        ></CustomDialog>
        {/*Privilege  Dialog */}
        <CustomDialog
          openDialog={dialogType === "Privilage"}
          dialogTitle={"Privileges - " + roleDetails?.roleName}
          handleCloseButton={handleCloseButton}
          component={
            <Privilege
              getRoleList={getRoleList}
              roleDetails={roleDetails}
              handleCloseButton={handleCloseButton}
            />
          }
        ></CustomDialog>
      </Container>
    </div>
  );
};
export default RolesPage;
