import React, { useEffect, useState } from "react";
import "../../../assets/styles/css/JobsPage.css";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import ListEmptyState from "../../../components/customComponents/common/CustomEmptyState";
import {
  ActiveUsersTable,
  PendingRequestTable,
  RejectedUsersTable,
} from "./RequestsTables";
import { postAPIHandler } from "../../../apiHandler/RestAPIHandler";
import API_URL_CONFIG from "../../../config/API_URL_CONFIG.json";
import SuperAdminHeader from "../../../layouts/superAdmin/Header/Index";
import AcceptRequest from "./AcceptRequest";
import CustomDialog from "../../../components/customComponents/common/CustomDialog";
import RejectRequest from "./RejectRequest";
import LoginVerify from "../../../components/utils/LoginVerify";
import CustomSnackbar from "../../../components/customComponents/common/CustomSnackbar";
import EditRole from "./EditRole";

/*Tab switch start*/
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};
/*Tab switch end */

const RequestsPage = () => {
  const [dialogType, setDialogType] = React.useState("");
  const [requestList, setRequestList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [listLoader, setListLoader] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [notificationData, setNotificationData] = useState({
    message: "",
    severity: "",
  });
  const [value, setValue] = useState(0);
  const [requestDetails, setRequestDetails] = useState();
  const [requestListCount, setRequestListCount] = useState("");
  const PAGINATION_ROWS_PER_PAGE = 5;

  useEffect(() => {
    LoginVerify();

    getCountAPI().then(() => {
      getRequestList(1);
    }); // eslint-disable-next-line
  }, [value]);
  const handleChangeTab = (event, newValue) => {
    setValue(event.target.value);
    setPageNo(1);
  };

  const OpenDialog = (e, item) => {
    setDialogType(e);
    setRequestDetails(item);
  };

  const handleCloseButton = () => {
    setDialogType("");
  };
  const buildAPIRequestBody = (isforList, pageNumber) => {
    let requestBodyObject = {
      filterCategory: "users",
      filterTemplate: "USERS_FILTER_TEMPLATE_V1.json",
    };
    if (isforList) {
      requestBodyObject["sort"] = {
        sortFieldName: "created",
        sortType: "DESC",
      };
      requestBodyObject["range"] = {
        page: pageNumber,
        limit: PAGINATION_ROWS_PER_PAGE,
      };
    }
    let filterArray = [];

    let jobStatus;
    let fieldOperator;
    let fieldKey;
    if (value === 0) {
      jobStatus = "pending";
      fieldKey = "userStatus";
      fieldOperator = "EQUAL";
    } else if (value === 1) {
      jobStatus = "active";
      fieldOperator = "EQUAL";
      fieldKey = "userStatus";
    } else if (value === 2) {
      jobStatus = "reject";
      fieldOperator = "EQUAL";
      fieldKey = "userStatus";
    }
    filterArray.push({
      fieldKey: fieldKey,
      filterValue: jobStatus,
      fieldOperator: fieldOperator,
      isLikeFilter: false,
    });
    requestBodyObject["filter"] = filterArray;

    return requestBodyObject;
  };
  const getRequestList = (pageNo = 1) => {
    let requestData = buildAPIRequestBody(true, pageNo);
    setListLoader(true);
    postAPIHandler(API_URL_CONFIG.USERS_REQUEST_LIST, requestData)
      .then((result) => {
        if (result.response.status === 401) {
          setOpenSnackbar(true);
          setNotificationData({
            message: "Session Expired, Please login",
            severity: "error",
          });
          setTimeout(() => {
            window.location.replace("/admin-console-login");
            localStorage.clear();
          }, 1000);
        }
        setListLoader(false);
        if (result.message === "Network Error") {
          setRequestList([]);
        } else {
          setRequestList(result?.response?.data?.data);
        }
      })
      .catch((err) => {
        setListLoader(false);
        setRequestList([]);
      });
  };

  const getCountAPI = () => {
    let requestData = buildAPIRequestBody(false, null);
    return new Promise((resolve, reject) => {
      postAPIHandler(API_URL_CONFIG.ROLE_LIST_COUNT, requestData)
        .then((result) => {
          if (result.response.status === 401) {
            setOpenSnackbar(true);
            setNotificationData({
              message: "Session Expired, Please login",
              severity: "error",
            });
            setTimeout(() => {
              window.location.replace("/admin-console-login");
              localStorage.clear();
            }, 1000);
          }
          if (result.message === "Network Error") {
            setRequestListCount(0);
            resolve();
          } else {
            setRequestListCount(result?.response.data.data[0].count);
            resolve();
          }
        })
        .catch((err) => {
          setRequestListCount(0);
          resolve();
        });
    });
  };
  const paginationChange = (event, pageNo) => {
    setPageNo(pageNo);
    getRequestList(pageNo);
  };
  const handleClose = () => {
    setOpenSnackbar(false);
  };
  return (
    <div>
      <SuperAdminHeader />
      {/* Custom notification snackbar */}
      <CustomSnackbar
        openSnackbar={openSnackbar}
        message={notificationData?.message}
        handleClose={handleClose}
        severity={notificationData?.severity}
      />
      {/* Request user list start  */}
      <Container maxWidth="xl">
        <div
          style={{
            paddingTop: 30,
            textAlign: "center",
          }}
        >
          <div className="row justify-content-between">
            <div style={{ paddingLeft: 34 }} className="col-3">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={value}
                  label="Option"
                  size="small"
                  onChange={handleChangeTab}
                >
                  <MenuItem {...a11yProps(0)} value={0}>
                    Pending
                  </MenuItem>
                  <MenuItem {...a11yProps(1)} value={1}>
                    Accepted
                  </MenuItem>
                  <MenuItem {...a11yProps(2)} value={2}>
                    Rejected
                  </MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="col-2"></div>
          </div>

          <TabPanel value={value} index={0}>
            <PendingRequestTable
              OpenDialog={OpenDialog}
              ListEmptyState={ListEmptyState}
              requestList={requestList}
              paginationChange={paginationChange}
              pageNo={pageNo}
              requestListCount={requestListCount}
              listLoader={listLoader}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ActiveUsersTable
              OpenDialog={OpenDialog}
              ListEmptyState={ListEmptyState}
              requestList={requestList}
              paginationChange={paginationChange}
              pageNo={pageNo}
              requestListCount={requestListCount}
              listLoader={listLoader}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <RejectedUsersTable
              OpenDialog={OpenDialog}
              ListEmptyState={ListEmptyState}
              requestList={requestList}
              paginationChange={paginationChange}
              pageNo={pageNo}
              requestListCount={requestListCount}
              listLoader={listLoader}
            />
          </TabPanel>
        </div>
        {/*Accept request  Dialog */}
        <CustomDialog
          openDialog={dialogType === "AcceptRequest"}
          dialogTitle="Request"
          handleCloseButton={handleCloseButton}
          component={
            <AcceptRequest
              getRequestList={getRequestList}
              requestDetails={requestDetails}
              handleCloseButton={handleCloseButton}
            />
          }
        ></CustomDialog>
        {/*Accept request  Dialog */}
        <CustomDialog
          openDialog={dialogType === "RejectRequest"}
          dialogTitle="Reject"
          handleCloseButton={handleCloseButton}
          component={
            <RejectRequest
              getRequestList={getRequestList}
              requestDetails={requestDetails}
              handleCloseButton={handleCloseButton}
            />
          }
        ></CustomDialog>
        {/* Edit user role */}
        <CustomDialog
          openDialog={dialogType === "EditRole"}
          dialogTitle="Update Role"
          handleCloseButton={handleCloseButton}
          component={
            <EditRole
              //getRequestList={getRequestList}
              dialogType={dialogType}
              requestDetails={requestDetails}
              handleCloseButton={handleCloseButton}
              //requestList={requestList}
            />
          }
        ></CustomDialog>
      </Container>
    </div>
  );
};
export default RequestsPage;
