import { Grid, Tooltip } from "@mui/material";
import { Container } from "@mui/system";
import "../../../assets/styles/css/ProfilePage.css";
import CustomButton from "../../../components/customComponents/common/CustomButton";
import CustomTextField from "../../../components/customComponents/common/CustomTextField";
import AdminHeader from "../../../layouts/admin/Header";
import "../../../assets/styles/css/Navbar.css";
import { useEffect, useState } from "react";
import {
  getAPIHandler,
  postAPIHandler,
} from "../../../apiHandler/RestAPIHandler";
import { customMessages } from "../../../CustomDatas";
import API_CONFIG_VAR from "../../../config/API_URL_CONFIG.json";
import CustomSnackbar from "../../../components/customComponents/common/CustomSnackbar";
import ProfileAvathar from "../../../assets/images/ProfilePage/profile-avathar.png";
import ChangePassword from "./ProfilePageChangePassword/ChangePassword";
import CustomDialog from "../../../components/customComponents/common/CustomDialog";
import LoginVerify from "../../../components/utils/LoginVerify";

const ProfilePage = () => {
  const [profileData, setProfileData] = useState({
    userName: "",
    email: "",
    designation: "",
    contactNumber: "",
    role: [],
  });
  const [profilePhoto, setProfilePhoto] = useState({
    imageData: "",
    imageType: "",
  });
  const [dialogType, setDialogType] = useState("");

  const [notificationData, setNotificationData] = useState({
    message: "",
    severity: "",
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    LoginVerify();

    getProfileDetails(); // eslint-disable-next-line
  }, []);

  const profileUpdateTrigger = (event) => {
    let user_id = localStorage.getItem("user_id");

    event.preventDefault();

    const URL = API_CONFIG_VAR.UPDATE_USER_PROFILE_DETAILS;
    const postData = {
      _id: user_id,
      userName: profileData.userName,
      designation: profileData.designation,
      contactNumber: profileData.contactNumber,
    };

    postAPIHandler(URL, postData).then((result) => {
      if (result.response.status === 401) {
        setOpenSnackbar(true);
        setNotificationData({
          message: "Session Expired, Please login",
          severity: "error",
        });
        setTimeout(() => {
          window.location.replace("/admin-console-login");
          localStorage.clear();
        }, 1000);
      }
      if (result?.response?.status === 200) {
        setOpenSnackbar(true);
        setNotificationData({
          message: customMessages.profileDataUpdateSuccess,
          severity: customMessages.severitySuccess,
        });
        setProfileData(result?.response.data);
      } else {
        return (
          setOpenSnackbar(true),
          setNotificationData({
            message: customMessages.commonError,
            severity: customMessages.severityError,
          })
        );
      }
    });
  };
  const getProfileDetails = (event) => {
    let user_id = localStorage.getItem("user_id");

    const URL = API_CONFIG_VAR.USER_DETAILS + "?_id=" + user_id;
    getAPIHandler(URL).then((result) => {
      if (result?.response?.status === 200) {
        setProfileData(result?.response.data.data);
        setProfilePhoto({
          ...profilePhoto,
          imageData: result?.response.data.data.imageData,
          imageType: result?.response.data.data.imageType,
        });
      } else if (result?.response?.status === 401) {
        setOpenSnackbar(true);
        setNotificationData({
          message: "Session Expired, Please login",
          severity: "error",
        });
        setTimeout(() => {
          window.location.replace("/admin-console-login");
          localStorage.clear();
        }, 1000);
      }
    });
  };
  const textChange = (event) => {
    setProfileData({
      ...profileData,
      [event.target.name]: event.target.value,
    });
  };
  const handleClose = () => {
    setOpenSnackbar(false);
  };
  const toBase64 = (files) => {
    let user_id = localStorage.getItem("user_id");
    let reader = new FileReader();
    reader.readAsDataURL(files);
    reader.onload = function () {
      let data = reader?.result;

      const URL = API_CONFIG_VAR.USER_PROFILE_ADD;

      const postData = { _id: user_id, imageType: files.type, imageData: data };

      postAPIHandler(URL, postData).then((result) => {
        if (result.response.status === 401) {
          setOpenSnackbar(true);
          setNotificationData({
            message: "Session Expired, Please login",
            severity: "error",
          });
          setTimeout(() => {
            window.location.replace("/admin-console-login");
            localStorage.clear();
          }, 1000);
        }
        if (result?.response?.status === 200) {
          setOpenSnackbar(true);
          setNotificationData({
            message: customMessages.profilePhotoAddSuccess,
            severity: customMessages.severitySuccess,
          });
          getProfileDetails();
        } else {
          return (
            setOpenSnackbar(true),
            setNotificationData({
              message: customMessages.commonError,
              severity: customMessages.severityError,
            })
          );
        }
      });
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };
  const UploadChange = (event) => {
    let file = event.target.files[0];
    toBase64(file);
  };
  const OpenDialog = (e) => {
    setDialogType(e);
  };
  const handleCloseButton = () => {
    setDialogType("");
  };
  return (
    <>
      {/* Custom notification snackbar */}
      <CustomSnackbar
        openSnackbar={openSnackbar}
        message={notificationData?.message}
        handleClose={handleClose}
        severity={notificationData?.severity}
      />
      <AdminHeader />
      <Container maxWidth="md" className="profile-container">
        <div className="profile-photo-container">
          <div className="photo-wrapper">
            <img
              src={
                profilePhoto.imageData ? profilePhoto.imageData : ProfileAvathar
              }
              alt="profile pic"
              className="profile-photo"
            />
            <Tooltip title="Change Profile Picture">
              <input
                onChange={UploadChange}
                accept=".png, .jpg, .jpeg"
                type="file"
              />
            </Tooltip>
          </div>
        </div>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          spacing={3}
          style={{ padding: 40 }}
        >
          <Grid item xs={3}>
            <p>Name</p>
          </Grid>
          <Grid item xs={9}>
            <CustomTextField
              name="userName"
              label=" Name "
              variant="outlined"
              size="small"
              fullWidth
              autoFocus
              value={profileData.userName}
              InputLabelProps={{ shrink: true }}
              onChange={textChange}
            />
          </Grid>
          <Grid item xs={3}>
            <p>Designation</p>
          </Grid>
          <Grid item xs={9}>
            <CustomTextField
              name="designation"
              label=" Designation "
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              size="small"
              fullWidth
              value={profileData.designation}
              onChange={textChange}
            />
          </Grid>
          <Grid item xs={3}>
            <p>Email</p>
          </Grid>
          <Grid item xs={9}>
            <CustomTextField
              name="email"
              label=" Email "
              variant="outlined"
              size="small"
              disabled
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={profileData.email}
              onChange={textChange}
            />
          </Grid>
          <Grid item xs={3}>
            <p>Role</p>
          </Grid>
          <Grid item xs={9}>
            <CustomTextField
              name="role"
              label=" Role "
              variant="outlined"
              size="small"
              disabled
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={profileData.role}
              onChange={textChange}
            />
          </Grid>
          <Grid item xs={3}>
            <p>Phone</p>
          </Grid>
          <Grid item xs={9}>
            <CustomTextField
              name="contactNumber"
              label=" Phone "
              variant="outlined"
              size="small"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={profileData.contactNumber}
              onChange={textChange}
            />
          </Grid>
          <Grid item xs={3}>
            <p
              onClick={() => OpenDialog("ChangePassword")}
              className="change-password"
            >
              Change Password
            </p>
          </Grid>
          <Grid item xs={9}>
            <CustomButton
              onClick={profileUpdateTrigger}
              size="small"
              name="Save"
            />
          </Grid>
        </Grid>
      </Container>
      {/* Change Password dialog */}
      <CustomDialog
        openDialog={dialogType === "ChangePassword"}
        dialogTitle="Change Password"
        handleCloseButton={handleCloseButton}
        component={
          <ChangePassword
            _id={profileData?._id}
            handleCloseButton={handleCloseButton}
          />
        }
      ></CustomDialog>
    </>
  );
};
export default ProfilePage;
