const validation = (values) => {
  let errors = { error: false };
  if (!values.userName) {
    errors.userName = "Please Enter Name";
    errors.error = true;
  }
  if (!values.email) {
    errors.email = " Please Enter Email";
    errors.error = true;
  } else if (!/\S+@saasvaap.com/.test(values.email)) {
    errors.email = " Enter a valid Email";
    errors.error = true;
  }

  if (!values.contactNumber) {
    errors.contactNumber = " Please Enter Mobile Number ";
  } else if (
    //eslint-disable-next-line
    !/^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/.test(values.contactNumber)
  ) {
    errors.contactNumber = "Enter a valid Mobile Number";
    errors.error = true;
  }
  if (!values.designation) {
    errors.designation = "Please Enter designation";
    errors.error = true;
  }

  return errors;
};

export default validation;
