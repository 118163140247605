import React, { useState, useEffect } from "react";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import { Pagination } from "@mui/material";
import {
  postAPIHandler,
  getAPIHandler,
} from "../../../../apiHandler/RestAPIHandler";
import API_URL_CONFIG from "../../../../config/API_URL_CONFIG.json";
import {
  ListSkeleton,
  PaginationSkeleton,
} from "../../../../components/Skeleton/admin";
import ListEmptyState from "../../../../components/customComponents/common/CustomEmptyState";

import CustomDialog from "../../../../components/customComponents/common/CustomDialog";

import ResumeView from "./ResumeView";
import CustomSnackbar from "../../../../components/customComponents/common/CustomSnackbar";
import ApplicantDetails from "./ApplicantDetails";
const AppliedCandidates = ({ jobDetails, userPrivileges }) => {
  const PAGINATION_ROWS_PER_PAGE = 5;
  const [applicantList, setApplicantList] = useState([]);
  const [dialogType, setDialogType] = useState("");
  const [userDetails, setUserDetails] = useState();
  const [applicantListCount, setApplicantListCount] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [listLoader, setListLoader] = useState(false);
  const [base64, setBase64] = useState();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [notificationData, setNotificationData] = useState({
    message: "",
    severity: "",
  });
  useEffect(() => {
    getCountAPI().then(() => {
      getApplicantList(1);
    }); // eslint-disable-next-line
  }, [jobDetails?.jobId]);

  const buildAPIRequestBody = (isforList, pageNumber) => {
    let requestBodyObject = {
      filterCategory: "applicant",
      filterTemplate: "APPLICANT_FILTER_TEMPLATE_V1.json",
    };
    if (isforList) {
      requestBodyObject["sort"] = {
        sortFieldName: "created",
        sortType: "DESC",
      };
      requestBodyObject["range"] = {
        page: pageNumber,
        limit: PAGINATION_ROWS_PER_PAGE,
      };
    }
    let filterArray = [];
    filterArray.push({
      fieldKey: "jobId",
      filterValue: jobDetails?.jobId,
      fieldOperator: "EQUAL",
      isLikeFilter: false,
    });

    requestBodyObject["filter"] = filterArray;

    return requestBodyObject;
  };
  const getCountAPI = () => {
    let requestData = buildAPIRequestBody(false, null);
    return new Promise((resolve, reject) => {
      postAPIHandler(API_URL_CONFIG.GET_JOB_APPLICANT_LIST_COUNT, requestData)
        .then((result) => {
          if (result.message === "Network Error") {
            setApplicantListCount(0);
            resolve();
          } else {
            setApplicantListCount(result?.response.data.data[0].count);
            resolve();
          }
        })
        .catch((err) => {
          setApplicantListCount(0);
          resolve();
        });
    });
  };
  const getApplicantList = (pageNo = 1) => {
    let requestData = buildAPIRequestBody(true, pageNo);
    setListLoader(true);
    postAPIHandler(API_URL_CONFIG.LIST_JOB_APPLICANT, requestData)
      .then((result) => {
        setListLoader(false);
        if (result.response.status === 401) {
          setOpenSnackbar(true);
          setNotificationData({
            message: "Session Expired, Please login",
            severity: "error",
          });
          setTimeout(() => {
            window.location.replace("/admin-console-login");
            localStorage.clear();
          }, 1000);
        }
        if (result.message === "Network Error") {
          setApplicantList([]);
        } else {
          setApplicantList(result?.response?.data?.data);
        }
      })
      .catch((err) => {
        setListLoader(false);
        setApplicantList([]);
      });
  };

  const paginationChange = (event, pageNo) => {
    setPageNo(pageNo);
    getApplicantList(pageNo);
  };
  const handleCloseButton = () => {
    setDialogType("");
    getApplicantList();
  };
  const OpenDialog = (e, item) => {
    setDialogType(e);
    setUserDetails(item);
  };
  const OpenDialogResume = (e, item) => {
    setDialogType(e);
    setUserDetails(item);
    viewResume(item.documentId);
  };

  const viewResume = (documentId) => {
    getAPIHandler(API_URL_CONFIG.GET_RESUMES + "?_id=" + documentId, {
      responseType: "arraybuffer",
    }).then((result) => {
      setBase64(result.response.data.data.documentData);
    });
  };
  const handleClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <div style={{ width: 1000 }}>
      <CustomSnackbar
        openSnackbar={openSnackbar}
        message={notificationData?.message}
        handleClose={handleClose}
        severity={notificationData?.severity}
      />
      <div className=" table-responsive-xl">
        <div className=" wrap-table100">
          <div className=" shadow table100" style={{ minHeight: 300 }}>
            <table>
              <thead>
                <tr className="table100-head">
                  <th scope="col" className="column">
                    Name
                  </th>
                  <th scope="col" className="column">
                    Location
                  </th>
                  <th scope="col" className="column">
                    Experience
                  </th>
                  <th scope="col" className="column">
                    Email
                  </th>
                  {userPrivileges.includes(
                    "ACTIVE_JOBS_APPLICANTS_VIEW_RESUME_BUTTON"
                  ) ? (
                    <th scope="col" className="column">
                      Resume
                    </th>
                  ) : (
                    ""
                  )}
                  <th scope="col" className="column">
                    Status
                  </th>

                  <th scope="col" className="column"></th>
                  <th scope="col" className="column"></th>
                </tr>
              </thead>

              {listLoader ? (
                <ListSkeleton />
              ) : applicantList ? (
                applicantList.length ? (
                  applicantList.map((item, i) => {
                    return (
                      <tbody key={i}>
                        <tr>
                          <td
                            style={{
                              color: "rgba(54, 171, 155)",
                            }}
                            className="column"
                            onClick={() =>
                              OpenDialog("AppliedCandidatesPage", item)
                            }
                          >
                            {item?.fullName}
                          </td>
                          <td className="column">{item?.location}</td>
                          <td className="column">
                            {item?.experienceInYear} Years and &nbsp;
                            {item?.experienceInMonth} Months
                          </td>
                          <td className="column">{item?.email}</td>

                          {userPrivileges.includes(
                            "ACTIVE_JOBS_APPLICANTS_VIEW_RESUME_BUTTON"
                          ) ? (
                            <td className="column">
                              <SimCardDownloadIcon
                                onClick={() =>
                                  OpenDialogResume("ReadResume", item)
                                }
                                color="primary"
                              />
                            </td>
                          ) : (
                            ""
                          )}
                          <td className="column">{item?.applicantStatus}</td>
                        </tr>
                      </tbody>
                    );
                  })
                ) : (
                  <ListEmptyState emptyState="No Candidates Applied" />
                )
              ) : (
                <ListEmptyState emptyState="No Candidates Applied" />
              )}
            </table>
          </div>

          {listLoader ? (
            <PaginationSkeleton />
          ) : applicantList ? (
            applicantListCount > 5 ? (
              <div className="job-list-pagination">
                <Pagination
                  count={
                    applicantListCount < 5
                      ? 1
                      : Math.ceil(applicantListCount / 5)
                  }
                  onChange={paginationChange}
                  page={pageNo}
                  color="primary"
                  size="small"
                />
              </div>
            ) : (
              <div style={{ marginBottom: 20 }} />
            )
          ) : (
            ""
          )}
        </div>
      </div>

      {/*Resume user Dialog */}
      <CustomDialog
        openDialog={dialogType === "ReadResume"}
        dialogTitle="View "
        handleCloseButton={handleCloseButton}
        component={
          <ResumeView
            getApplicantList={getApplicantList}
            handleCloseButton={handleCloseButton}
            base64={base64}
          />
        }
      ></CustomDialog>
      {/*applicat Dialog */}
      <CustomDialog
        openDialog={dialogType === "AppliedCandidatesPage"}
        dialogTitle="Candidate Details"
        handleCloseButton={handleCloseButton}
        component={
          <ApplicantDetails
            dialogType={dialogType}
            handleCloseButton={handleCloseButton}
            userDetails={userDetails}
            getApplicantList={getApplicantList}
          />
        }
      ></CustomDialog>
    </div>
  );
};
export default AppliedCandidates;
