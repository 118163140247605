import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { List } from "@mui/material";
import { getAPIHandler } from "../../../../apiHandler/RestAPIHandler";
import API_URL_CONFIG from "../../../../config/API_URL_CONFIG.json";
import { Grid } from "@mui/material";
import CustomButton from "../../../../components/customComponents/common/CustomButton";
import CustomDialog from "../../../../components/customComponents/common/CustomDialog";
import CreateFieldEntry from "../CreateFieldEntry/Index";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteEntry from "../DeleteEntry/Index";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Configuration = () => {
  const [value, setValue] = useState(0);
  const [keySkillsList, setKeySkillsList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [qualificationList, setQualificationList] = useState([]);
  const [positionList, setPositionList] = useState([]);
  const [dialogType, setDialogType] = useState("");
  const [deleteEntryId, setDeleteEntryId] = useState("");
  const [entryType, setEntryType] = useState("");
  const [entryValue, setEntryValue] = useState("");

  useEffect(() => {
    getKeySkills();
    getLocation();
    getPosition();
    getQualification();
  }, []);

  const getKeySkills = () => {
    getAPIHandler(API_URL_CONFIG.KEYSKILLS_LIST, {
      responseType: "arraybuffer",
    }).then((result) => {
      setKeySkillsList(result.response.data.data);
    });
  };
  const getLocation = () => {
    getAPIHandler(API_URL_CONFIG.LOCATION_LIST, {
      responseType: "arraybuffer",
    }).then((result) => {
      setLocationList(result.response.data.data);
    });
  };
  const getQualification = () => {
    getAPIHandler(API_URL_CONFIG.QUALIFICATION_LIST, {
      responseType: "arraybuffer",
    }).then((result) => {
      setQualificationList(result.response.data.data);
    });
  };
  const getPosition = () => {
    getAPIHandler(API_URL_CONFIG.POSITION_LIST, {
      responseType: "arraybuffer",
    }).then((result) => {
      setPositionList(result.response.data.data);
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const OpenDialog = (e, item) => {
    setDialogType(e);
  };

  const handleCloseButton = () => {
    setDialogType("");
  };

  return (
    <div style={{ minWidth: 800 }}>
      {/* Custom notification snackbar */}
      <div className="add-configuration">
        <CustomButton name="Add" onClick={() => OpenDialog("CreateEntry")} />
      </div>
      <br />
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        indicatorColor="primary"
        textColor="inherit"
        variant="fullWidth"
      >
        <Tab label="Key Skill" {...a11yProps(0)} />
        <Tab label="Qualification" {...a11yProps(1)} />
        <Tab label="Position" {...a11yProps(2)} />
        <Tab label="Location" {...a11yProps(3)} />
      </Tabs>

      <TabPanel value={value} index={0}>
        {keySkillsList.map((item) => {
          return (
            <>
              <Grid container spacing={3}>
                <div className="row-container">
                  <div>
                    <CancelIcon
                      style={{
                        marginTop: 10,
                        marginRight: 10,
                        fontSize: "medium",
                        color: "gray",
                        opacity: 0.5,
                      }}
                      className="delete-entry"
                      onClick={() => {
                        OpenDialog("DeleteEntry");
                        setDeleteEntryId(item._id);
                        setEntryType("keySkills");
                        setEntryValue(item.keySkills);
                      }}
                    />
                  </div>
                  <Grid item xs={12}>
                    <List>{item.keySkills}</List>
                  </Grid>
                </div>
              </Grid>
            </>
          );
        })}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {qualificationList.map((item) => {
          return (
            <>
              <Grid container spacing={3}>
                <div className="row-container">
                  <div>
                    <CancelIcon
                      style={{
                        marginTop: 10,
                        marginRight: 10,
                        fontSize: "medium",

                        color: "gray",
                        opacity: 0.5,
                      }}
                      className="delete-entry"
                      onClick={() => {
                        OpenDialog("DeleteEntry");
                        setDeleteEntryId(item._id);
                        setEntryType("qualification");
                        setEntryValue(item.qualification);
                      }}
                    />
                  </div>

                  <Grid item xs={12}>
                    <List>{item.qualification}</List>
                  </Grid>
                </div>
              </Grid>
            </>
          );
        })}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {positionList.map((item) => {
          return (
            <>
              <Grid container spacing={2}>
                <div className="row-container">
                  <div>
                    <CancelIcon
                      style={{
                        marginTop: 10,
                        marginRight: 10,
                        fontSize: "medium",

                        color: "gray",
                        opacity: 0.5,
                      }}
                      className="delete-entry"
                      onClick={() => {
                        OpenDialog("DeleteEntry");
                        setDeleteEntryId(item._id);
                        setEntryType("position");
                        setEntryValue(item.position);
                      }}
                    />
                  </div>

                  <Grid item xs={12}>
                    <List>{item.position}</List>
                  </Grid>
                </div>
              </Grid>
            </>
          );
        })}
      </TabPanel>
      <TabPanel value={value} index={3}>
        {locationList.map((item) => {
          return (
            <>
              <Grid container spacing={2}>
                <div className="row-container">
                  <div>
                    <CancelIcon
                      style={{
                        marginTop: 10,
                        marginRight: 10,
                        fontSize: "medium",

                        color: "gray",
                        opacity: 0.5,
                      }}
                      className="delete-entry"
                      onClick={() => {
                        OpenDialog("DeleteEntry");
                        setDeleteEntryId(item._id);
                        setEntryType("location");
                        setEntryValue(item.location);
                      }}
                    />
                  </div>

                  <Grid item xs={12}>
                    <List>{item.location}</List>
                  </Grid>
                </div>
              </Grid>
            </>
          );
        })}
      </TabPanel>
      <hr />
      <br />
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
        spacing={2}
      ></Grid>

      <CustomDialog
        openDialog={dialogType === "CreateEntry"}
        dialogTitle="Create Entry"
        handleCloseButton={handleCloseButton}
        component={
          <CreateFieldEntry
            dialogType={dialogType}
            handleCloseButton={handleCloseButton}
            getKeySkills={getKeySkills}
            getLocation={getLocation}
            getPosition={getPosition}
            getQualification={getQualification}
          />
        }
      ></CustomDialog>

      <CustomDialog
        openDialog={dialogType === "DeleteEntry"}
        dialogTitle="Delete Entry"
        handleCloseButton={handleCloseButton}
        component={
          <DeleteEntry
            dialogType={dialogType}
            deleteEntryId={deleteEntryId}
            entryType={entryType}
            entryValue={entryValue}
            handleCloseButton={handleCloseButton}
            getKeySkills={getKeySkills}
            getLocation={getLocation}
            getPosition={getPosition}
            getQualification={getQualification}
          />
        }
      ></CustomDialog>
    </div>
  );
};
export default Configuration;
