import * as React from "react";
import Skeleton from "@mui/material/Skeleton";

export const ListSkeleton = () => {
  return Array(5)
    .fill()
    .map((item, i) => (
      <>
        <tbody key={i}>
          <tr>
            <td className="col">
              <Skeleton />
            </td>
            <td className="col">
              <Skeleton />
            </td>
            <td className="col">
              <Skeleton />
            </td>
            <td className="col">
              <Skeleton />
            </td>
            <td className="col">
              <Skeleton />
            </td>
          </tr>
        </tbody>
      </>
    ));
};
export const PaginationSkeleton = () => {
  return (
    <div className="container">
      <div className="job-list-pagination">
        <Skeleton animation="wave" variant="rounded" width="250px" />
      </div>
    </div>
  );
};
