const Validation = (values) => {
  console.log("valueddddd", values);
  let errors = { error: false };
  if (!values.interviewMode) {
    errors.interviewMode = "Please Select the interview level";
    errors.error = true;
  }
  if (!values.startTime) {
    errors.startTime = "Please enter  Interview time";
    errors.error = true;
  }
  if (!values.endTime) {
    errors.endTime = "Please enter  Interview time";
    errors.error = true;
  }
  if (!values.interviewerEmail) {
    errors.interviewerEmail = "Please enter  Interview time";
    errors.error = true;
  }

  return errors;
};

export default Validation;
