import React, { useState } from "react";
import { Grid } from "@mui/material";
import "../../../../assets/styles/css/AddJob.css";
import { Autocomplete, TextField } from "@mui/material";
import API_URL_CONFIG from "../../../../config/API_URL_CONFIG.json";
import { postAPIHandler } from "../../../../apiHandler/RestAPIHandler";
import CustomTextField from "../../../../components/customComponents/common/CustomTextField";
import validation from "./Validation";
import CustomButton from "../../../../components/customComponents/common/CustomButton";
import CustomSnackbar from "../../../../components/customComponents/common/CustomSnackbar";
import {
  customMessages,
  InterviewStatus,
  L1InterviewStatus,
  L2InterviewStatus,
} from "../../../../CustomDatas";

const ChangeInterviewStatus = ({
  handleCloseButton,
  applicantId,
  selectedOption,
}) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errors, setErrors] = useState({});
  const [notificationData, setNotificationData] = useState({
    message: "",
    severity: "",
  });

  const [changeInterviewStatusOption, setChangeInterviewStatusOption] =
    useState("");

  const [inputValue, setInputValue] = useState("");

  const onChangeChangeInterviewStatusOption = (event, value) => {
    setChangeInterviewStatusOption(value);
  };
  const handleCommentsText = (event) => {
    const value = event.target.value;
    setInputValue(value);
  };
  const submitEntry = () => {
    interviewStatusData(changeInterviewStatusOption, inputValue);
  };

  const interviewStatusData = (changeInterviewStatusOption, inputValue) => {
    let validationError = validation(
      (changeInterviewStatusOption = changeInterviewStatusOption),
      (inputValue = inputValue)
    );
    setErrors(validationError);

    const postData =
      changeInterviewStatusOption === "L1 Completed Candidates"
        ? {
            interviewStatus: "L1 completed",
            comments: inputValue,
            _id: applicantId._id,
          }
        : changeInterviewStatusOption === "L2 Completed Candidates"
        ? {
            interviewStatus: "L2 completed",
            comments: inputValue,
            _id: applicantId._id,
          }
        : changeInterviewStatusOption === "Selected Candidates"
        ? {
            interviewStatus: "final round completed",
            comments: inputValue,
            _id: applicantId._id,
          }
        : changeInterviewStatusOption === "Onboarded Candidates"
        ? {
            interviewStatus: "onboarded",
            comments: inputValue,
            _id: applicantId._id,
          }
        : null;

    const URL = API_URL_CONFIG.APPLICANT_STATUS_UPDATE;
    if (!validationError?.error) {
      postAPIHandler(URL, postData)
        .then((result) => {
          if (result.response.status === 401) {
            setOpenSnackbar(true);
            setNotificationData({
              message: "Session Expired, Please login",
              severity: "error",
            });
            setTimeout(() => {
              window.location.replace("/admin-console-login");
              localStorage.clear();
            }, 1000);
          }
          if (result.response.status === 200) {
            return (
              setOpenSnackbar(true),
              setNotificationData({
                message: customMessages.applicantStatusUpdated,
                severity: customMessages.severitySuccess,
              }),
              setTimeout(() => {
                handleCloseButton();
                setChangeInterviewStatusOption("");
                setInputValue("");
              }, 1000)
            );
          } else if (result.response.status === 412) {
            return (
              setOpenSnackbar(true),
              setNotificationData({
                message: customMessages.formFillError,
                severity: customMessages.severityError,
              })
            );
          }
        })
        .catch((err) => {
          return (
            setOpenSnackbar(true),
            setNotificationData({
              message: customMessages.commonError,
              severity: customMessages.severityError,
            })
          );
        });
    } else {
      return (
        setOpenSnackbar(true),
        setNotificationData({
          message: customMessages.formFillError,
          severity: customMessages.severityError,
        })
      );
    }
  };
  const handleClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      {/* Custom notification snackbar */}
      <CustomSnackbar
        openSnackbar={openSnackbar}
        message={notificationData?.message}
        handleClose={handleClose}
        severity={notificationData?.severity}
      />
      <Grid
        style={{ width: 400 }}
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid xs={12}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={
              selectedOption === 0
                ? InterviewStatus
                : selectedOption === 1
                ? L1InterviewStatus
                : selectedOption === 2
                ? L2InterviewStatus
                : ""
            }
            onChange={onChangeChangeInterviewStatusOption}
            size="small"
            value={changeInterviewStatusOption}
            renderInput={(params) => (
              <TextField {...params} label="Select Field" />
            )}
          />
          {errors.changeInterviewStatusOption && (
            <span className="role-error-span ">
              {errors.changeInterviewStatusOption}
            </span>
          )}
        </Grid>
        <Grid xs={12} style={{ marginTop: 30 }}>
          <CustomTextField
            name="Comments"
            label="  Comments"
            variant="outlined"
            size="small"
            fullWidth
            value={inputValue}
            onChange={handleCommentsText}
          />

          {errors.inputValue && (
            <span className="role-error-span">{errors.inputValue}</span>
          )}
        </Grid>
      </Grid>
      <hr />
      <br />
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
        spacing={2}
      >
        <Grid xs={6} sm={3}>
          <CustomButton
            onClick={handleCloseButton}
            color="secondary"
            name="Cancel"
          />
        </Grid>
        <Grid xs={3} sm={3}>
          <CustomButton name="Submit" onClick={submitEntry} />
        </Grid>
      </Grid>
    </>
  );
};
export default ChangeInterviewStatus;
