import { postAPIHandler } from "../../../../apiHandler/RestAPIHandler";
import API_URL_CONFIG from "../../../../config/API_URL_CONFIG.json";
import validation from "./Validation";

export const addJobAPIHandler = (
  value,
  jobFields,
  customMessages,
  setNotificationData,
  setOpenSnackbar,
  handleCloseButton,
  getJobList,
  initialStates,
  setJobFields,
  setValidationTrigger,
  dialogType,
  errors,
  jobDetails,
  setErrors,
  repost
) => {
  setValidationTrigger(true);
  let statusValue = value;
  if (jobDetails?.status === "active") {
    statusValue = "active";
  } else if (jobDetails?.status === "priority") {
    statusValue = "priority";
  } else {
    statusValue = value;
  }
  const postData = {
    jobTitle: jobFields.jobTitle,
    jobDescription: jobFields.jobDescription,
    position: jobFields.position,
    keySkills: jobFields.keySkills,
    minimumExperience: jobFields.minimumExperience,
    maximumExperience: jobFields.maximumExperience,
    minimumAnnualSalary: jobFields.minimumAnnualSalary,
    maximumAnnualSalary: jobFields.maximumAnnualSalary,
    timeShift: jobFields.timeShift,
    fullTime: jobFields.fullTime,
    partTime: jobFields.partTime,
    location: jobFields.location,
    qualification: jobFields.qualification,
    status: statusValue,
    _id: dialogType === "EditJob" ? jobDetails?._id : null,
    workMode: jobFields.workMode,
  };
  const URL =
    dialogType === "EditJob"
      ? API_URL_CONFIG.EDIT_JOB
      : API_URL_CONFIG.POST_JOB;
  if (statusValue === "draft") {
    if (jobFields?.jobTitle) {
      setValidationTrigger(false);
      postAPIHandler(URL, postData)
        .then((result) => {
          if (result.response.status === 200) {
            return (
              setOpenSnackbar(true),
              setNotificationData({
                message: customMessages.draftSuccess,
                severity: customMessages.severitySuccess,
              }),
              setTimeout(() => {
                getJobList(1);
                handleCloseButton();
              }, 1000)
            );
          } else if (result.response.status === 401) {
            setOpenSnackbar(true);
            setNotificationData({
              message: "Session Expired, Please login",
              severity: "error",
            });
            setTimeout(() => {
              window.location.replace("/admin-console-login");
              localStorage.clear();
            }, 1000);
          } else {
            return (
              setOpenSnackbar(true),
              setNotificationData({
                message: customMessages.commonError,
                severity: customMessages.severityError,
              })
            );
          }
        })
        .catch(() => {
          setOpenSnackbar(true);
          setNotificationData({
            message: customMessages.commonError,
            severity: customMessages.severityError,
          });
        });
    } else {
      setValidationTrigger(false);
      setOpenSnackbar(true);
      setNotificationData({
        message: customMessages.formFillErrorJobTitle,
        severity: customMessages.severityError,
      });
    }
  } else {
    let validationError = validation(jobFields);
    setErrors(validationError);
    if (!validationError.error) {
      postAPIHandler(URL, postData)
        .then((result) => {
          if (result.response.status === 200) {
            return (
              setOpenSnackbar(true),
              setNotificationData({
                message:
                  dialogType === "EditJob"
                    ? repost === "RepostJob"
                      ? customMessages.jobRepostSuccess
                      : customMessages.jobEditSuccess
                    : statusValue === "submitted"
                    ? customMessages.jobAddSuccess
                    : customMessages.draftSuccess,
                severity: customMessages.severitySuccess,
              }),
              setTimeout(() => {
                getJobList(1);
                handleCloseButton();
              }, 1000)
            );
          } else if (result.response.status === 401) {
            setOpenSnackbar(true);
            setNotificationData({
              message: "Session Expired, Please login",
              severity: "error",
            });
            setTimeout(() => {
              window.location.replace("/admin-console-login");
              localStorage.clear();
            }, 1000);
          } else if (result.response.status === 412) {
            return (
              setOpenSnackbar(true),
              setNotificationData({
                message: customMessages.formFillError,
                severity: customMessages.severityError,
              })
            );
          } else {
            return (
              setOpenSnackbar(true),
              setNotificationData({
                message: customMessages.commonError,
                severity: customMessages.severityError,
              })
            );
          }
        })
        .catch(() => {
          setOpenSnackbar(true);
          setNotificationData({
            message: customMessages.commonError,
            severity: customMessages.severityError,
          });
        });
    } else {
      return (
        setOpenSnackbar(true),
        setNotificationData({
          message: customMessages.formFillError,
          severity: customMessages.severityError,
        })
      );
    }
  }
};
export const getJobDetails = (
  dialogType,
  jobDetails,
  setJobFields,
  setOpenSnackbar,
  setNotificationData
) => {
  if (dialogType === "EditJob" || dialogType === "JobDetails") {
    const URL = API_URL_CONFIG.JOB_DESCRIPTION_AUTH;
    postAPIHandler(URL, { _id: jobDetails?._id })
      .then((result) => {
        if (result.response.status === 401) {
          return (
            setOpenSnackbar(true),
            setNotificationData({
              message: "Session Expired, Please login",
              severity: "error",
            }),
            setTimeout(() => {
              window.location.replace("/admin-console-login");
              localStorage.clear();
            }, 1000)
          );
        }
        if (result.message === "Network Error") {
          setJobFields({});
        } else {
          setJobFields(result?.response?.data?.data);
        }
      })
      .catch((err) => {
        setJobFields({});
      });
  }
};
