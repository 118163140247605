import React from "react";
import { Button } from "@mui/material";
import "../../../../assets/styles/css/CustomComponents.css";
const CustomButton = (props) => {
  const {
    size = "",
    variant = "contained",
    name = "",
    autoFocus = false,
    color = "primary",
    startIcon = "",
    onClick,
    fullWidth,
    className = "",
    disabled = false,
    disableRipple = false,
    disableFocusRipple = false,
    disableElevation = false,
  } = props;

  return (
    <>
      <Button
        style={{
          minWidth: 100,
          minHeight: 30,
          textTransform: "none",
          color: "white",
        }}
        disabled={disabled}
        className={className}
        size={size}
        color={color}
        variant={variant}
        autoFocus={autoFocus}
        startIcon={startIcon}
        onClick={onClick}
        fullWidth={fullWidth}
        disableRipple={disableRipple}
        disableFocusRipple={disableFocusRipple}
        disableElevation={disableElevation}
        {...props}
      >
        {name}
      </Button>
    </>
  );
};
export default CustomButton;
