const validation = (values) => {
  let errors = {};
  if (!values.password) {
    errors.password = "Enter password";
  }
  if (!values.email) {
    errors.email = " Please enter email";
  } else if (!/\S+@saasvaap.com/.test(values.email)) {
    errors.email = " Enter a valid email";
  }

  if (Object.values(errors).length) {
    return errors;
  } else {
    return false;
  }
};

export default validation;
