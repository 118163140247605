import isoLogo from "../../assets/images/indexPage/iso.png";
import ebayLogo from "../../assets/images/indexPage/ebay.png";
import paaniniLogo from "../../assets/images/indexPage/paanini.png";
import googleLogo from "../../assets/images/indexPage/google.png";
import saasvaapImage from "../../assets/images/indexPage/saasvaap.png";
const Footer = () => {
  return (
    <>
      <div className="section-footer">
        <div className="container">
          <div className="client-logos">
            <div className="row">
              <div className="col-md-3 col-6">
                <img src={isoLogo} alt="" />
              </div>
              <div className="col-md-3 col-6" id="logo">
                <img src={ebayLogo} alt="" />
              </div>
              <div className="col-md-3 col-6" id="logo">
                <img src={paaniniLogo} alt="" />
              </div>
              <div className="col-md-3 col-6" id="logo">
                <img src={googleLogo} alt="" />
              </div>
            </div>
          </div>

          <div className="main-footer">
            <div className="row">
              <div className="col-md-3 col-6">
                <h5>About Us</h5>
                <p>
                  Saasvaap is equipped to develop powerful , lightweight &
                  secure solutions with the advantages of digital power for an
                  utmost competitive edge in business
                </p>
              </div>

              <div className="col-md-3 col-6">
                <h5>Our Services</h5>
                <ul>
                  <li>ERP</li>
                  <li>Web</li>
                  <li>Mobility</li>
                  <li>Analytics</li>
                  <li>Oodoo Practices</li>
                </ul>
              </div>

              <div className="col-md-3 col-5">
                <h5>OUR GROUP OF COMPANIES</h5>
                <img src={saasvaapImage} alt="" />
                <p id="Saasvap-India">Saasvaap India</p>
              </div>

              <div className="col-md-3 col-7 contact-info">
                <h5>CONTACT INFO</h5>
                <div className="textwidget">
                  <div className="row">
                    <div className="col-md-4 col-4">Address:</div>
                    <div className="col-md-8 col-12">
                      85 Saratoga Avenue,
                      <br />
                      Santa Clara,
                      <br />
                      CA-95051, USA
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 col-4">Phone:</div>
                    <div className="col-md-8 col-12">
                      <a style={{ color: "#d0d0d0" }} href="tel:+14085200753">
                        +1 (408) 520- 0753
                      </a>
                      <br />
                      <a style={{ color: "#d0d0d0" }} href="tel:+14087247219">
                        +1 (408) 724- 7219
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 col-4">Mail:</div>
                    <div className="col-md-8 col-12">
                      <a
                        style={{ color: "#d0d0d0" }}
                        href="mailto:enquiries.us@saasvaap.com"
                      >
                        enquiries.us@saasvaap.com
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 col-4">Hours:</div>
                    <div className="col-md-8 col-12">Mon-Fri</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="copyright" className="clearfix">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div style={{ textAlign: "center" }}>
                @2022 All Rights Reserved by Saasvaap Inc.
              </div>

              <div className="copyright-right col-md-6 no-padding"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
