import React, { useState, useEffect } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import FileViewer from "react-file-viewer";
const ResumeView = ({ base64 }) => {
  const [file, setFile] = useState();
  useEffect(() => {
    if (base64) {
      viewDocument(); // eslint-disable-next-line
    }
  }, [base64]);
  const viewDocument = () => {
    let attachmentvalue = base64?.split(";");
    let converted = attachmentvalue[0];
    if (converted === "data:application/pdf") {
      setFile(`file.pdf`);
    } else if (converted === "data:image/jpeg") {
      setFile(`file.jpg`);
    } else if (converted === "data:image/png") {
      setFile(`file.jpg`);
    } else {
      setFile(`file.docx`);
    }
  };
  const resumeDownload = () => {
    var url = base64.replace(
      /^data:image\/[^;]+/,
      "data:application/octet-stream"
    );
    window.open(url);
  };

  return (
    <div>
      <div style={{ width: 1000, height: "100%" }}>
        {file === `file.pdf` ? (
          <object
            data={base64 ? base64 : ""}
            type="application/pdf"
            style={{ width: 1000, height: 900 }}
          ></object>
        ) : file === `file.jpg` ? (
          <div className="test">
            <DownloadIcon
              style={{
                color: "skyblue",
                cursor: "pointer",
                float: "right",
                fontSize: 35,
              }}
              onClick={resumeDownload}
              name="download"
            />
            <object
              data={base64 ? base64 : ""}
              style={{ width: "100%", height: "100%" }}
            ></object>
          </div>
        ) : file === `file.docx` ? (
          <div>
            <DownloadIcon
              style={{
                color: "skyblue",
                cursor: "pointer",
                float: "right",
                fontSize: 35,
              }}
              onClick={resumeDownload}
              name="download"
            />
            <FileViewer fileType="docx" filePath={base64} />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default ResumeView;
