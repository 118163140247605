import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import "../../../../assets/styles/css/AdminDashboard.css";
import AdminHeader from "../../../../layouts/admin/Header";
import directoryImg from "../../../../assets/images/Dashboard/directoryimg.png";
import CustomButton from "../../../../components/customComponents/common/CustomButton";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useEffect, useState } from "react";
import API_CONFIG_VAR from "../../../../config/API_URL_CONFIG.json";
import { getAPIHandler } from "../../../../apiHandler/RestAPIHandler";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomSnackbar from "../../../../components/customComponents/common/CustomSnackbar";

const AdminDashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [notificationData, setNotificationData] = useState({
    message: "",
    severity: "",
  });
  const [jobList, setJobList] = useState([]);
  const [dasboardCounts, setDasboardCounts] = useState({
    draft: null,
    active: null,
    allResumes: null,
    shortListed: null,
  });

  const getDashboardDetails = () => {
    const URL = API_CONFIG_VAR.ADMIN_DASHBOARD;
    getAPIHandler(URL).then((result) => {
      if (result?.response?.status === 200) {
        setJobList(result?.response?.data?.data.jobList);
        setDasboardCounts({
          ...dasboardCounts,
          active: result?.response?.data?.data?.activeCount,
          draft: result?.response?.data?.data?.draftCount,
          allResumes: result?.response?.data?.data?.allResumesCount,
          shortListed: result?.response?.data?.data?.shortListedCount,
          interviewShortlisted:
            result?.response?.data?.data?.interviewShortListedCount,
          interviewSelected:
            result?.response?.data?.data?.interviewSelectedCount,
        });
      } else if (result.response.status === 401) {
        setOpenSnackbar(true);
        setNotificationData({
          message: "Session Expired, Please login",
          severity: "error",
        });
        setTimeout(() => {
          window.location.replace("/admin-console-login");
          localStorage.clear();
        }, 1000);
      } else {
        setJobList([]);
      }
    });
  };
  useEffect(() => {
    let token = localStorage.getItem("jwt_access_token");

    if (!location?.state?.isToken && !token) {
      navigate("/admin-console-login");
    }
    getDashboardDetails();
    // eslint-disable-next-line
  }, []);
  const handleClose = () => {
    setOpenSnackbar(false);
  };
  return (
    <>
      <AdminHeader />
      {/* Custom notification snackbar */}
      <CustomSnackbar
        openSnackbar={openSnackbar}
        message={notificationData?.message}
        handleClose={handleClose}
        severity={notificationData?.severity}
      />

      <div className="bx">
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
              <div className="dashboard-card-container">
                <div className="dashboard-card">
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={4}
                  >
                    <Grid item xs={6} sm={2}>
                      <img src={directoryImg} style={{ width: 60 }} alt="" />
                    </Grid>
                    <Grid item xs={6} sm={7}>
                      <h4 className="dashboard-card-head">Jobs Directory</h4>
                    </Grid>
                    <Grid item xs={6} sm={5}>
                      <p
                        className="card-p"
                        style={{ marginTop: -71, marginLeft: 36 }}
                      >
                        Draft Jobs : {dasboardCounts?.draft}
                      </p>
                      <p
                        className="card-p"
                        style={{ marginTop: -10, marginLeft: 36 }}
                      >
                        Active Jobs: {dasboardCounts?.active}
                      </p>
                    </Grid>
                  </Grid>
                  <div
                    style={{
                      marginTop: -10,
                      marginLeft: 145,
                    }}
                  >
                    <Link
                      to="/jobs"
                      state={{ isToken: true }}
                      style={{ textDecoration: "none" }}
                    >
                      <CustomButton name="View More" size="small" />
                    </Link>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={6} md={4}>
              <div className="dashboard-card-container">
                <div className="dashboard-card">
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={4}
                  >
                    <Grid item xs={6} sm={2}>
                      <img src={directoryImg} style={{ width: 60 }} alt="" />
                    </Grid>
                    <Grid item xs={6} sm={8}>
                      <h4 className="dashboard-card-head">Resumes Directory</h4>
                    </Grid>

                    <Grid item xs={6} sm={5}>
                      <p
                        className="card-p"
                        style={{ marginTop: -71, marginLeft: 36 }}
                      >
                        All Resumes : {dasboardCounts?.allResumes}
                      </p>
                      <p
                        className="card-p"
                        style={{ marginTop: -10, marginLeft: 36 }}
                      >
                        Short Listed: {dasboardCounts?.shortListed}
                      </p>
                    </Grid>
                  </Grid>
                  <div
                    style={{
                      marginTop: -10,
                      marginLeft: 145,
                    }}
                  >
                    <Link
                      to="/resumes-directory"
                      state={{ isToken: true }}
                      style={{ textDecoration: "none" }}
                    >
                      <CustomButton name="View More" size="small" />
                    </Link>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={6} md={4}>
              <div className="dashboard-card-container">
                {/* <div className="dashboard-card">
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={4}
                  >
                    <Grid item xs={6} sm={2}>
                      <img src={directoryImg} style={{ width: 60 }} alt="" />
                    </Grid>
                    <Grid item xs={6} sm={8}>
                      <h4 className="dashboard-card-head">
                        Interview Schedule
                      </h4>
                    </Grid>

                    <Grid item xs={6} sm={5}>
                      <p
                        className="card-p"
                        style={{ marginTop: -71, marginLeft: 36 }}
                      >
                        Shorlisted : {dasboardCounts?.interviewShortlisted}
                      </p>
                      <p
                        className="card-p"
                        style={{ marginTop: -10, marginLeft: 36 }}
                      >
                        Selected : {dasboardCounts?.interviewSelected}
                      </p>
                    </Grid>
                  </Grid>
                  <div
                    style={{
                      marginTop: -10,
                      marginLeft: 145,
                    }}
                  >
                    <Link
                      to="/interview-directory"
                      state={{ isToken: true }}
                      style={{ textDecoration: "none" }}
                    >
                      <CustomButton name="View More" size="small" />
                    </Link>
                  </div>
                </div> */}
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Container maxWidth="lg" style={{ marginTop: 110, marginBottom: 20 }}>
        <div className="rectangle">
          <div className="frame">
            <p className="current-opening-head">Current Openings </p>

            <Link to="/jobs" state={{ tab: "active", isToken: true }}>
              <p className="viewall">View All</p>
            </Link>
          </div>
          <div>
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="center"
            >
              {jobList.map((item, i) => {
                return (
                  <Grid item sm={3} key={item?.id}>
                    <div style={{ width: 250 }} className="card-dashboard">
                      <div className="card-body">
                        <>
                          <h5 className="card-head">{item.jobTitle}</h5>
                          <p className="card-p">
                            Exp: {item.minimumExperience}-
                            {item.maximumExperience} Yrs
                          </p>
                          <p className="card-p">
                            <LocationOnIcon color="primary" fontSize="small" />

                            {item.location.length > 1
                              ? item.location.join(", ")
                              : item.location}
                          </p>
                        </>
                      </div>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </div>
      </Container>
    </>
  );
};
export default AdminDashboard;
