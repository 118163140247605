import React, { useState, useEffect } from "react";
import "../../../assets/styles/css/ApplyJobPage.css";
import TopBar from "../../../layouts/Header/TopBar";
import Navbar from "../../../layouts/Header/Navbar";
import Footer from "../../../layouts/Footer";
import CustomTextField from "../../../components/customComponents/common/CustomTextField";
import {
  Autocomplete,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import CustomButton from "../../../components/customComponents/common/CustomButton";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { noticePeriodData, customMessages } from "../../../CustomDatas";
import {
  postAPIHandler,
  getAPIHandler,
} from "../../../apiHandler/RestAPIHandler";
import API_URL_CONFIG from "../../../config/API_URL_CONFIG.json";
import validation from "./Validation";
import { useNavigate, useParams } from "react-router-dom";
import CustomSnackbar from "../../../components/customComponents/common/CustomSnackbar";
const ApplyJobPage = ({
  handleCloseButton,
  getApplicantList,
  userDetails,
  dialogType,
  applicantList,
  appliedCandidateDetails,
}) => {
  let navigate = useNavigate();
  const { jobId, jobTitle } = useParams();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errors, setErrors] = useState({});
  const [validationTrigger, setValidationTrigger] = useState(false);

  const [keySkillsList, setKeySkillsList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [qualificationList, setQualificationList] = useState([]);

  useEffect(() => {
    getKeySkills();
    getLocation();
    getQualification();
  }, []);

  const getKeySkills = () => {
    getAPIHandler(API_URL_CONFIG.KEYSKILLS_LIST, {
      responseType: "arraybuffer",
    }).then((result) => {
      const keySkillsArray = result.response.data.data.map(
        (item) => item.keySkills
      );
      setKeySkillsList(keySkillsArray);
    });
  };
  const getLocation = () => {
    getAPIHandler(API_URL_CONFIG.LOCATION_LIST, {
      responseType: "arraybuffer",
    }).then((result) => {
      const locationArray = result.response.data.data.map(
        (item) => item.location
      );
      setLocationList(locationArray);
    });
  };
  const getQualification = () => {
    getAPIHandler(API_URL_CONFIG.QUALIFICATION_LIST, {
      responseType: "arraybuffer",
    }).then((result) => {
      const qualificationArray = result.response.data.data.map(
        (item) => item.qualification
      );
      setQualificationList(qualificationArray);
    });
  };

  let initialStates = {
    fullName: "",
    email: "",
    qualification: "",
    keySkills: [],
    phone: "",
    expectedCTC: "",
    currentCTC: "",
    location: null,
    experienceInYear: null,
    experienceInMonth: null,
    relevantExperienceInYear: null,
    relevantExperienceInMonth: null,
    noticeperiod: null,
    timeshift: "any",
    ctcNegotiable: "true",
    jobType: "",
    resume: "",
    resumeName: "",
    position: "",
  };
  const [applicantDetails, setApplicantDetails] = useState(initialStates);
  const [notificationData, setNotificationData] = useState({
    message: "",
    severity: "",
  });
  // const textChange = (event) => {
  //   const { name, value } = event.target;
  //   setApplicantDetails({
  //     ...applicantDetails,
  //     [name]: value,
  //   });
  // };

  const applyJob = (e) => {
    setValidationTrigger(true);

    const postData = {
      fullName: applicantDetails.fullName,
      email: applicantDetails.email,
      qualification: applicantDetails.qualification,
      phone: applicantDetails.phone,
      expectedCTC: applicantDetails.expectedCTC,
      currentCTC: applicantDetails.currentCTC,
      location: applicantDetails.location,
      experienceInYear: applicantDetails.experienceInYear,
      experienceInMonth: applicantDetails.experienceInMonth,
      relevantExperienceInYear: applicantDetails.relevantExperienceInYear,
      relevantExperienceInMonth: applicantDetails.relevantExperienceInMonth,
      affordableTime: applicantDetails.timeshift,
      resume: applicantDetails.resume,
      noticeperiod: applicantDetails.noticeperiod,
      negotiable: applicantDetails.ctcNegotiable,
      jobType: applicantDetails.jobType,
      jobId: jobId ? jobId : "fresher",
      jobTitle: jobTitle ? jobTitle : "open",
      keySkills: applicantDetails.keySkills,
      position: applicantDetails.position,
    };
    const URL = API_URL_CONFIG.APPLY_JOB;
    let validationError = validation(applicantDetails);
    if (!validationError.error) {
      postAPIHandler(URL, postData)
        .then((result) => {
          if (result.response.status === 200) {
            return (
              setOpenSnackbar(true),
              setNotificationData({
                message: customMessages.jobApplySuccess,
                severity: customMessages.severitySuccess,
              }),
              setTimeout(() => {
                navigate("/");
              }, 2000)
            );
          } else if (result.response.status === 401) {
            setOpenSnackbar(true);
            setNotificationData({
              message: "Session Expired, Please login",
              severity: "error",
            });
            setTimeout(() => {
              window.location.replace("/admin-console-login");
              localStorage.clear();
            }, 1000);
          } else if (result.response.status === 412) {
            return (
              setOpenSnackbar(true),
              setNotificationData({
                message: customMessages.commonError,
                severity: customMessages.severityError,
              })
            );
          }
        })
        .catch(() => {
          setOpenSnackbar(true);
          setNotificationData({
            message: customMessages.commonError,
            severity: customMessages.severityError,
          });
        });
    } else {
      return (
        setOpenSnackbar(true),
        setNotificationData({
          message: customMessages.formFillError,
          severity: customMessages.severityError,
        })
      );
    }
  };
  useEffect(() => {
    if (validationTrigger) setErrors(validation(applicantDetails));
  }, [applicantDetails, validationTrigger]);
  //console.log("applicvat", applicantDetails);

  const textChange = (event) => {
    const { name, value } = event.target;
    setApplicantDetails({
      ...applicantDetails,
      [name]: value,
    });
  };

  const onCountryChange = (event, value) => {
    setApplicantDetails({
      ...applicantDetails,
      location: value,
    });
  };

  const noticePeriodChange = (event, value) => {
    setApplicantDetails({
      ...applicantDetails,
      noticeperiod: value,
    });
  };

  const timeShiftChange = (event) => {
    setApplicantDetails({
      ...applicantDetails,
      timeshift: event.target.value,
    });
  };
  const onCtcNegotiableChange = (event) => {
    setApplicantDetails({
      ...applicantDetails,
      ctcNegotiable: event.target.value,
    });
  };
  const onJobtypeChange = (event) => {
    setApplicantDetails({
      ...applicantDetails,
      jobType: event.target.value,
    });
  };

  const toBase64 = (files) => {
    let reader = new FileReader();
    reader.readAsDataURL(files);
    reader.onload = function () {
      let data = reader?.result;

      setApplicantDetails({
        ...applicantDetails,
        resume: data,
        resumeName: files.name,
      });
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };

    /* This sprint is not being taken. 
      (when we need to download pdf or image fom base64 encoded )   
         <embed style={{ width: 300, height: 500 }} src={image} />
         <a href={image} download="file.pdf">download pdf</a> */
  };
  const pdfUploadChange = (event) => {
    let file = event.target.files[0];
    toBase64(file);
  };
  const handleClose = () => {
    setOpenSnackbar(false);
  };
  const onChangeQualification = (event, value) => {
    setApplicantDetails({
      ...applicantDetails,
      qualification: value,
    });
  };

  const onChangeKeySkills = (event, value) => {
    setApplicantDetails({
      ...applicantDetails,
      keySkills: value,
    });
  };
  return (
    <>
      {/* Custom notification snackbar */}
      <CustomSnackbar
        openSnackbar={openSnackbar}
        message={notificationData?.message}
        handleClose={handleClose}
        severity={notificationData?.severity}
      />
      <TopBar />
      <div className="sub-hero-image">
        <div className="container">
          <div className="row">
            <Navbar color={"#fff"} />
          </div>
          <h2 id="Job-Application">Job Application</h2>
        </div>
      </div>
      <div className="Applicant-Details">
        <div className="container">
          <h3>Applicant Details</h3>
          <form className="g-3">
            <div className="row">
              <div className="col-md-12 row">
                <div className="col-md-6">
                  <label htmlFor="inputText" className="form-label">
                    Full Name <span className="label-required">*</span>
                  </label>
                  <CustomTextField
                    name="fullName"
                    label="Full Name "
                    variant="outlined"
                    margin="normal"
                    size="small"
                    autoFocus
                    fullWidth
                    disabled={dialogType === "AppliedCandidates"}
                    value={
                      dialogType === "AppliedCandidates"
                        ? appliedCandidateDetails?.fullName
                        : applicantDetails?.fullName
                    }
                    //value={applicantDetails.fullName}
                    onChange={textChange}
                  />
                  {errors.fullName && (
                    <span className="error-span">{errors.fullName}</span>
                  )}
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputEmail4" className="form-label">
                    Email <span className="label-required">*</span>
                  </label>
                  <CustomTextField
                    name="email"
                    label="Email Address"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    fullWidth
                    type="email"
                    disabled={dialogType === "AppliedCandidates"}
                    value={
                      dialogType === "AppliedCandidates"
                        ? appliedCandidateDetails?.email
                        : applicantDetails?.email
                    }
                    //value={applicantDetails.email}
                    onChange={textChange}
                  />
                  {errors.email && (
                    <span className="error-span">{errors.email}</span>
                  )}
                </div>

                <div className="col-md-6">
                  <label htmlFor="inputText" className="form-label">
                    Position / Role
                  </label>
                  <CustomTextField
                    name="position"
                    label="Position / Role "
                    variant="outlined"
                    margin="normal"
                    size="small"
                    fullWidth
                    disabled={dialogType === "AppliedCandidates"}
                    value={
                      dialogType === "AppliedCandidates"
                        ? appliedCandidateDetails?.position
                        : applicantDetails?.position
                    }
                    //value={applicantDetails.position}
                    onChange={textChange}
                  />
                </div>

                <div className="col-md-6">
                  <label htmlFor="inputText" className="form-label">
                    Qualification <span className="label-required">*</span>
                  </label>
                  <Autocomplete
                    id="multiple-limit-tags"
                    className="form-label-height"
                    options={qualificationList}
                    size="small"
                    margin="normal"
                    onChange={onChangeQualification}
                    disabled={dialogType === "AppliedCandidates"}
                    value={
                      dialogType === "AppliedCandidates"
                        ? appliedCandidateDetails?.qualification
                        : applicantDetails?.qualification
                    }
                    //value={applicantDetails?.qualification}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField {...params} label="Qualification" />
                    )}
                  />
                  {errors.qualification && (
                    <span className="error-span">{errors.qualification}</span>
                  )}
                </div>

                <div className="col-md-6">
                  <label htmlFor="inputText" className="form-label">
                    Skills
                  </label>
                  <Autocomplete
                    multiple
                    id="multiple-limit-tags"
                    className="form-label-height"
                    options={keySkillsList}
                    size="small"
                    margin="normal"
                    onChange={onChangeKeySkills}
                    disabled={dialogType === "AppliedCandidates"}
                    value={
                      dialogType === "AppliedCandidates"
                        ? appliedCandidateDetails?.keySkills
                        : applicantDetails?.keySkills
                    }
                    //value={applicantDetails?.keySkills}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField {...params} label="Skills" />
                    )}
                  />
                </div>

                <div className="col-md-6">
                  <label htmlFor="inputState" className="form-label">
                    Preferred Location <span className="label-required">*</span>
                  </label>
                  <Autocomplete
                    className="form-label-height"
                    disablePortal
                    id="combo-box-demo"
                    options={locationList}
                    onChange={onCountryChange}
                    size="small"
                    disabled={dialogType === "AppliedCandidates"}
                    value={
                      dialogType === "AppliedCandidates"
                        ? appliedCandidateDetails?.location
                        : applicantDetails?.location
                    }
                    //value={applicantDetails.location}
                    renderInput={(params) => (
                      <TextField {...params} label="Select location" />
                    )}
                  />
                  {errors.location && (
                    <span className="error-span">{errors.location}</span>
                  )}
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputNumber" className="form-label">
                    Mobile Number <span className="label-required">*</span>
                  </label>
                  <CustomTextField
                    name="phone"
                    label="Enter Mobile Number"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    fullWidth
                    disabled={dialogType === "AppliedCandidates"}
                    onChange={textChange}
                    value={
                      dialogType === "AppliedCandidates"
                        ? appliedCandidateDetails?.phone
                        : applicantDetails?.phone
                    }
                    //value={applicantDetails.phone}
                  />
                  {errors.phone && (
                    <span className="error-span">{errors.phone}</span>
                  )}
                </div>
              </div>
              <div className="col-md-12 row">
                <div className="col-md-6">
                  <label htmlFor="inputNumber" className="form-label">
                    Total Experience <span className="label-required">*</span>
                  </label>
                  <div className="row">
                    <div className="col-md-6">
                      <CustomTextField
                        name="experienceInYear"
                        placeholder=" Experience In Year"
                        // label=" Experience In Year"
                        variant="outlined"
                        size="small"
                        fullWidth
                        margin="normal"
                        InputProps={{ inputProps: { min: 0 } }}
                        type="Number"
                        disabled={dialogType === "AppliedCandidates"}
                        value={
                          dialogType === "AppliedCandidates"
                            ? appliedCandidateDetails?.experienceInYear
                            : applicantDetails?.experienceInYear
                        }
                        //value={applicantDetails?.experienceInYear}
                        onChange={textChange}
                      />

                      {errors.experienceInYear && (
                        <span className="error-span">
                          {errors.experienceInYear}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <CustomTextField
                        name="experienceInMonth"
                        //label="  Experience In Month"
                        variant="outlined"
                        size="small"
                        fullWidth
                        margin="normal"
                        InputProps={{ inputProps: { min: 0 } }}
                        type="Number"
                        disabled={dialogType === "AppliedCandidates"}
                        value={
                          dialogType === "AppliedCandidates"
                            ? appliedCandidateDetails?.experienceInMonth
                            : applicantDetails?.experienceInMonth
                        }
                        //value={applicantDetails?.experienceInMonth}
                        onChange={textChange}
                      />
                      {errors.experienceInMonth && (
                        <span className="error-span">
                          {errors.experienceInMonth}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputNumber" className="form-label">
                    Relevant Experience{" "}
                    <span className="label-required">*</span>
                  </label>
                  <div className="row">
                    <div className="col-md-6">
                      <CustomTextField
                        name="relevantExperienceInYear"
                        placeholder=" Experience In Year"
                        // label=" Experience In Year"
                        variant="outlined"
                        size="small"
                        fullWidth
                        margin="normal"
                        InputProps={{ inputProps: { min: 0 } }}
                        type="Number"
                        disabled={dialogType === "AppliedCandidates"}
                        value={
                          dialogType === "AppliedCandidates"
                            ? appliedCandidateDetails?.relevantExperienceInYear
                            : applicantDetails?.relevantExperienceInYear
                        }
                        onChange={textChange}
                      />

                      {errors.relevantExperienceInYear && (
                        <span className="error-span">
                          {errors.relevantExperienceInYear}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <CustomTextField
                        name="relevantExperienceInMonth"
                        //label="  Experience In Month"
                        variant="outlined"
                        size="small"
                        fullWidth
                        margin="normal"
                        InputProps={{ inputProps: { min: 0 } }}
                        type="Number"
                        disabled={dialogType === "AppliedCandidates"}
                        value={
                          dialogType === "AppliedCandidates"
                            ? appliedCandidateDetails?.relevantExperienceInMonth
                            : applicantDetails?.relevantExperienceInMonth
                        }
                        onChange={textChange}
                      />
                      {errors.relevantExperienceInMonth && (
                        <span className="error-span">
                          {errors.relevantExperienceInMonth}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-6">
                      <label htmlFor="inputState" className="form-label">
                        Notice period <span className="label-required">*</span>
                      </label>
                      <Autocomplete
                        className="form-label-height"
                        disablePortal
                        id="combo-box-demo"
                        options={noticePeriodData}
                        onChange={noticePeriodChange}
                        disabled={dialogType === "AppliedCandidates"}
                        value={
                          dialogType === "AppliedCandidates"
                            ? appliedCandidateDetails?.noticeperiod
                            : applicantDetails?.noticeperiod
                        }
                        //value={applicantDetails.noticeperiod}
                        size="small"
                        renderInput={(params) => (
                          <TextField {...params} label="Select Notice Period" />
                        )}
                      />
                      {errors.noticeperiod && (
                        <span className="error-span">
                          {errors.noticeperiod}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="inputState" className="form-label">
                        Time Shift
                      </label>
                      <RadioGroup
                        style={{ marginTop: 10 }}
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        onChange={timeShiftChange}
                        disabled={dialogType === "AppliedCandidates"}
                        value={applicantDetails.timeshift}
                        // value={
                        //   dialogType === "AppliedCandidates"
                        //     ? appliedCandidateDetails?.affordableTime
                        //     : applicantDetails?.timeshift
                        // }
                      >
                        <FormControlLabel
                          value="day"
                          control={<Radio />}
                          label="Day"
                        />
                        <FormControlLabel
                          value="night"
                          control={<Radio />}
                          label="Night"
                        />
                        <FormControlLabel
                          value="any"
                          control={<Radio />}
                          label="Any"
                        />
                      </RadioGroup>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-6">
                      <label htmlFor="inputText" className="form-label">
                        Is Expected CTC negotiable?
                      </label>
                      <RadioGroup
                        style={{ marginTop: 10 }}
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        onChange={onCtcNegotiableChange}
                        disabled={dialogType === "AppliedCandidates"}
                        // value={
                        //   dialogType === "AppliedCandidates"
                        //     ? appliedCandidateDetails?.negotiable
                        //     : applicantDetails?.ctcNegotiable
                        // }
                        value={applicantDetails.ctcNegotiable}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="inputText" className="form-label">
                        Preferred Job Type
                      </label>
                      <RadioGroup
                        style={{ marginTop: 10 }}
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        onChange={onJobtypeChange}
                        disabled={dialogType === "AppliedCandidates"}
                        value={applicantDetails.jobType}
                        // value={
                        //   dialogType === "AppliedCandidates"
                        //     ? appliedCandidateDetails?.jobType
                        //     : applicantDetails?.jobType
                        // }
                      >
                        <FormControlLabel
                          value="full_time"
                          control={<Radio />}
                          label="Full Time"
                        />
                        <FormControlLabel
                          value="part_time"
                          control={<Radio />}
                          label="Part Time"
                        />
                      </RadioGroup>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputText" className="form-label">
                    Current CTC
                  </label>

                  <CustomTextField
                    name="currentCTC"
                    label="Enter Current CTC in Lakhs Per Annum (INR)"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    fullWidth
                    disabled={dialogType === "AppliedCandidates"}
                    type="number"
                    onChange={textChange}
                    value={applicantDetails.currentCTC}
                    // value={
                    //   dialogType === "AppliedCandidates"
                    //     ? appliedCandidateDetails?.currentCTC
                    //     : applicantDetails?.currentCTC
                    // }
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputText" className="form-label">
                    Expected CTC
                  </label>
                  <CustomTextField
                    name="expectedCTC"
                    label="Enter Expected CTC in Lakhs Per Annum (INR)"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    fullWidth
                    type="number"
                    onChange={textChange}
                    disabled={dialogType === "AppliedCandidates"}
                    // value={
                    //   dialogType === "AppliedCandidates"
                    //     ? appliedCandidateDetails?.expectedCTC
                    //     : applicantDetails?.expectedCTC
                    // }
                    value={applicantDetails.expectedCTC}
                  />
                </div>
                <div className="col-md-12">
                  <br />
                  {dialogType === "AppliedCandidates" ? (
                    ""
                  ) : (
                    <div>
                      <Button
                        style={{
                          minWidth: 100,
                          minHeight: 30,
                          textTransform: "none",
                          color: "white",
                        }}
                        startIcon={<UploadFileIcon />}
                        onChange={pdfUploadChange}
                        variant="contained"
                        component="label"
                        size="small"
                      >
                        Upload Resume
                        <input
                          hidden
                          accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword"
                          type="file"
                        />
                      </Button>
                      <span className="label-required">*</span>
                      {errors.resume && (
                        <span className="error-span">{errors.resume}</span>
                      )}
                      <span style={{ fontSize: 12 }}>
                        {applicantDetails?.resumeName?.length
                          ? applicantDetails.resumeName
                          : ""}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
          {dialogType === "AppliedCandidates" ? (
            ""
          ) : (
            <div className="form-label-height">
              <CustomButton size="small" onClick={applyJob} name="Apply Now" />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};
export default ApplyJobPage;
