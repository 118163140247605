import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import CustomTextField from "../../../../components/customComponents/common/CustomTextField";
import { Grid, TextField, Autocomplete } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import CustomButton from "../../../../components/customComponents/common/CustomButton";
import "../../../../assets/styles/css/Interview.css";
import API_URL_CONFIG from "../../../../config/API_URL_CONFIG.json";
import {
  postAPIHandler,
  getAPIHandler,
} from "../../../../apiHandler/RestAPIHandler";
import { customMessages } from "../../../../CustomDatas";
import CustomSnackbar from "../../../../components/customComponents/common/CustomSnackbar";
import {
  InterviewType,
  DateFormat,
  InterviewTypeL1,
  InterviewTypeHR,
} from "../../../../CustomDatas";
import Validation from "./validation";

const ScheduleInterview = ({
  handleCloseButton,
  applicantList,
  applicantId,
  dialogType,
  validationTrigger,
  setValidationTrigger,
  getListAPI,
}) => {
  const today = dayjs();
  const tomorrow = dayjs().add(1, "day");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [notificationData, setNotificationData] = useState({
    message: "",
    severity: "",
  });
  const [errors, setErrors] = useState({});

  let initialStates = {
    startTime: "",
    endTime: "",
    date: dayjs("2023-05-04T05:45:48.325Z"),
    interviewerEmail: "",
    interviewMode: "",
  };

  const [scheduleInterview, setScheduleInterview] = useState(initialStates);

  useEffect(() => {
    if (validationTrigger) setErrors(Validation(scheduleInterview));
  }, [scheduleInterview, validationTrigger]);
  const dateChange = (value, d) => {
    setScheduleInterview({
      ...scheduleInterview,
      date:
        value instanceof Date
          ? value.toISOString()
          : dayjs(value.$d).toISOString(),
    });
  };
  const startTimeChange = (value) => {
    setScheduleInterview({ ...scheduleInterview, startTime: value });
  };
  const endTimeChange = (value, e) => {
    setScheduleInterview({ ...scheduleInterview, endTime: value });
  };
  const textChange = (event) => {
    setScheduleInterview({
      ...scheduleInterview,
      [event.target.name]: event.target.value,
    });
  };
  const onChangeInterviewMode = (event, value) => {
    setScheduleInterview({
      ...scheduleInterview,
      interviewMode: value,
    });
  };
  useEffect(() => {
    if (dialogType === "Re-Schedule") {
      setScheduleInterview(initialStates);
      getScheduleDetails();
    } else {
      setScheduleInterview(initialStates);
    }
  }, [dialogType]);
  // schedulded detials by id

  const getScheduleDetails = () => {
    const URL =
      API_URL_CONFIG.GET_INTERVIEW_SCHEDULED_DETAILS +
      "?_id=" +
      applicantId?._id;
    getAPIHandler(URL)
      .then((result) => {
        if (result.message === "Network Error") {
          setScheduleInterview({});
        } else {
          setScheduleInterview(result?.response?.data?.data);
        }
        if (result.response.status === 401) {
          setOpenSnackbar(true);
          setNotificationData({
            message: "Session Expired, Please login",
            severity: "error",
          });
          setTimeout(() => {
            window.location.replace("/admin-console-login");
            localStorage.clear();
          }, 1000);
        }
      })
      .catch((err) => {
        setScheduleInterview({});
      });
  };

  //schedule or reschedule interview
  const AddOrEditApi = () => {
    setValidationTrigger(true);
    let validationError = Validation(scheduleInterview);
    setErrors(validationError);
    const postData = {
      _id: applicantId._id,
      startTime: scheduleInterview.startTime,
      endTime: scheduleInterview.endTime,
      date: scheduleInterview.date,
      interviewerEmail: scheduleInterview.interviewerEmail,
      intervieweeEmail: applicantId.email,
      interviewMode: scheduleInterview.interviewMode,
    };
    if (!validationError?.error) {
      const URL = API_URL_CONFIG.INTERVIEW_SCHEDULE;
      postAPIHandler(URL, postData)
        .then((result) => {
          if (result.response.status === 200) {
            return (
              setOpenSnackbar(true),
              setNotificationData({
                message:
                  dialogType === "Re-Schedule"
                    ? customMessages.interviewReScheduleSuccess
                    : customMessages.interviewScheduleSuccess,
                severity: customMessages.severitySuccess,
              }),
              setTimeout(() => {
                setScheduleInterview(initialStates);
                getListAPI(1);
                handleCloseButton();
              }, 1000)
            );
          } else if (result.response.status === 401) {
            setOpenSnackbar(true);
            setNotificationData({
              message: "Session Expired, Please login",
              severity: "error",
            });
            setTimeout(() => {
              window.location.replace("/admin-console-login");
              localStorage.clear();
            }, 1000);
          } else if (result.response.status === 412) {
            return (
              setOpenSnackbar(true),
              setNotificationData({
                message: customMessages.commonError,
                severity: customMessages.severityError,
              })
            );
          }
        })
        .catch(() => {
          setOpenSnackbar(true);
          setNotificationData({
            message: customMessages.commonError,
            severity: customMessages.severityError,
          });
        });
    } else {
      return (
        setOpenSnackbar(true),
        setNotificationData({
          message: customMessages.formFillError,
          severity: customMessages.severityError,
        })
      );
    }
  };
  const handleClose = () => {
    setOpenSnackbar(false);
  };
  return (
    <>
      {/* Custom notification snackbar */}
      <CustomSnackbar
        openSnackbar={openSnackbar}
        message={notificationData?.message}
        handleClose={handleClose}
        severity={notificationData?.severity}
      />

      <Grid
        style={{ maxWidth: 400 }}
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid xs={12} style={{ marginBottom: 20 }}>
          <label htmlFor="inputText" className="form-label">
            Interview Type
            <span className="label-required">*</span>
          </label>

          {applicantId.interviewStatus === "shortlisted" ? (
            <Autocomplete
              disablePortal
              className="input-field"
              id="combo-box-demo"
              options={InterviewType}
              onChange={onChangeInterviewMode}
              size="small"
              value={scheduleInterview?.interviewMode}
              renderInput={(params) => (
                <TextField {...params} label="interviewType" />
              )}
            />
          ) : applicantId.interviewStatus === "L1 completed" ? (
            <Autocomplete
              disablePortal
              className="input-field"
              id="combo-box-demo"
              options={InterviewTypeL1}
              onChange={onChangeInterviewMode}
              size="small"
              value={scheduleInterview?.interviewMode}
              renderInput={(params) => (
                <TextField {...params} label="interviewType" />
              )}
            />
          ) : applicantId.interviewStatus === "L2 completed" ? (
            <Autocomplete
              disablePortal
              className="input-field"
              id="combo-box-demo"
              options={InterviewTypeHR}
              onChange={onChangeInterviewMode}
              size="small"
              value={scheduleInterview?.interviewMode}
              renderInput={(params) => (
                <TextField {...params} label="interviewType" />
              )}
            />
          ) : (
            ""
          )}

          {errors?.scheduleInterview?.interviewMode && (
            <span className="error-span">
              {errors.scheduleInterview.interviewMode}
            </span>
          )}
        </Grid>
        <Grid xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label=" Interview  Date"
              className="input-field"
              inputFormat={DateFormat}
              size="small"
              value={dayjs(scheduleInterview?.date)}
              onChange={dateChange}
              renderInput={(params) => (
                <TextField {...params} fullWidth margin="normal" size="small" />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["TimePicker"]}>
              <TimePicker
                label="Interview start time"
                className="input-field"
                value={scheduleInterview?.startTime}
                onChange={startTimeChange}
              />
            </DemoContainer>
          </LocalizationProvider>
          {errors?.scheduleInterview?.startTime && (
            <span className="error-span">
              {errors.scheduleInterview.startTime}
            </span>
          )}
        </Grid>
        <Grid xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["TimePicker"]}>
              <TimePicker
                label="Interview End time"
                className="input-field"
                value={scheduleInterview?.endTime}
                onChange={endTimeChange}
              />
            </DemoContainer>
          </LocalizationProvider>
          {errors?.scheduleInterview?.endTime && (
            <span className="error-span">
              {errors.scheduleInterview.endTime}
            </span>
          )}
        </Grid>
        <Grid xs={12}>
          <CustomTextField
            className="input-field"
            name="interviewerEmail"
            label="Interviewer Email Address"
            variant="outlined"
            margin="normal"
            size="small"
            type="email"
            onChange={textChange}
            value={scheduleInterview?.interviewerEmail}
          />
          {errors?.scheduleInterview?.interviewerEmail && (
            <span className="error-span">{errors.interviewerEmail}</span>
          )}
        </Grid>
      </Grid>
      <Grid
        style={{ marginTop: 30 }}
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid xs={3} sm={3}>
          <CustomButton
            onClick={handleCloseButton}
            color="secondary"
            name="Cancel"
          />
        </Grid>
        <Grid xs={3} sm={6}>
          <CustomButton
            name={dialogType === "Re-Schedule" ? "Re-Schedule" : "Schedule"}
            onClick={AddOrEditApi}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default ScheduleInterview;
