import React, { useEffect, useState } from "react";
import "../../../assets/styles/css/JobDescriptionPage.css";
import TopBar from "../../../layouts/Header/TopBar";
import Navbar from "../../../layouts/Header/Navbar";
import Footer from "../../../layouts/Footer";
import CustomButton from "../../../components/customComponents/common/CustomButton";
import { Link } from "react-router-dom";
import { postNoAuthAPIHandler } from "../../../apiHandler/RestAPIHandler";
import API_URL_CONFIG from "../../../config/API_URL_CONFIG.json";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  JobDiscriptionBodySkeleton,
  JobDiscriptionHeadSkeleton,
} from "../../../components/Skeleton/public";
import { useParams } from "react-router-dom";

const JobDescriptionPage = () => {
  const { _id } = useParams();
  const [jobDescriptionData, setJobDescriptionData] = useState({});
  const [discriptionLoader, setDiscriptionLoader] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setDiscriptionLoader(true);

    const URL = API_URL_CONFIG.JOB_DESCRIPTION;
    postNoAuthAPIHandler(URL, { _id })
      .then((result) => {
        setDiscriptionLoader(false);
        if (result.message === "Network Error") {
          setJobDescriptionData({});
        } else {
          setJobDescriptionData(result?.response?.data?.data);
        }
      })
      .catch((err) => {
        setDiscriptionLoader(false);
        setJobDescriptionData({});
      });
  }, [_id]);

  return (
    <>
      <TopBar />
      <div className="sub-hero-image">
        <div className="container">
          <div className="row">
            <Navbar color={"#fff"} />
          </div>
          {discriptionLoader ? (
            <JobDiscriptionHeadSkeleton />
          ) : (
            <>
              <h2>{jobDescriptionData?.jobTitle}</h2>
              <p>{jobDescriptionData?.jobId}</p>
              <p>
                <LocationOnIcon color="error" fontSize="small" />

                {jobDescriptionData.location
                  ? jobDescriptionData?.location.length > 1
                    ? jobDescriptionData?.location.join(", ")
                    : jobDescriptionData.location
                  : ""}
              </p>
            </>
          )}
        </div>
      </div>
      {discriptionLoader ? (
        <JobDiscriptionBodySkeleton />
      ) : (
        <div className="career-info">
          <div className="container">
            <div className="career-details-content">
              <div className="block-wrapper">
                <div className="block-item">
                  {jobDescriptionData?.jobDescription ? (
                    <>
                      <h3 className="main-title">Job Description</h3>

                      <p>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: jobDescriptionData?.jobDescription,
                          }}
                        ></div>
                      </p>
                    </>
                  ) : (
                    ""
                  )}
                  {jobDescriptionData?.keySkills?.length ? (
                    <>
                      <h3 className="sub-title">Skills Required</h3>
                      <p>
                        {jobDescriptionData?.keySkills
                          ? jobDescriptionData?.keySkills.join(", ")
                          : ""}
                      </p>
                    </>
                  ) : (
                    ""
                  )}
                  {jobDescriptionData?.minimumExperience &&
                  jobDescriptionData?.maximumExperience ? (
                    <>
                      <h3 className="sub-title">Experience</h3>
                      <p className="location-details">
                        {jobDescriptionData?.minimumExperience}-
                        {jobDescriptionData?.maximumExperience}
                      </p>
                    </>
                  ) : (
                    ""
                  )}
                  {jobDescriptionData.qualification ? (
                    jobDescriptionData?.qualification.length ? (
                      <>
                        <h3 className="sub-title">Qualification</h3>
                        <p className="location-details">
                          {jobDescriptionData?.qualification
                            ? jobDescriptionData?.qualification.join(", ")
                            : ""}
                        </p>
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  {jobDescriptionData?.location ? (
                    <>
                      <h3 className="sub-title"> Location</h3>
                      <p className="location-details">
                        {jobDescriptionData.location
                          ? jobDescriptionData?.location.length > 1
                            ? jobDescriptionData?.location.join(", ")
                            : jobDescriptionData.location
                          : ""}
                      </p>
                    </>
                  ) : (
                    ""
                  )}
                  {jobDescriptionData?.workMode ? (
                    <>
                      <h3 className="sub-title"> Work Mode</h3>
                      <p className="location-details">
                        {jobDescriptionData?.workMode}
                      </p>
                    </>
                  ) : (
                    ""
                  )}

                  <h3 className="sub-title">Work Type</h3>
                  <p className="location-details">
                    {jobDescriptionData
                      ? jobDescriptionData.partTime === true
                        ? "Part Time"
                        : jobDescriptionData.fullTime === true
                        ? "FullTime"
                        : "Any"
                      : ""}
                  </p>
                  {jobDescriptionData?.timeShift ? (
                    <>
                      <h3 className="sub-title">Time Shift</h3>
                      <p className="location-details">
                        {jobDescriptionData?.timeShift}
                      </p>
                    </>
                  ) : (
                    ""
                  )}
                  <div style={{ marginBottom: 20 }}>
                    <Link
                      style={{ textDecoration: "none" }}
                      to={`/job-apply/${jobDescriptionData.jobId}/${jobDescriptionData.jobTitle}`}
                    >
                      <CustomButton size="small" name="Apply Now" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};
export default JobDescriptionPage;
