import React from "react";
import {
  ListSkeleton,
  PaginationSkeleton,
} from "../../../components/Skeleton/admin";
import { Pagination } from "@mui/material";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";

// all resumes start
export const AllResumes = ({
  listLoader,
  applicantList,
  OpenDialog,
  ListEmptyState,
  CustomButton,
  pageNo,
  paginationChange,
  applicantListCount,
}) => {
  return (
    <div className="table-responsive-xl">
      <div className="wrap-table100">
        <div className="shadow table100" style={{ minHeight: 300 }}>
          <table>
            <thead>
              <tr className="table100-head">
                <th scope="col" className="column">
                  SL No.
                </th>
                <th scope="col" className="column-td">
                  Name
                </th>
                <th scope="col" className="column-td">
                  Email
                </th>
                <th scope="col" className="column-td">
                  Job Title
                </th>
                <th scope="col" className="column-td">
                  Job ID
                </th>
                <th scope="col" className="column-td">
                  Experience
                </th>
                <th scope="col" className="column-td">
                  Location
                </th>
                <th scope="col" className="column">
                  Resume
                </th>
                <th scope="col" className="column-td">
                  Status
                </th>
              </tr>
            </thead>
            {listLoader ? (
              <ListSkeleton />
            ) : applicantList ? (
              applicantList.length ? (
                applicantList.map((item, i) => {
                  return (
                    <tbody key={i}>
                      <tr>
                        <td className="column">
                          {pageNo === 1 ? i + 1 : (pageNo - 1) * 10 + (i + 1)}
                        </td>

                        <td
                          style={{
                            color: "rgba(54, 171, 155)",
                          }}
                          className="column-td"
                          onClick={() =>
                            OpenDialog("AppliedCandidatesPage", item)
                          }
                        >
                          {" "}
                          {item?.fullName}
                        </td>
                        <td className="column-td"> {item?.email}</td>
                        <td className="column-td"> {item?.jobTitle}</td>
                        <td className="column-td"> {item?.jobId}</td>
                        <td className="column-td">
                          {item?.experienceInYear}
                          {item?.experienceInYear === "1"
                            ? " year "
                            : " years "}
                          & {item?.experienceInMonth}
                          {item?.experienceInMonth === "1"
                            ? " month"
                            : " months"}
                        </td>
                        <td className="column-td"> {item?.location}</td>

                        <td className="column">
                          <SimCardDownloadIcon
                            onClick={() => OpenDialog("ReadResume", item)}
                            color="primary"
                          />
                        </td>
                        <td className="column-td">
                          {item?.applicantStatus === "applied"
                            ? "Applied"
                            : item?.applicantStatus === "rejected"
                            ? "Rejected"
                            : item?.applicantStatus === "shortlisted"
                            ? "Shortlisted"
                            : ""}
                        </td>
                      </tr>
                    </tbody>
                  );
                })
              ) : (
                <ListEmptyState emptyState="No results found" />
              )
            ) : (
              <ListEmptyState emptyState="No results found" />
            )}
          </table>
        </div>
        {listLoader ? (
          <PaginationSkeleton />
        ) : applicantList ? (
          applicantListCount > 5 ? (
            <div className="job-list-pagination">
              <Pagination
                count={
                  applicantListCount < 10
                    ? 1
                    : Math.ceil(applicantListCount / 10)
                }
                onChange={paginationChange}
                page={pageNo}
                color="primary"
                size="small"
              />
            </div>
          ) : (
            <div style={{ marginBottom: 20 }} />
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
// all resumes end
// shortlisted resumes start

export const ShortListResumes = ({
  listLoader,
  applicantList,
  OpenDialog,
  ListEmptyState,
  CustomButton,
  pageNo,
  paginationChange,
  applicantListCount,
}) => {
  return (
    <div className="table-responsive-xl">
      <div className="wrap-table100">
        <div className="shadow table100" style={{ minHeight: 300 }}>
          <table>
            <thead>
              <tr className="table100-head">
                <th scope="col" className="column">
                  SL No.
                </th>
                <th scope="col" className="column-td">
                  Name
                </th>
                <th scope="col" className="column-td">
                  Email
                </th>
                <th scope="col" className="column-td">
                  Job Title
                </th>
                <th scope="col" className="column-td">
                  Job ID
                </th>
                <th scope="col" className="column-td">
                  Experience
                </th>
                <th scope="col" className="column-td">
                  Location
                </th>
                <th scope="col" className="column">
                  Resume
                </th>
                <th scope="col" className="column-td">
                  Status
                </th>
              </tr>
            </thead>
            {listLoader ? (
              <ListSkeleton />
            ) : applicantList ? (
              applicantList.length ? (
                applicantList.map((item, i) => {
                  return (
                    <tbody key={i}>
                      <tr>
                        <td className="column">
                          {pageNo === 1 ? i + 1 : (pageNo - 1) * 10 + (i + 1)}
                        </td>

                        <td
                          style={{
                            color: "rgba(54, 171, 155)",
                          }}
                          className="column-td"
                          onClick={() =>
                            OpenDialog("AppliedCandidatesPage", item)
                          }
                        >
                          {" "}
                          {item?.fullName}
                        </td>
                        <td className="column-td"> {item?.email}</td>
                        <td className="column-td"> {item?.jobTitle}</td>
                        <td className="column-td"> {item?.jobId}</td>
                        <td className="column-td">
                          {item?.experienceInYear}
                          {item?.experienceInYear === "1"
                            ? " year "
                            : " years "}
                          & {item?.experienceInMonth}
                          {item?.experienceInMonth === "1"
                            ? " month"
                            : " months"}
                        </td>
                        <td className="column-td"> {item?.location}</td>

                        <td className="column">
                          <SimCardDownloadIcon
                            onClick={() => OpenDialog("ReadResume", item)}
                            color="primary"
                          />
                        </td>
                        <td className="column-td">
                          {item?.applicantStatus === "shortlisted"
                            ? "Shortlisted"
                            : ""}
                        </td>
                      </tr>
                    </tbody>
                  );
                })
              ) : (
                <ListEmptyState emptyState="No results found" />
              )
            ) : (
              <ListEmptyState emptyState="No results found" />
            )}
          </table>
        </div>
        {listLoader ? (
          <PaginationSkeleton />
        ) : applicantList ? (
          applicantListCount > 5 ? (
            <div className="job-list-pagination">
              <Pagination
                count={
                  applicantListCount < 10
                    ? 1
                    : Math.ceil(applicantListCount / 10)
                }
                onChange={paginationChange}
                page={pageNo}
                color="primary"
                size="small"
              />
            </div>
          ) : (
            <div style={{ marginBottom: 20 }} />
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
// short listed resumes end

// rejected resumes start

export const RejectedResumes = ({
  listLoader,
  applicantList,
  OpenDialog,
  ListEmptyState,
  CustomButton,
  pageNo,
  paginationChange,
  applicantListCount,
}) => {
  return (
    <div className="table-responsive-xl">
      <div className="wrap-table100">
        <div className="shadow table100" style={{ minHeight: 300 }}>
          <table>
            <thead>
              <tr className="table100-head">
                <th scope="col" className="column">
                  SL No.
                </th>
                <th scope="col" className="column-td">
                  Name
                </th>
                <th scope="col" className="column-td">
                  Email
                </th>
                <th scope="col" className="column-td">
                  Job Title
                </th>
                <th scope="col" className="column-td">
                  Job ID
                </th>
                <th scope="col" className="column-td">
                  Experience
                </th>
                <th scope="col" className="column-td">
                  Location
                </th>
                <th scope="col" className="column">
                  Resume
                </th>
                <th scope="col" className="column-td">
                  Status
                </th>
              </tr>
            </thead>
            {listLoader ? (
              <ListSkeleton />
            ) : applicantList ? (
              applicantList.length ? (
                applicantList.map((item, i) => {
                  return (
                    <tbody key={i}>
                      <tr>
                        <td className="column">
                          {pageNo === 1 ? i + 1 : (pageNo - 1) * 10 + (i + 1)}
                        </td>

                        <td
                          style={{
                            color: "rgba(54, 171, 155)",
                          }}
                          className="column-td"
                          onClick={() =>
                            OpenDialog("AppliedCandidatesPage", item)
                          }
                        >
                          {" "}
                          {item?.fullName}
                        </td>
                        <td className="column-td"> {item?.email}</td>
                        <td className="column-td"> {item?.jobTitle}</td>
                        <td className="column-td"> {item?.jobId}</td>
                        <td className="column-td">
                          {item?.experienceInYear}
                          {item?.experienceInYear === "1"
                            ? " year "
                            : " years "}
                          & {item?.experienceInMonth}
                          {item?.experienceInMonth === "1"
                            ? " month"
                            : " months"}
                        </td>
                        <td className="column-td"> {item?.location}</td>

                        <td className="column">
                          <SimCardDownloadIcon
                            onClick={() => OpenDialog("ReadResume", item)}
                            color="primary"
                          />
                        </td>
                        <td className="column-td">
                          {item?.applicantStatus === "rejected"
                            ? "Rejected"
                            : ""}
                        </td>
                      </tr>
                    </tbody>
                  );
                })
              ) : (
                <ListEmptyState emptyState="No results found" />
              )
            ) : (
              <ListEmptyState emptyState="No results found" />
            )}
          </table>
        </div>
        {listLoader ? (
          <PaginationSkeleton />
        ) : applicantList ? (
          applicantListCount > 5 ? (
            <div className="job-list-pagination">
              <Pagination
                count={
                  applicantListCount < 10
                    ? 1
                    : Math.ceil(applicantListCount / 10)
                }
                onChange={paginationChange}
                page={pageNo}
                color="primary"
                size="small"
              />
            </div>
          ) : (
            <div style={{ marginBottom: 20 }} />
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
//rejected Resumes end
