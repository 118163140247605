const validation = (values) => {
  let errors = { error: false };
  if (!values.fullName) {
    errors.fullName = "Please enter full name";
    errors.error = true;
  }
  if (!values.qualification) {
    errors.qualification = "Please enter qualification";
    errors.error = true;
  }
  if (!values.resume) {
    errors.resume = "Please choose a file";
    errors.error = true;
  }

  if (!values.location) {
    errors.location = "Please select preffered location ";
    errors.error = true;
  }
  if (!values.experienceInYear) {
    errors.experienceInYear = "Please select  experience in year ";
    errors.error = true;
  }
  if (!values.experienceInMonth) {
    errors.experienceInMonth = "Please select  experience in month  ";
    errors.error = true;
  }
  if (!values.noticeperiod) {
    errors.noticeperiod = "Please select  notice period ";
    errors.error = true;
  }
  if (!values.phone) {
    errors.phone = " Please enter mobile number "; //eslint-disable-next-line
  } else if (!/^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/.test(values.phone)) {
    errors.phone = "Enter a valid mobile number";
    errors.error = true;
  }

  if (!values.email) {
    errors.email = " Please enter email";
    errors.error = true;
  } else if (
    !/^[A-Za-z0-9._%+-]+@(?:[A-Za-z0-9-]+\.)+[A-Za-z]{2,}$/.test(
      values.email
    ) ||
    /\S+@saasvaap.com/.test(values.email)
  ) {
    errors.email = "Enter a valid email";
    errors.error = true;
  }
  if (!values.jobTitle) {
    errors.jobTitle = "Please enter job title ";
    errors.error = true;
  }
  if (values?.keySkills?.length === 0) {
    errors.keySkills = "Please select skill";
    errors.error = true;
  }

  return errors;
};

export default validation;
